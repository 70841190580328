import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { delActivity, delRound } from '@/api/openMaintenance';
import utils from '@/utils/utils';
export default {
  name: 'Tested',
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    type: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      usedName: '',
      newName: '',
      setName: false,
      isPC: utils.isPC(),
      time: [],
      isAdd: false,
      // 是否展示 修改 弹窗
      isEdit: false,
      date: new Date(),
      //  修改弹框
      listData: {
        name: '',
        end_time: '',
        start_time: ''
      },
      // 新增弹框
      addData: {
        name: '',
        end_time: '',
        start_time: ''
      },
      // 活动下标
      listIndex: 0,
      // 场次下标
      tableIndex: 0,
      // 保存loading
      saveLo: true,
      // 新增保存loading
      addSaveLo: true,
      seteventIndex: ''
    };
  },
  created: function created() {},
  methods: {
    getstartTime: function getstartTime(row, val) {
      if (row.type === 1) {
        this.$emit('getFormalStartTime', row.start_time);
      }
    },
    addMinuteFunc: function addMinuteFunc(val, inx) {
      this.list[inx].addMinute = val;
      var obj = this.list[inx];
      obj.addMinute = val;
      obj.end_time = val ? obj.start_time + val * 60 * 1000 : '';
      this.$set(this.list, inx, obj);
    },
    endTimeFunc: function endTimeFunc(val, inx) {
      console.log(val, inx);
    },
    // 修改活动名称
    seteventName: function seteventName(item, index) {
      if (item.type !== 1 && this.type === 'add') {
        //非正式活动
        this.usedName = item.name;
        this.seteventIndex = index;
        this.setName = true;
      }
    },
    handleSaveName: function handleSaveName() {
      if (!this.newName) {
        this.$message({
          type: 'warning',
          message: '请输入新名称!'
        });
        return;
      }

      this.list[this.seteventIndex].name = this.newName;
      this.setName = false;
    },
    handelIsSo: function handelIsSo(id, inx) {
      if (this.type === 'edit') {
        return;
      }

      this.$emit('handelIsSo', id, inx);
    },
    // 验证
    handleEndTime: function handleEndTime(inx) {
      if (this.list[inx].end_time - this.list[inx].start_time <= 0) {
        this.$message({
          type: 'warning',
          message: '结束时间必须大于开始时间'
        });
        this.list[inx].end_time = '';
        this.list[inx].addMinute = '';
      } else {
        this.list[inx].addMinute = Math.ceil((this.list[inx].end_time - this.list[inx].start_time) / 60 / 60 / 1000 * 60);
        this.addMinuteFunc(this.list[inx].addMinute, inx);
      }
    },
    getTitle: function getTitle(i, type) {
      var text = '';

      switch (type) {
        case 1:
          text = '正式活动';
          break;

        case 2:
          text = '第' + (i + 1) + '次公测';
          break;

        case 3:
          text = '第' + (i + 1) + '次内测';
          break;

        case 4:
          text = '第' + (i + 1) + '次拟测';
          break;
      }

      return text;
    },
    // 时间戳转换
    toHHmmss: function toHHmmss(end, star) {
      if (end == 0 || end == null || end == '' || star == 0 || star == null || star == '') {
        return '';
      } else {
        var time = end - star;
        var days = parseInt(time / (1000 * 60 * 60 * 24));
        var hours = parseInt(time % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
        var minutes = parseInt(time % (1000 * 60 * 60) / (1000 * 60));
        var seconds = time % (1000 * 60) / 1000;
        return days + ' 天 ' + hours + ' 时 ' + minutes + ' 分 ' + seconds + ' 秒 ';
      }
    },
    // 打开新增弹框
    handelAdd: function handelAdd(index) {
      this.isAdd = true;
      this.listIndex = index;
    },
    // 保存新增场次
    handleSaveAdd: function handleSaveAdd() {
      var _this = this;

      if (!this.addSaveLo) {
        return;
      } // 表单验证是否完成


      this.$refs['addData'].validate(function (valid) {
        if (valid) {
          // 判断时间是否正确
          if (_this.addData.end_time - _this.addData.start_time <= 0) {
            _this.$message({
              type: 'warning',
              message: '结束时间必须大于开始时间'
            });

            return false;
          }

          _this.addData.end_time = _this.addData.end_time / 1000;
          _this.addData.start_time = _this.addData.start_time / 1000;
          _this.saveLo = false;
          _this.isAdd = false;

          _this.list[_this.listIndex].rounds.push(_this.addData); // 清空弹框


          _this.addData = {};
        } else {
          _this.$message({
            type: 'warning',
            message: '检查在保存'
          });

          return false;
        }
      });
    },
    // 删除对应的场次
    handelDel: function handelDel(row, tableIndex, listIndex) {
      var _this2 = this;

      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        // 有 id 就走  接口删除
        if (row.id != undefined) {
          delRound({
            id: row.id
          }).then(function (res) {
            if (res.status === 200) {
              _this2.$message({
                type: 'success',
                message: res.data.msg
              });

              _this2.$emit('handelRoundsDel', row, tableIndex, listIndex); // 前端删除
              // this.list[listIndex].rounds.splice(tableIndex, 1);

            } else {
              _this2.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          });
        } else {
          _this2.$emit('handelRoundsDel', row, tableIndex, listIndex); // 前端删除
          // this.list[listIndex].rounds.splice(tableIndex, 1);


          _this2.$message({
            type: 'success',
            message: '操作成功'
          });
        }
      });
    },
    // 删除对应活动
    handelListDel: function handelListDel(id, listIndex) {
      var _this3 = this;

      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        if (id != undefined) {
          delActivity({
            id: id
          }).then(function (res) {
            if (res.status == 200) {
              _this3.$message({
                type: 'success',
                message: res.data.msg
              }); // 前端删除


              _this3.list.splice(listIndex, 1);

              _this3.$emit('handelDel', _this3.list.length);
            } else {
              _this3.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          });
        } else {
          _this3.list.splice(listIndex, 1);

          _this3.$emit('handelDel', _this3.list.length);

          _this3.$message({
            type: 'success',
            message: '操作成功'
          });
        }
      });
    },
    // 保存修改的数据
    handleSaveTable: function handleSaveTable() {
      var _this4 = this;

      // 防止多次点击
      if (!this.saveLo) {
        return;
      } // 表单验证是否完成


      this.$refs['listData'].validate(function (valid) {
        if (valid) {
          // 判断时间是否正确
          if (_this4.listData.end_time - _this4.listData.start_time <= 0) {
            _this4.$message({
              type: 'warning',
              message: '结束时间必须大于开始时间'
            });

            return false;
          }

          _this4.saveLo = false;
          _this4.isEdit = false;

          _this4.$emit('handelEdit', _this4.listData, _this4.listIndex, _this4.tableIndex); // 清空弹框


          _this4.listData = {};
        } else {
          _this4.$message({
            type: 'warning',
            message: '检查在保存'
          });

          return false;
        }
      });
    },
    // 打开修改弹框
    handelEdit: function handelEdit(row, tableIndex, listIndex) {
      this.isEdit = true;
      this.saveLo = true; // 回显数据

      this.listData = {
        name: row.name,
        end_time: row.end_time == '' ? '' : row.end_time * 1000,
        start_time: row.start_time == '' ? '' : row.start_time * 1000,
        id: row.id,
        batch: row.batch,
        event_id: row.event_id,
        project_id: row.project_id
      }; // 场次下标

      this.tableIndex = tableIndex; // 活动下标

      this.listIndex = listIndex;
    }
  }
};