import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from "@/utils/utils";
import { selBatchGroup, setBatchGroup } from '@/api/openMaintenance';
export default {
  name: 'batchGroups',
  props: {
    type: {
      type: String,
      default: ''
    },
    moduleData: {
      //正式活动
      type: Array,
      defalut: {}
    },
    batchList: {
      type: Array,
      defalut: []
    },
    interval_min: {
      type: Number,
      defalut: 0
    },
    interval_sec: {
      type: Number,
      defalut: 0
    }
  },
  data: function data() {
    return {
      isPC: utils.isPC(),
      groups: [],
      oldgroups: [],
      isAdd: false,
      addData: {
        name: '',
        num_min: '',
        num_max: '',
        start_time: '',
        end_time: ''
      },
      onedisabled: false,
      dialogTitle: '',
      setall: []
    };
  },
  mounted: function mounted() {
    this.getgroups();
  },
  methods: {
    onbatchSelect: function onbatchSelect(queryString, cb) {
      var temList = this.batchList;
      var results = queryString ? temList.filter(function (restaurant) {
        restaurant.value = restaurant.value.toString();
        return restaurant.value == queryString;
      }) : temList; // 调用 callback 返回建议列表的数据

      cb(results);
    },
    // 新增分组
    handelAdd: function handelAdd() {
      this.groups.push({
        name: '',
        num_min: null,
        num_max: null,
        start_time: '',
        end_time: ''
      });
      this.oldgroups.push({
        name: '',
        num_min: null,
        num_max: null,
        start_time: '',
        end_time: ''
      });
    },
    // 删除分组
    handelDel: function handelDel(row, index) {
      this.groups.splice(index, 1);
      this.oldgroups.splice(index, 1);
    },
    allsetGroup: function allsetGroup(groups, type) {
      var _this = this;

      return new Promise(function (resolve, error) {
        var interval_min = _this.interval_min,
            interval_sec = _this.interval_sec;
        setBatchGroup({
          interval_min: interval_min,
          interval_sec: interval_sec,
          start_time: _this.moduleData[0].start_time,
          groups: groups
        }).then(function (res) {
          _this.getgroups();

          resolve();
        }).catch(function (err) {
          error();
        });
      });
    },
    getgroups: function getgroups() {
      var _this2 = this;

      selBatchGroup().then(function (res) {
        if (!res.data.list || !res.data.list.length) {
          _this2.groups = [];

          _this2.groups.push({
            name: '',
            num_min: 1,
            num_max: '',
            start_time: _this2.moduleData[0].start_time / 1000,
            end_time: null
          });
        } else {
          _this2.groups = res.data.list || [];
        }

        _this2.oldgroups = JSON.parse(JSON.stringify(_this2.groups));

        for (var i = 0; i < _this2.oldgroups.length; i++) {
          _this2.oldgroups[i].num_max = String(_this2.oldgroups[i].num_max);
          _this2.oldgroups[i].num_min = String(_this2.oldgroups[i].num_min);
          _this2.oldgroups[i].start_time = _this2.oldgroups[i].start_time * 1000 || '';
          _this2.oldgroups[i].end_time = _this2.oldgroups[i].end_time * 1000 || '';
        }
      });
    },
    setfirstStartTime: function setfirstStartTime(val) {
      var interval = val - this.oldgroups[0].start_time;

      for (var i = 0; i < this.oldgroups.length; i++) {
        this.oldgroups[i].start_time = this.oldgroups[i].start_time + interval;
        this.oldgroups[i].end_time = this.oldgroups[i].end_time + interval;
      }

      this.oldgroups[0].start_time = val;
    },
    // 验证分组是否填写完整
    verify: function verify() {
      var _this3 = this;

      return new Promise(function (resolve, error) {
        var errInfo = '';
        var flag = true;
        var newgroups = JSON.parse(JSON.stringify(_this3.oldgroups));

        try {
          newgroups.map(function (item) {
            item.num_max = Number(item.num_max);
            item.num_min = Number(item.num_min);

            if (item.name === '') {
              flag = false;
              throw '批次分组名称不能为空';
            }

            if (item.num_min === '' || item.num_min === 0) {
              flag = false;
              throw item.name + '起始批次不能为空';
            }

            if (item.num_max === '' || item.num_max === 0) {
              flag = false;
              throw item.name + '截止批次不能为空';
            }

            if (item.start_time === '') {
              flag = false;
              throw item.name + '开始时间不能为空';
            }

            if (item.end_time === '') {
              flag = false;
              throw item.name + '结束时间不能为空';
            }
          });
        } catch (err) {
          errInfo = err;
        }

        if (flag) {
          _this3.allsetGroup(newgroups).then(function () {
            resolve();
          }).catch(function () {
            error();
          });
        } else {
          _this3.$message.error(errInfo);

          error();
        }
      });
    }
  }
};