import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.fill.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getLedStyle, getLedData } from '@/api/projectRound';
import { getWsToken } from '@/utils/auth';
export default {
  name: 'publicity',
  data: function data() {
    return {
      style: {
        title: {
          show: 1,
          top: 44,
          color: '#FFFFFF',
          fontSize: 50,
          bottom: 100
        },
        box: {
          opacity: 0.1,
          top: 30,
          round: {
            show: 1,
            color: '#FFFFFF',
            fontSize: 40,
            position: 'top',
            width: 0,
            top: 20
          },
          itemBox: {
            width: 1000,
            margin: 0,
            marginTop: 0,
            height: 100
          },
          item: {
            leftMargin: 10,
            topMargin: 10,
            topPadding: 10,
            numFontSize: 30,
            numColor: '#1D1D1D',
            numTop: 0,
            fontSize: 20,
            color: '#1D1D1D',
            contentTop: 0,
            lineHeight: 20
          }
        },
        logo: 'rand/lottery.png'
      },
      // 布局样式 一行显示几个轮次
      round_row_num: 1,
      // 公示模式  0：全部，1：最新模式   X   轮
      publicity_type: 0,
      // 最新模式   X   轮
      max_round_length: 3,
      // 每页展示轮次
      round_length: 10,
      // 每行展示数量
      row_num: 5,
      // 公示屏切换间隔 (秒)
      carousel_interval: 3,
      rand: {},
      rand_name: '',
      data: [],
      group_id: 0,
      new_id: null
    };
  },
  created: function created() {
    this.doWebsocket();
  },
  mounted: function mounted() {
    this.doGetLedStyle();
  },
  methods: {
    doWebsocket: function doWebsocket() {
      var url = this.wsUrl;
      var token = getWsToken();
      var ws;
      var tt;
      var lockReconnect = false; // 避免重复连接

      var self = this;
      var websocket = {
        Init: function Init() {
          ws = new WebSocket("".concat(url, "?token=").concat(token));

          ws.onmessage = function (e) {
            var message = e.data;
            var msg = JSON.parse(message);

            if (msg.data.type == 'refresh' || msg.data.type == 'reload') {// this.numbers = 0
            } else {
              // 处理数据
              self.socketListen(msg);
            }

            heartCheck.start();
          };

          ws.onclose = function () {
            console.log('webSocket重连');
            reconnect();
          };

          ws.onopen = function () {
            // clearInterval(_self.socketErrTimer)
            console.log('webSocket连接');
            heartCheck.start();
          };

          ws.onerror = function (e) {
            console.log('webSockt错误');
            reconnect();
          };
        }
      }; // 重连

      function reconnect() {
        if (lockReconnect) {
          return;
        }

        lockReconnect = true; // 没连接上会一直重连, 设置延迟避免请求过多

        tt && clearTimeout(tt);
        tt = setTimeout(function () {
          console.log('执行断线重连...'); // clearInterval(_self.socketErrTimer)

          websocket.Init();
          lockReconnect = false;
        }, 4000);
      } // 心跳


      var heartCheck = {
        timeout: 1000 * 10,
        timeoutObj: null,
        serverTimeoutObj: null,
        start: function start() {
          var that = this;
          this.timeoutObj && clearTimeout(this.timeoutObj);
          this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
          this.timeoutObj = setTimeout(function () {
            // 这里发送一个心跳, 后端收到后, 返回一个心跳消息,
            // onmessage 拿到返回的心跳就说明连接正常
            ws.send('{"type":"ping"}');
            that.serverTimeoutObj = setTimeout(function () {
              if (ws.readyState != 1) {
                ws.close();
              }
            }, that.timeout);
          }, this.timeout);
        }
      };
      websocket.Init();
    },
    // socket 信息监听
    socketListen: function socketListen(msg) {
      if (!msg.data) return; // 开始摇号

      if (msg.data.type == 'rand_rotate') {} // 摇号结束


      if (msg.data.type == 'help') {
        //之前处理数据 在msg.data.type == 'rand_rotate' 判断里
        window.clearInterval(this.timeId);
        this.doGetLedData();
      } // 重置摇号


      if (msg.data.type == 'reload') {}
    },
    // 获取公示大屏样式
    doGetLedStyle: function doGetLedStyle() {
      var _this = this;

      //  loading
      var loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      getLedStyle().then(function (res) {
        _this.rand = res.data.data.rand; // 布局样式 一行显示几个轮次

        _this.round_row_num = res.data.data.led_style.round_row_num; // 公示模式  0：全部，1：最新模式   X   轮

        _this.publicity_type = res.data.data.led_style.publicity_type; // 最新模式   X   轮

        _this.max_round_length = res.data.data.led_style.max_round_length; // 每页展示轮次

        _this.round_length = res.data.data.led_style.round_length; // 每行展示数量

        _this.row_num = res.data.data.led_style.row_num; // 公示屏切换间隔 (秒)

        _this.carousel_interval = res.data.data.led_style.carousel_interval;
        _this.style = res.data.data.led_style.style ? JSON.parse(res.data.data.led_style.style) : {
          title: {
            show: 1,
            top: 44,
            color: '#FFFFFF',
            fontSize: 50,
            bottom: 100
          },
          box: {
            opacity: 0.1,
            top: 30,
            round: {
              show: 1,
              color: '#FFFFFF',
              fontSize: 40,
              position: 'top',
              width: 0,
              top: 20
            },
            itemBox: {
              width: 1000,
              margin: 0,
              marginTop: 0,
              height: 100
            },
            item: {
              leftMargin: 10,
              topMargin: 10,
              topPadding: 10,
              numFontSize: 30,
              numColor: '#1D1D1D',
              numTop: 0,
              fontSize: 20,
              color: '#1D1D1D',
              contentTop: 0,
              lineHeight: 20
            }
          },
          logo: 'rand/lottery.png'
        };

        _this.doGetLedData();

        _this.style.logo = _this.style.logo || _this.rand.logo || '/rand/lottery.png';
        _this.rand_name = res.data.data.rand.name;
        loading.close();
      }).catch(function (res) {
        loading.close();
      });
    },
    // 获取公示大屏数据
    doGetLedData: function doGetLedData() {
      var _this2 = this;

      getLedData({
        rand_id: +this.$route.query.rand_id,
        //规则ID
        group_id: this.group_id,
        //分组ID 不穿默认取最新组
        order_by_type: this.publicity_type,
        //排序 0升序 1倒序
        num: this.publicity_type ? this.max_round_length : 0 //最新几轮 0默认全部

      }).then(function (res) {
        _this2.group_id = 0;
        _this2.new_id = 0;
        var data = [[]],
            list = res.data.list || [],
            index = 0;

        if (list.length) {
          for (var i = 0; i < list.length; i++) {
            if (_this2.$route.query.type_group == 1) {
              //开启摇号分组 赋值没轮的组别
              list[i].level = list[i].rand_list[0].rand_group;
            }

            if (index >= _this2.round_length) {
              index = 0;
              data.push([]);
            }

            data[data.length - 1].push(list[i]);
            index += 1;
          }

          _this2.data = data;

          _this2.$nextTick(function () {
            _this2.doGetWidthHeight();
          });
        }

        if (+res.data.this_level.sort !== 1) {
          if (res.data.this_level.id != _this2.group_id && !list.length) {
            var sort = res.data.this_level.sort - 1;
            var id = res.data.level.level.find(function (item) {
              return item.sort == sort;
            }).id;
            _this2.group_id = id;
          } else {
            _this2.group_id = +res.data.this_level.id;
          }

          _this2.doGetLedData();
        }
      }).catch(function (res) {});
    },
    // 获取宽高
    doGetWidthHeight: function doGetWidthHeight() {
      var _this3 = this;

      var round = document.getElementsByClassName('round');
      var box = document.getElementsByClassName('box');
      var box_content = document.getElementsByClassName('box-content');
      this.style.box.round.width = round[0] ? round[0].clientWidth : 0;
      this.style.box.itemBox.width = box[0] ? box[0].scrollWidth - this.style.box.round.width : 0;
      this.$nextTick(function () {
        var height = [];

        for (var i = 0; i < box_content.length; i++) {
          height.push(box_content[i].clientHeight || 0);
        }

        _this3.style.box.itemBox.height = height;
      });
    },
    // 身份证
    getCard: function getCard(card, show_card) {
      // 隐藏身份证中间
      if (show_card === 1) {
        if (card.length > 8) {
          return card.replace(/^(.{4})(?:\d+)(.{4})$/, '$1******$2');
        } else {
          return card.replace(/^(.{1})(?:\d+)(.{1})$/, '$1******$2');
        }
      } // 只显示最后六个号码
      else {
        return card.substring(card.length - 6);
      }
    },
    nickName: function nickName() {
      var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var names = str.split(';');
      var newName = '';
      names.forEach(function (name) {
        if (!!name) {
          if (name.length < 3) {
            newName = newName + name[0] + '*';
          }

          if (name.length > 2) {
            newName = newName + name[0] + new Array(name.length - 2).fill('*').join('') + name[name.length - 1];
          }

          if (names.length > 1) newName = newName + ';';
        }
      });
      return newName;
    },
    //  手机正则
    getTel: function getTel(tel) {
      if (tel != null) {
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        return tel.replace(reg, '$1****$2');
      }
    }
  }
};