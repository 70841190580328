var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stylePopup" }, [
      _c("div", { staticClass: "dialog-header-11" }, [
        _c("span", { staticClass: "dialog-header-11-text" }, [
          _vm._v("恭喜认购成功"),
        ]),
      ]),
      _c("div", { staticClass: "dialog-content-11" }, [
        _c("img", {
          staticClass: "dialog-content-bg-11",
          attrs: {
            src: "https://yxk3-oss.oss-cn-chengdu.aliyuncs.com/xf/img/in-success.png",
            alt: "",
          },
        }),
        _c("div", { staticClass: "dialog-content-wrapper-11" }, [
          _c("div", { staticClass: "dialog-content-info-11" }, [
            _c("div", { staticClass: "dialog-content-info-type-11" }, [
              _vm._v("220户型"),
            ]),
            _c("div", { staticClass: "dialog-content-info-box1-11" }, [
              _vm._v(" 1号楼-东单元1-101 "),
            ]),
            _c("div", { staticClass: "dialog-content-info-box2-11" }, [
              _c("div", [_vm._v("总价：1,662,912.00元")]),
              _c("div", [_vm._v("面积：104.68㎡")]),
            ]),
          ]),
          _c("div", { staticClass: "dialog-content-text-11" }, [
            _vm._v(" 认购须知认购须知认购须知认购须知认购须知 "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "dialog-footer-11" }, [
        _c("div", { staticClass: "footer_11" }, [
          _c("img", {
            staticClass: "footer_bg_11",
            attrs: {
              src: "https://yxk3-oss.oss-cn-chengdu.aliyuncs.com/xf/img/sign-up-bo.png",
              alt: "",
            },
          }),
          _c("div", { staticClass: "btn_row_11" }, [
            _c("div", { staticClass: "btn_11 o_btn_11" }, [
              _c("img", {
                staticClass: "btn_bg_11",
                attrs: {
                  src: "https://yxk3-oss.oss-cn-chengdu.aliyuncs.com/xf/img/btn-bg.png",
                },
              }),
              _c("span", { staticClass: "text" }, [_vm._v("继续认狗")]),
            ]),
            _c("div", { staticClass: "btn_11" }, [
              _c("img", {
                staticClass: "btn_bg_11",
                attrs: {
                  src: "https://yxk3-oss.oss-cn-chengdu.aliyuncs.com/xf/img/btn-bg.png",
                },
              }),
              _c("span", { staticClass: "text" }, [_vm._v("点击签约")]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }