import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import screenfull from '@/components/my-screen';
import { debounce } from '@/utils/debounce';
import { noQueryTurn, noStartLottery, noStopLottery } from '@/api/lottery';
import editFormatMixin from '@/views/editFormat/editFormatMixin';
import { beginRound, endRound } from '@/api/projectRound';
import { getWsToken } from '@/utils/auth';
import { getProject } from '@/utils/auth';
export default {
  name: 'StartLotteryNo',
  components: {
    screenfull: screenfull
  },
  mixins: [editFormatMixin],
  data: function data() {
    return {
      // 定时器
      timeId: null,
      // 摇号 规则
      rand: {},
      // 摇号列表
      lottery: [],
      h: 0,
      // 轮次信息
      list: {},
      // 摇号按钮
      startButton: false,
      // 下一轮摇号
      is_next: false,
      // 获取当前轮次
      roundNumber: 0,
      // 剩余摇号人数
      laveNumber: 0,
      // 判断本组是否有下一轮
      is_rand_next: false,
      // 根据个数改变样式
      heightClass: 'height10',
      styleObject: {},
      //  防止多次点击停止
      stopLotteryButton: false,
      //  query
      query: {},
      numbers: 0
    };
  },
  // 初始化
  created: function created() {
    var query = this.$route.query;
    this.query = query; // this.initStartLottery()
    // ----------------websocket------------------------

    var url = this.wsUrl;
    var token = getWsToken();
    var ws;
    var tt;
    var lockReconnect = false; // 避免重复连接

    var self = this;
    var websocket = {
      Init: function Init() {
        ws = new WebSocket("".concat(url, "?token=").concat(token, "&admin_id=").concat(JSON.parse(getProject()).id, "&round_id=").concat(self.$route.query.id));

        ws.onmessage = function (e) {
          var message = e.data;
          var msg = JSON.parse(message);
          heartCheck.start();
          var type = msg.data ? msg.data.type : msg.mag;

          if (type == 'refresh' || type == 'reload') {// this.numbers = 0
          } else {
            // 处理数据
            self.socketListen(msg);
          }
        };

        ws.onclose = function () {
          console.log('webSocket重连');
          reconnect();
        };

        ws.onopen = function () {
          // clearInterval(_self.socketErrTimer)
          console.log('webSocket连接');
          heartCheck.start();
        };

        ws.onerror = function (e) {
          console.log('webSockt错误');
          reconnect();
        };
      }
    }; // 重连

    function reconnect() {
      if (lockReconnect) {
        return;
      }

      lockReconnect = true; // 没连接上会一直重连, 设置延迟避免请求过多

      tt && clearTimeout(tt);
      tt = setTimeout(function () {
        console.log('执行断线重连...'); // clearInterval(_self.socketErrTimer)

        websocket.Init();
        lockReconnect = false;
      }, 4000);
    } // 心跳


    var heartCheck = {
      timeout: 1000 * 10,
      timeoutObj: null,
      serverTimeoutObj: null,
      start: function start() {
        var that = this;
        this.timeoutObj && clearTimeout(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.timeoutObj = setTimeout(function () {
          // 这里发送一个心跳, 后端收到后, 返回一个心跳消息,
          // onmessage 拿到返回的心跳就说明连接正常
          ws.send('{"type":"ping"}');
          that.serverTimeoutObj = setTimeout(function () {
            if (ws.readyState != 1) {
              ws.close();
            }
          }, that.timeout);
        }, this.timeout);
      }
    };
    websocket.Init();
  },
  mounted: function mounted() {
    var query = this.$route.query;
    this.query = query;
    this.getBeginData();
  },
  methods: {
    // socket 信息监听
    socketListen: function socketListen(msg) {
      // 开始摇号
      var type = msg.data ? msg.data.type : msg.mag;

      if (type == 'rand_rotate') {
        if (this.numbers == 0) {
          this.roundNumber = 1;
        } else {
          this.roundNumber += 1;
        }

        this.beginAmimate();
      } // 摇号结束


      if (type == 'help') {
        window.clearInterval(this.timeId);
        this.getBeginData();
      } // 重置摇号


      if (type == 'reload') {}
    },
    // begin
    getBeginData: function getBeginData() {
      var _this = this;

      var params = {
        id: this.query.id
      };
      endRound(params).then(function (res) {
        var _res$data = res.data,
            list = _res$data.list,
            _res$data$list = _res$data.list,
            erd = _res$data$list.erd,
            this_round = _res$data$list.this_round;
        _this.styleObject = {
          background: "url(".concat(_this.imgUrl + erd.logo, ")100%, no-repeat"),
          backgroundSize: 'cover',
          backgroundPosition: 'center, center'
        };
        _this.rand = erd;

        if (this_round == null || this_round == undefined) {
          // 还未开始摇号
          _this.roundNumber = 1;
          _this.numbers = 0;
          _this.lottery = [];

          for (var i = 0; i < erd.rand_count; i++) {
            _this.lottery.push({
              name: '****',
              code: '****',
              tel: '****',
              card: '****'
            });
          }

          _this.unRoundList();
        } else {
          _this.roundNumber = this_round;
          _this.numbers = this_round;
          _this.lottery = list.rand_list;

          _this.unRoundList();
        }
      });
    },
    // 开始动画
    beginAmimate: function beginAmimate() {
      var _this2 = this;

      // 关闭再开 避免多个定时器
      window.clearInterval(this.timeId);
      this.timeId = setInterval(function () {
        _this2.lottery.forEach(function (item) {
          item.name = _this2.nameArray[Math.floor(Math.random() * _this2.nameArray.length)];
          item.tel = _this2.telArray[Math.floor(Math.random() * _this2.telArray.length)];
          item.code = _this2.codeArray[Math.floor(Math.random() * _this2.codeArray.length)];
          item.card = _this2.cardArray[Math.floor(Math.random() * _this2.cardArray.length)];
        });
      }, 10);
    },
    // 获取未摇号列表
    unRoundList: function unRoundList() {
      var _this3 = this;

      var params = {
        id: this.query.id
      };
      beginRound(params).then(function (res) {
        var list = res.data.list; // 组装未摇号人数据

        if (list.not_rand_user_list !== null) {
          var nameArray = [];
          var telArray = [];
          var codeArray = [];
          var cardArray = [];
          list.not_rand_user_list.forEach(function (item) {
            nameArray.push(item.name);
            telArray.push(item.tel);
            codeArray.push(item.code);
            cardArray.push(item.card);
          });
          _this3.nameArray = nameArray;
          _this3.telArray = telArray;
          _this3.codeArray = codeArray;
          _this3.cardArray = cardArray;
        }
      });
    },
    // 身份证
    getCard: function getCard(card, show_card) {
      // 隐藏身份证中间
      if (show_card === 1) {
        if (card.length > 8) {
          return card.replace(/^(.{4})(?:\d+)(.{4})$/, '$1******$2');
        } else {
          return card.replace(/^(.{1})(?:\d+)(.{1})$/, '$1******$2');
        }
      } // 只显示最后六个号码
      else {
        return card.substring(card.length - 6);
      }
    },
    nickName: function nickName() {
      var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var names = str.split(';');
      var newName = '';
      names.forEach(function (name) {
        if (name != null) {
          if (name.length < 3) {
            newName = newName + name[0] + '*';
          }

          if (name.length > 2) {
            newName = newName + name[0] + new Array(name.length - 2).fill('*').join('') + name[name.length - 1];
          }

          if (names.length > 1) newName = newName + ';';
        }
      });
      return newName;
    },
    //  手机正则
    getTel: function getTel(tel) {
      if (tel != null) {
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        return tel.replace(reg, '$1****$2');
      }
    },
    // 上一轮 跳转历史页面
    handleLastRound: function handleLastRound() {
      // 获取摇号结果
      // 获取 url 数据
      var query = JSON.parse(JSON.stringify(this.$route.query));
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: this.list.this_round
      };
      this.$router.push({
        name: 'StartLotteryNoHistory',
        query: data
      });
    },
    // 点击 下一轮摇号
    handleNextRound: debounce(function () {
      var _this4 = this;

      // 有预设失败的
      if (this.list.is_preinstall) {
        this.$alert('摇号人数异常无法继续摇号，请修复异常。', '提示', {
          confirmButtonText: '好的',
          callback: function callback(action) {}
        });
        return;
      }

      if (this.is_rand_next) {
        this.$confirm('本轮摇号已结束！', '提醒', {
          confirmButtonText: '确定',
          cancelButtonText: '返回首页',
          closeOnClickModal: false,
          closeOnPressEscape: false,
          showClose: false,
          type: 'warning'
        }).then(function () {}).catch(function () {
          _this4.$router.push({
            name: 'Lottery',
            query: {
              id: _this4.$route.query.id
            }
          });
        });
        return;
      } // 获取参数


      var query = JSON.parse(JSON.stringify(this.$route.query));
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: this.list.max_round === 0 ? this.list.max_round + 1 : this.list.max_round
      }; // 下一轮

      noQueryTurn(data).then(function (res) {
        var _res$data2 = res.data,
            list = _res$data2.list,
            _res$data2$list = _res$data2.list,
            rand = _res$data2$list.rand,
            not_user_list = _res$data2$list.not_user_list; // 摇号规则

        _this4.rand = rand; // 活动参数

        _this4.list = list; // 获取当前轮次  加+1

        _this4.roundNumber = list.max_round + 1; // 改变参数 轮次加+1

        query.round = list.max_round + 1; // 更新路由

        _this4.$router.push({
          path: _this4.$route.path,
          query: query
        }); // 组装页面摇号 个数


        if (rand.rand_count > 0) {
          _this4.lottery.forEach(function (item) {
            item.name = '****';
            item.code = '****';
            item.tel = '****';
            item.card = '****';
          }); // 页面个数 高度


          if (rand.rand_count > 10) {
            _this4.heightClass = 'height20';
          } else {
            _this4.heightClass = 'height10';
          } // 下一轮 数据


          _this4.is_next = false;
        }
      });
    }, 200, true),
    // 初始化摇号
    initStartLottery: function initStartLottery() {
      var _this5 = this;

      // 获取参数
      var query = JSON.parse(JSON.stringify(this.$route.query));
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: query.round
      }; // 查询摇号接口

      noQueryTurn(data).then(function (res) {
        if (res.status === 200) {
          var _res$data3 = res.data,
              list = _res$data3.list,
              _res$data3$list = _res$data3.list,
              not_user_list = _res$data3$list.not_user_list,
              rand = _res$data3$list.rand,
              not_rand_user_list = _res$data3$list.not_rand_user_list; // 活动参数

          _this5.list = list; // 摇号规则

          _this5.rand = rand; // 本组是否结束

          _this5.is_rand_next = list.end;
          _this5.styleObject = {
            background: "url(".concat(_this5.imgUrl + rand.logo, ")100%, no-repeat"),
            backgroundSize: 'cover',
            backgroundPosition: 'center, center'
          }; // 获取未摇号人数

          _this5.laveNumber = list.not_user_count; // 改变参数

          query.round = list.max_round === 0 ? list.max_round + 1 : list.max_round; // 更新路由

          _this5.$router.push({
            path: _this5.$route.path,
            query: query
          });

          if (query.round === list.max_round) {
            _this5.is_next = true;
          } else {
            _this5.is_next = false;
          } // 获取当前轮次


          _this5.roundNumber = list.max_round === 0 ? list.max_round + 1 : list.max_round; // 判断当前号 已摇

          if (list.max_round !== 0) {
            // 覆盖数据
            if (list.max_round_list.length < rand.rand_count) {
              // 计算出来还差几个
              // let number = rand.rand_count - list.max_round_list.length;
              //
              // for (let i = 0; i < number; i++) {
              // 	list.max_round_list.push({
              // 		name: '****',
              // 		code: '***',
              // 		tel: '***',
              // 		card: '***'
              // 	})
              // }
              _this5.lottery = list.max_round_list;
            } else {
              _this5.lottery = list.max_round_list;
            }
          } else {
            // 组装页面摇号 个数
            if (list.not_user_count > 0) {
              for (var i = 0; i < rand.rand_count; i++) {
                _this5.lottery.push({
                  name: '****',
                  code: '****',
                  tel: '****',
                  card: '****'
                });
              }
            }
          } // 组装未摇号人数据


          if (not_rand_user_list !== null) {
            var nameArray = [];
            var telArray = [];
            var codeArray = [];
            var cardArray = [];
            not_rand_user_list.forEach(function (item) {
              nameArray.push(item.name);
              telArray.push(item.tel);
              codeArray.push(item.code);
              cardArray.push(item.card);
            });
            _this5.nameArray = nameArray;
            _this5.telArray = telArray;
            _this5.codeArray = codeArray;
            _this5.cardArray = cardArray;
          } // 页面个数 高度


          if (rand.rand_count > 10) {
            _this5.heightClass = 'height20';
          } else {
            _this5.heightClass = 'height10';
          }
        }
      });
    },
    // 取消摇号
    handleCancel: function handleCancel() {
      // 关闭定时器
      window.clearInterval(this.timeId); // 页面数据 又变成开始摇号

      this.lottery.forEach(function (item) {
        item.name = '****';
        item.code = '****';
        item.tel = '****';
        item.card = '****';
      }); //   取消过后又变成开始摇号

      this.startButton = false;
    },
    // 获取开始摇号
    getStartLottery: debounce(function () {
      var _this6 = this;

      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      // 获取参数
      var query = JSON.parse(JSON.stringify(this.$route.query));
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: query.round,
        type: type
      };
      noStartLottery(data).then(function (res) {
        if (res.status === 200) {
          var _res$data4 = res.data,
              list = _res$data4.list,
              _res$data4$list = _res$data4.list,
              not_user_list = _res$data4$list.not_user_list,
              rand = _res$data4$list.rand,
              not_rand_user_list = _res$data4$list.not_rand_user_list; // 摇号规则

          _this6.rand = rand; // 活动参数

          _this6.list = list; // 获取 未摇号人数

          _this6.laveNumber = list.not_user_count; // 组装未摇号人数据

          if (not_rand_user_list !== null) {
            var nameArray = [];
            var telArray = [];
            var codeArray = [];
            var cardArray = [];
            not_rand_user_list.forEach(function (item) {
              nameArray.push(item.name);
              telArray.push(item.tel);
              codeArray.push(item.code);
              cardArray.push(item.card);
            });
            _this6.nameArray = nameArray;
            _this6.telArray = telArray;
            _this6.codeArray = codeArray;
            _this6.cardArray = cardArray;
          } // 启动开始摇号


          _this6.handleStartLottery();
        }
      });
    }, 200, true),
    // 随机取 固定条数
    getArrayItems: function getArrayItems(data, num) {
      var temp_arr = data.slice(0);
      var return_arr = [];

      for (var i = 0; i < num; i++) {
        // 判断如果数组还有可以取出的元素，以防下标越界
        if (temp_arr.length > 0) {
          // 在数组中产生一个随机索引
          var arrIndex = Math.floor(Math.random() * temp_arr.length); // 将此随机索引的对应数组元素值复制出来

          return_arr[i] = temp_arr[arrIndex]; // 然后删掉此索引的数组元素，这时候temp_arr变为新的数组

          temp_arr.splice(arrIndex, 1);
        } else {
          // 数组中数据项取完后，退出循环，比如数组本来只有10项，但要求取出20项
          break;
        }
      }

      return return_arr;
    },
    // 点击开始 启动摇号
    handleStartLottery: function handleStartLottery() {
      var _this7 = this;

      // 有预设失败的
      if (this.list.is_preinstall) {
        this.$alert('摇号人数异常无法继续摇号，请修复异常。', '提示', {
          confirmButtonText: '好的',
          callback: function callback(action) {}
        });
        return;
      } // 关闭再开 避免多个定时器


      window.clearInterval(this.timeId);
      this.timeId = setInterval(function () {
        _this7.lottery.forEach(function (item) {
          item.name = _this7.nameArray[Math.floor(Math.random() * _this7.nameArray.length)];
          item.tel = _this7.telArray[Math.floor(Math.random() * _this7.telArray.length)];
          item.code = _this7.codeArray[Math.floor(Math.random() * _this7.codeArray.length)];
          item.card = _this7.cardArray[Math.floor(Math.random() * _this7.cardArray.length)];
        });
      }, 10); // 点击以后就显示结束按钮

      this.startButton = true;
    },
    // 点击结束摇号
    handleStopLottery: debounce(function () {
      var _this8 = this;

      // 获取摇号结果
      var data = {
        id: this.rand.id,
        group_id: this.list.group_id
      };
      this.stopLotteryButton = true;
      noStopLottery(data).then(function (res) {
        if (res.status === 200) {
          var _res$data5 = res.data,
              list = _res$data5.list,
              newList = _res$data5.list.newList; //  点击停止摇号 获取剩余摇号人数 - 被摇号人数

          _this8.laveNumber = _this8.list.not_user_count - newList.length; //  当前组是否结束

          _this8.is_rand_next = list.end; // 当 每页个数大于 摇号结果个数

          if (newList.length < _this8.rand.rand_count) {
            _this8.lottery = newList;
          } else {
            _this8.lottery = newList;
          } //	停止摇号打开下一轮摇号


          _this8.is_next = true; // 停止摇号过后 就不能显示取消按钮

          _this8.startButton = false; // 关闭定时器 停止摇号

          window.clearInterval(_this8.timeId);
          _this8.stopLotteryButton = false;
        }
      });
    }, 200, true)
  }
};