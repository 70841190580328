import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import commonLibrary from './common/commonLibrary.vue';
import editCommon from './common/editCommon.vue';
import canvasContent from './common/canvasContent.vue';
import { DiySet, DiyData, DiyCopy, getShowRoom } from '@/api/screen';
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  name: 'rightSetting',
  components: {
    commonLibrary: commonLibrary,
    canvasContent: canvasContent,
    editCommon: editCommon
  },
  computed: _objectSpread(_objectSpread({}, mapMutations(['ISCHANGE'])), mapGetters(['startCommontList', 'afterCommontLIst', 'background', 'isChange', 'isHide'])),
  data: function data() {
    return {
      led_id: '',
      dialogVisible: false,
      ledList: ['上海', '北京'],
      ledIds: [],
      is_hide: "false"
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.event_id = this.$route.query.event_id;
    this.project_id = this.$route.query.project_id;
    this.led_id = this.$route.query.id;
    this.getledInfo();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('rightSetting', ['SET_ACTIVE_DATA', 'SET_DEFALUT_COLOR', 'SET_CHANGE', 'SET_RIGHT_HIDE'])), {}, {
    //   复制屏幕
    copyLed: function copyLed() {
      var _this = this;

      this.getDiyData().then(function (res) {
        _this.dialogVisible = true;
      });
    },
    getDiyData: function getDiyData() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        DiyData().then(function (res) {
          _this2.ledList = res.data.list.filter(function (item) {
            return item.id != _this2.led_id;
          });

          if (_this2.ledList.length > 0) {
            resolve();
          } else {
            _this2.$message.error('没有可复制的屏幕!');
          }
        });
      });
    },
    copyLedSubmit: function copyLedSubmit() {
      var _this3 = this;

      if (this.ledIds.length) {
        DiyCopy({
          led_id: Number(this.led_id),
          led_ids: this.ledIds
        }).then(function (res) {
          _this3.$message.success('复制成功!');
        });
      } else {
        this.$message.error('请选择复制屏幕!');
      }
    },
    save: function save() {
      var _this4 = this;

      var start_json = JSON.stringify(this.startCommontList);
      var end_json = JSON.stringify(this.afterCommontLIst);
      DiySet({
        led_id: Number(this.led_id),
        start_json: start_json,
        end_json: end_json,
        background: this.background,
        is_change: this.isChange,
        is_hide: this.isHide
      }).then(function (res) {
        _this4.$message.success('保存成功!');
      });
    },
    getledInfo: function getledInfo() {
      var _this5 = this;

      getShowRoom({
        project_id: this.project_id,
        id: this.id,
        event_id: this.event_id
      }).then(function (res) {
        var _res$data$ledInfo = res.data.ledInfo,
            start_json = _res$data$ledInfo.start_json,
            end_json = _res$data$ledInfo.end_json,
            background = _res$data$ledInfo.background,
            color_type = _res$data$ledInfo.color_type,
            is_hide = _res$data$ledInfo.is_hide,
            is_change = _res$data$ledInfo.is_change;

        _this5.SET_CHANGE(is_change);

        _this5.SET_RIGHT_HIDE(is_hide);

        _this5.SET_DEFALUT_COLOR(res.data.ledConfInfo.color_type);

        _this5.SET_ACTIVE_DATA({
          start_json: start_json,
          end_json: end_json,
          background: background
        });
      });
    },
    close: function close() {
      this.ledIds = [];
    }
  })
};