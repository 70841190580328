var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "successPopup" }, [
    _c("div", { staticClass: "swicthquota" }, [
      _c(
        "div",
        {
          class: ["swicth-btn", _vm.quotaSwicth == 1 ? "active-btn" : ""],
          on: {
            click: function ($event) {
              return _vm.swicthquota(1)
            },
          },
        },
        [_vm._v("有剩余名额时")]
      ),
      _c(
        "div",
        {
          class: ["swicth-btn", _vm.quotaSwicth == 2 ? "active-btn" : ""],
          on: {
            click: function ($event) {
              return _vm.swicthquota(2)
            },
          },
        },
        [_vm._v("无剩余名额时")]
      ),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "stylePreview" }, [_c("stylePreview")], 1),
      _c("div", { staticClass: "styleSetup" }, [_c("el-table")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }