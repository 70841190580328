var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "userDatails" } },
    [
      _c("div", { staticClass: "top-fixed" }, [
        _c(
          "div",
          { staticClass: "title-top-box align-items" },
          [
            _c("div", { staticClass: "title flex align-items" }, [
              _c("div", [
                _vm._v(" 客户详情 "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "black",
                      "font-size": "16px",
                      "font-weight": "500",
                      "margin-left": "20px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.user.name) + " - " + _vm._s(_vm.user.tel))]
                ),
              ]),
              _c("div", { staticClass: "userText" }, [
                _c("div", { staticClass: "detail-item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "text",
                      staticStyle: { "margin-left": "0" },
                    },
                    [
                      _vm._v(
                        "账号状态：" + _vm._s(_vm.user.status ? "启用" : "禁用")
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName == 0,
                    expression: "activeName == 0",
                  },
                ],
                staticClass: "greens-btn",
                attrs: { size: "small" },
                on: { click: _vm.handleEdit },
              },
              [_vm._v("修改 ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.tabClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "基本信息", name: "0" } }),
          _vm.roles.includes("admin") || _vm.roles.includes("user/preference")
            ? _c("el-tab-pane", { attrs: { label: "意向单和偏好", name: "1" } })
            : _vm._e(),
          _vm.roles.includes("admin") || _vm.roles.includes("user/eventdata")
            ? _c("el-tab-pane", {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "user/eventdata"],
                    expression: "['admin', 'user/eventdata']",
                  },
                ],
                attrs: { label: "活动数据", name: "2" },
              })
            : _vm._e(),
          _vm.roles.includes("admin") || _vm.roles.includes("user/loglist")
            ? _c("el-tab-pane", {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "user/loglist"],
                    expression: "['admin', 'user/loglist']",
                  },
                ],
                attrs: { label: "日志", name: "3" },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == 0,
              expression: "activeName == 0",
            },
          ],
          staticClass: "detail-box",
        },
        [
          _c(
            "div",
            { staticClass: "block" },
            [
              _vm._m(0),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: _vm.isPC ? 16 : 24 } },
                    [
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("姓名："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: { textContent: _vm._s(_vm.user.name) },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("登录手机号："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: { textContent: _vm._s(_vm.user.tel) },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("证件号码："),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.user.card)),
                          ]),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              _vm._s(_vm.projectList.user_code_name) + "："
                            ),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: { textContent: _vm._s(_vm.user.code) },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("置业顾问："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: {
                              textContent: _vm._s(_vm.user.admin_name),
                            },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("限购数量："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: {
                              textContent: _vm._s(_vm.user.buy_count),
                            },
                          }),
                        ]),
                      ]),
                      _vm.matching_order || _vm.is_must_buy_room >= 1
                        ? _c(
                            "el-col",
                            {
                              style: {
                                opacity: _vm.is_must_buy_room >= 1 ? 0 : 1,
                              },
                              attrs: { span: _vm.isPC ? 12 : 24 },
                            },
                            [
                              _c("div", { staticClass: "detail-item" }, [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v("车位最小认购数量："),
                                ]),
                                _c("div", {
                                  staticClass: "text",
                                  domProps: {
                                    textContent: _vm._s(_vm.user.buy_min_car),
                                  },
                                }),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.matching_order || _vm.is_must_buy_room >= 1
                        ? _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 12 : 24 } },
                            [
                              _c("div", { staticClass: "detail-item" }, [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v("车位最大认购数量："),
                                ]),
                                _c("div", {
                                  staticClass: "text",
                                  domProps: {
                                    textContent: _vm._s(_vm.user.buy_max_car),
                                  },
                                }),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("分批选房序号："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: { textContent: _vm._s(_vm.user.num) },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("选房时长限定："),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(_vm.user.min) +
                                " 分 " +
                                _vm._s(_vm.user.sec) +
                                " 秒"
                            ),
                          ]),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("诚意金额："),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm.projectList.cate_room
                              ? _c("div", { staticClass: "renge" }, [
                                  _c("span", [
                                    _vm._v(
                                      "住宅: " + _vm._s(_vm.price.room_price)
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "份数: " + _vm._s(_vm.price.room_number)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.projectList.cate_villa
                              ? _c("div", { staticClass: "renge" }, [
                                  _c("span", [
                                    _vm._v(
                                      "别墅: " + _vm._s(_vm.price.villa_price)
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "份数: " + _vm._s(_vm.price.villa_number)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.projectList.cate_car
                              ? _c("div", { staticClass: "renge" }, [
                                  _c("span", [
                                    _vm._v(
                                      "车位: " + _vm._s(_vm.price.car_price)
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "份数: " + _vm._s(_vm.price.car_number)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.projectList.cate_shops
                              ? _c("div", { staticClass: "renge" }, [
                                  _c("span", [
                                    _vm._v(
                                      "商品: " + _vm._s(_vm.price.shops_price)
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "份数: " + _vm._s(_vm.price.shops_number)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("客户分类："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: { textContent: _vm._s(_vm.user.level) },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("测试摇号结果："),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.user.is_rand ? "显示" : "隐藏")),
                          ]),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("摇号分组："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: {
                              textContent: _vm._s(_vm.user.rand_group),
                            },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: _vm.isPC ? 12 : 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("特殊排序："),
                          ]),
                          _c("div", {
                            staticClass: "text other-text",
                            domProps: {
                              textContent: _vm._s(_vm.user.rand_sort),
                            },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 8 } }),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-left": "10px" },
                      attrs: { span: 20 },
                    },
                    [
                      _c("div", { staticClass: "detail-item" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v("其他信息："),
                        ]),
                        _c("div", {
                          staticClass: "text other-text",
                          domProps: {
                            textContent: _vm._s(_vm.user.other_remark),
                          },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.signUp
            ? [
                _vm.user_audit_owner.length > 0
                  ? _c("div", { staticClass: "block person-mes" }, [
                      _vm._m(1),
                      _c(
                        "div",
                        {
                          staticClass: "mT15 pB20",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "grid",
                                width: "77%",
                                "grid-template-columns": "50% 50%",
                                padding: "20px",
                              },
                            },
                            _vm._l(_vm.user_audit_owner, function (item, i) {
                              return _c("div", { key: i }, [
                                _c(
                                  "div",
                                  { staticClass: "title-button-box pB10" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "buttons",
                                        attrs: { size: "mini" },
                                      },
                                      [
                                        _vm._v(
                                          "第" +
                                            _vm._s(_vm.changeData(i)) +
                                            "产权人"
                                        ),
                                      ]
                                    ),
                                    _vm.XAndQ
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "autonym-container",
                                            class: {
                                              error:
                                                item.is_name === 1 ||
                                                item.status !== 2,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.is_name === 1
                                                    ? "认证异常"
                                                    : item.status === -1
                                                    ? "未通过"
                                                    : _vm.autonymStatus[
                                                        item.status
                                                      ]
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c("div", { staticClass: "detail-item" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v("姓名："),
                                  ]),
                                  _c("div", {
                                    staticClass: "text",
                                    domProps: {
                                      textContent: _vm._s(item.real_name),
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "detail-item" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v("手机号："),
                                  ]),
                                  _c("div", {
                                    staticClass: "text",
                                    domProps: { textContent: _vm._s(item.tel) },
                                  }),
                                ]),
                                _c("div", { staticClass: "detail-item" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v("证件类型："),
                                  ]),
                                  _c("div", {
                                    staticClass: "text",
                                    domProps: {
                                      textContent: _vm._s(item.card_type),
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "detail-item" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v("证件号码："),
                                  ]),
                                  _c("div", { staticClass: "text" }, [
                                    _c("span", [_vm._v(_vm._s(item.card))]),
                                  ]),
                                ]),
                                _c("div", { staticClass: "detail-item" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v("通讯地址："),
                                  ]),
                                  _c("div", {
                                    staticClass: "text",
                                    domProps: {
                                      textContent: _vm._s(item.tx_address),
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "detail-item" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v("其他系统FID："),
                                  ]),
                                  _c("div", {
                                    staticClass: "text",
                                    domProps: {
                                      textContent: _vm._s(item.address),
                                    },
                                  }),
                                ]),
                              ])
                            }),
                            0
                          ),
                          _c("div", { staticStyle: { width: "33.33333%" } }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.activeName == 1
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "user/preference"],
                  expression: "['admin', 'user/preference']",
                },
              ],
              staticClass: "detail-box",
            },
            [
              _c("custom-focus", {
                attrs: {
                  "project-list": _vm.projectList,
                  preference: _vm.preference,
                  "table-data": _vm.tableData,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.activeName == 2
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "user/eventdata"],
                  expression: "['admin', 'user/eventdata']",
                },
              ],
              staticClass: "detail-box",
            },
            [
              _vm.isData
                ? _c("custom-active", { attrs: { user: _vm.user } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.activeName == 3
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "user/loglist"],
                  expression: "['admin', 'user/loglist']",
                },
              ],
              staticClass: "detail-box",
            },
            [
              _c("logView", {
                ref: "logList",
                attrs: { log: _vm.log },
                on: { handleSearchClick: _vm.handleSearchClick },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-h2" }, [
      _c("div", { staticClass: "flex space-between" }, [
        _c("div", [_vm._v("客户信息")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-h2" }, [
      _c("div", { staticClass: "flex space-between" }, [
        _c("div", [_vm._v("产权人信息")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }