var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.is_null
        ? _c(
            "div",
            { staticClass: "noData" },
            [
              _c("img", {
                staticClass: "onImage",
                attrs: { src: require("../../assets/image/icon-qs.png") },
              }),
              _c("div", { staticClass: "onTips" }, [
                _vm._v("还没完善图文资料哦~"),
              ]),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { size: "mini" },
                  on: { click: _vm.handlePerfect },
                },
                [_vm._v("去完善")]
              ),
            ],
            1
          )
        : [
            _c("div", { staticClass: "top-fixed" }, [
              _c("div", { staticClass: "title-top-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("图文设置")]),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _vm.type === "add"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "roomcate/preserve"],
                                expression: "['admin', 'roomcate/preserve']",
                              },
                            ],
                            staticClass: "greens-btn",
                            attrs: {
                              loading: _vm.saveLoading,
                              type: "primary",
                              size: "small",
                            },
                            on: { click: _vm.handleSave },
                          },
                          [_vm._v("保存 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "roomcate/preserve"],
                                expression: "['admin', 'roomcate/preserve']",
                              },
                            ],
                            staticClass: "greens-btn",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleEdit },
                          },
                          [_vm._v("修改 ")]
                        ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "roomcate/export"],
                            expression: "['admin', 'roomcate/export']",
                          },
                        ],
                        staticClass: "btnHollowGreen",
                        attrs: { size: "small" },
                        on: { click: _vm.handleExport },
                      },
                      [_vm._v("导出复核 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "detail-box mT10" }, [
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c(
                    "div",
                    { staticClass: "title-h2" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.customSetting.imgTitle) + "资料 "
                      ),
                      _vm.type === "add"
                        ? _c("svg-icon", {
                            attrs: {
                              "class-name": "icon",
                              "icon-class": "sort",
                            },
                            on: { click: _vm.showEditDialog },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.infoData.cate_room === 1
                    ? _c(
                        "div",
                        { staticClass: "flex align-items title-h3" },
                        [
                          _vm.type === "add" || _vm.roomResidence.length > 0
                            ? _c("div", [
                                _c("span", { staticClass: "button-h3" }, [
                                  _vm._v("住宅"),
                                ]),
                                _c("span", { staticClass: "numbers" }, [
                                  _vm._v(
                                    "(" + _vm._s(_vm.roomResidence.length) + ")"
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.type === "add"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "greens-btn mL10",
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBatchImg(3)
                                    },
                                  },
                                },
                                [_vm._v("批量替换图片 ")]
                              )
                            : _vm._e(),
                          _vm.type === "add"
                            ? _c("custom-switch", {
                                staticStyle: { "margin-left": "auto" },
                                attrs: {
                                  svalue: _vm.roomResidenceIsShow,
                                  trueLabel: "显示",
                                  falseLabel: "隐藏",
                                },
                                on: { change: _vm.onRoomResidenceIsShow },
                              })
                            : _vm._e(),
                          _vm.type === "add"
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "控制客户端房源详情页面是否展示户型图。",
                                    placement: "right",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "tips el-icon-question",
                                    staticStyle: { color: "#999" },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    { staticClass: "room-block", attrs: { gutter: 20 } },
                    _vm._l(_vm.roomResidence, function (item, index) {
                      return _c(
                        "el-col",
                        { key: item.id, attrs: { span: 6 } },
                        [
                          _c("div", { staticClass: "room-box" }, [
                            _c("div", { staticClass: "room-bg room-h2" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "room-img",
                                  staticStyle: { position: "relative" },
                                },
                                [
                                  item.image
                                    ? _c("el-image", {
                                        staticClass: "img-style",
                                        attrs: { src: _vm.imgUrl + item.image },
                                      })
                                    : _c("el-image", {
                                        staticClass: "img-style",
                                        attrs: {
                                          src: require("../../assets/image/default2.png"),
                                        },
                                      }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        "z-index": "10",
                                        top: "10px",
                                        right: "10px",
                                      },
                                    },
                                    [
                                      _vm.type === "add"
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  item.is_show === 1
                                                    ? "隐藏"
                                                    : "显示",
                                                placement: "bottom",
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  "font-size": "22px",
                                                  color: "rgb(34, 162, 113)",
                                                },
                                                attrs: {
                                                  "class-name": "color-hui",
                                                  "icon-class":
                                                    item.is_show === 1
                                                      ? "eye"
                                                      : "eye-open",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onItemShow(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "room-text-box" }, [
                                _c("div", { staticClass: "room-text flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex space-between w10" },
                                    [
                                      _c("div", { staticClass: "aic" }, [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v("户型名称："),
                                        ]),
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                      ]),
                                      _vm.type === "add"
                                        ? _c(
                                            "div",
                                            { staticClass: "icon-box flex" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "svg-edit",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEditRoom(
                                                        1,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "class-name": "greens",
                                                      "icon-class": "edit",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "svg-del",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDelRoom(
                                                        1,
                                                        index,
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "class-name":
                                                        "color-red delete-svg",
                                                      "icon-class": "delete",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "room-text flex" }, [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v("户型结构："),
                                  ]),
                                  _c("span", { staticClass: "text" }, [
                                    _vm._v(_vm._s(item.type)),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                  _vm.customSetting.cate_villa === 1 ||
                  _vm.customSetting.cate_shops === 1 ||
                  _vm.customSetting.cate_car === 1
                    ? _c(
                        "div",
                        { staticClass: "flex align-items title-h3" },
                        [
                          _vm.type === "add" || _vm.roomVilla.length > 0
                            ? _c("div", [
                                _c("span", { staticClass: "button-h3" }, [
                                  _vm._v("车位"),
                                ]),
                                _vm.roomVilla.length > 0
                                  ? _c("span", { staticClass: "numbers" }, [
                                      _vm._v(
                                        "(" + _vm._s(_vm.roomVilla.length) + ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.type === "add"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "greens-btn mL10",
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBatchImg(4)
                                    },
                                  },
                                },
                                [_vm._v("批量替换图片 ")]
                              )
                            : _vm._e(),
                          _vm.type === "add"
                            ? _c("custom-switch", {
                                staticStyle: { "margin-left": "auto" },
                                attrs: {
                                  svalue: _vm.carResidenceIsShow,
                                  trueLabel: "显示",
                                  falseLabel: "隐藏",
                                },
                                on: { change: _vm.onCarResidenceIsShow },
                              })
                            : _vm._e(),
                          _vm.type === "add"
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "控制客户端车位详情页面是否展示户型图。",
                                    placement: "right",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "tips el-icon-question",
                                    staticStyle: { color: "#999" },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    { staticClass: "room-block", attrs: { gutter: 20 } },
                    _vm._l(_vm.roomVilla, function (item, index) {
                      return _c(
                        "el-col",
                        { key: item.id, attrs: { span: 6 } },
                        [
                          _c("div", { staticClass: "room-box" }, [
                            _c("div", { staticClass: "room-bg room-h2" }, [
                              _c(
                                "div",
                                { staticClass: "room-img" },
                                [
                                  item.image
                                    ? _c("el-image", {
                                        staticClass: "img-style",
                                        attrs: { src: _vm.imgUrl + item.image },
                                      })
                                    : _c("el-image", {
                                        staticClass: "img-style",
                                        attrs: {
                                          src: require("../../assets/image/default2.png"),
                                        },
                                      }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        "z-index": "10",
                                        top: "10px",
                                        right: "10px",
                                      },
                                    },
                                    [
                                      _vm.type === "add"
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  item.is_show === 1
                                                    ? "隐藏"
                                                    : "显示",
                                                placement: "bottom",
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  "font-size": "22px",
                                                  color: "rgb(34, 162, 113)",
                                                },
                                                attrs: {
                                                  "class-name": "color-hui",
                                                  "icon-class":
                                                    item.is_show === 1
                                                      ? "eye"
                                                      : "eye-open",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onCardItemShow(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "room-text-box" }, [
                                _c("div", { staticClass: "room-text flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex space-between w10" },
                                    [
                                      _c("div", { staticClass: "aic" }, [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v("类型名称："),
                                        ]),
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                      ]),
                                      _vm.type === "add"
                                        ? _c(
                                            "div",
                                            { staticClass: "icon-box flex" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "svg-edit",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEditRoom(
                                                        2,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "class-name": "greens",
                                                      "icon-class": "edit",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "svg-del",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDelRoom(
                                                        2,
                                                        index,
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "class-name":
                                                        "color-red delete-svg",
                                                      "icon-class": "delete",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "room-text flex" }, [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v("类型结构："),
                                  ]),
                                  _c("span", { staticClass: "text" }, [
                                    _vm._v(_vm._s(item.type)),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("div", { staticClass: "title-h2" }, [_vm._v("楼栋资料")]),
                  _vm.infoData.cate_room === 1
                    ? _c(
                        "div",
                        { staticClass: "flex align-items title-h3" },
                        [
                          _vm.type === "add" || _vm.buildResidence.length > 0
                            ? _c("div", [
                                _c("span", { staticClass: "button-h3" }, [
                                  _vm._v("住宅"),
                                ]),
                                _vm.buildResidence.length > 0
                                  ? _c("span", { staticClass: "numbers" }, [
                                      _vm._v(
                                        "(" +
                                          _vm._s(_vm.buildResidence.length) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.type === "add"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "greens-btn mL10",
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBatchImg(1)
                                    },
                                  },
                                },
                                [_vm._v("批量替换图片 ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    { staticClass: "room-block", attrs: { gutter: 20 } },
                    _vm._l(_vm.buildResidence, function (item, index) {
                      return _c(
                        "el-col",
                        { key: item.id, attrs: { span: 6 } },
                        [
                          _c("div", { staticClass: "room-box" }, [
                            _c("div", { staticClass: "room-bg room-h" }, [
                              _c(
                                "div",
                                { staticClass: "room-img" },
                                [
                                  item.image
                                    ? _c("el-image", {
                                        staticClass: "img-style",
                                        attrs: { src: _vm.imgUrl + item.image },
                                      })
                                    : _c("el-image", {
                                        staticClass: "img-style",
                                        attrs: {
                                          src: require("../../assets/image/default.png"),
                                        },
                                      }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "room-text-box" }, [
                                _c("div", { staticClass: "room-text flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "space-between flex w10" },
                                    [
                                      _c("div", { staticClass: "aic" }, [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v("楼栋名称："),
                                        ]),
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                      ]),
                                      _vm.type === "add"
                                        ? _c(
                                            "div",
                                            { staticClass: "icon-box flex" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "svg-edit",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEditBuild(
                                                        1,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "class-name":
                                                        "edit delete-svg ",
                                                      "icon-class": "edit",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "svg-del",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDelBuild(
                                                        1,
                                                        index,
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "class-name":
                                                        "color-red delete-svg",
                                                      "icon-class": "delete",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "room-text flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "space-between flex w10" },
                                    [
                                      _c("div", { staticClass: "aic" }, [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v("统计名称："),
                                        ]),
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.funTextBuild(item.type_id)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                  _vm.customSetting.cate_villa === 1 ||
                  _vm.customSetting.cate_shops === 1 ||
                  _vm.customSetting.cate_car === 1
                    ? _c(
                        "div",
                        { staticClass: "flex align-items title-h3" },
                        [
                          _vm.type === "add" || _vm.buildVilla.length > 0
                            ? _c("div", [
                                _c("span", { staticClass: "button-h3" }, [
                                  _vm._v("车位"),
                                ]),
                                _vm.buildVilla.length > 0
                                  ? _c("span", { staticClass: "numbers" }, [
                                      _vm._v(
                                        "(" +
                                          _vm._s(_vm.buildVilla.length) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.type === "add"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "greens-btn mL10",
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBatchImg(2)
                                    },
                                  },
                                },
                                [_vm._v("批量替换图片 ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    { staticClass: "room-block", attrs: { gutter: 20 } },
                    _vm._l(_vm.buildVilla, function (item, index) {
                      return _c(
                        "el-col",
                        { key: item.id, attrs: { span: 6 } },
                        [
                          _c("div", { staticClass: "room-box" }, [
                            _c("div", { staticClass: "room-bg room-h" }, [
                              _c(
                                "div",
                                { staticClass: "room-img" },
                                [
                                  item.image
                                    ? _c("el-image", {
                                        staticClass: "img-style",
                                        attrs: { src: _vm.imgUrl + item.image },
                                      })
                                    : _c("el-image", {
                                        staticClass: "img-style",
                                        attrs: {
                                          src: require("../../assets/image/default.png"),
                                        },
                                      }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "room-text-box" }, [
                                _c("div", { staticClass: "room-text flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex space-between w10" },
                                    [
                                      _c("div", { staticClass: "aic" }, [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v("楼栋名称："),
                                        ]),
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                      ]),
                                      _vm.type === "add"
                                        ? _c(
                                            "div",
                                            { staticClass: "icon-box" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "svg-edit",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEditBuild(
                                                        2,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "class-name": "greens",
                                                      "icon-class": "edit",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "svg-del",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDelBuild(
                                                        2,
                                                        index,
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "class-name":
                                                        "color-red delete-svg",
                                                      "icon-class": "delete",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "room-text flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex space-between w10" },
                                    [
                                      _c("div", { staticClass: "aic" }, [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v("统计名称："),
                                        ]),
                                        _c("span", { staticClass: "text" }, [
                                          _vm._v(
                                            _vm._s(_vm.funText(item.type_id))
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "block" }, [
                _c("div", { staticClass: "img-box" }, [
                  _c("div", { staticClass: "title-h2" }, [_vm._v("宣传资料")]),
                  _c("div", { staticClass: "background-container" }, [
                    _c("div", { staticClass: "background-setting" }, [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "setting__title" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  disabled: _vm.type === "edit",
                                  label: 0,
                                },
                                model: {
                                  value: _vm.project_images.index_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.project_images,
                                      "index_type",
                                      $$v
                                    )
                                  },
                                  expression: "project_images.index_type",
                                },
                              },
                              [_vm._v("首页背景图 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "preview mT10" },
                          [
                            _vm.project_images.login_image
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "orange-btn-hollows",
                                    attrs: { size: "mini" },
                                    on: { click: _vm.handlePreview },
                                  },
                                  [_vm._v("预览 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "file_content" },
                        [
                          _vm.type === "edit"
                            ? _c("div", { staticClass: "img-list" }, [
                                _c(
                                  "div",
                                  { staticClass: "img" },
                                  [
                                    _vm.project_images.login_image
                                      ? _c("el-image", {
                                          staticClass: "image-size",
                                          attrs: {
                                            src:
                                              _vm.imgUrl +
                                              _vm.project_images.login_image,
                                            fit: "contain",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _c("GMainUpload", {
                                attrs: {
                                  "list-type": _vm.listTypes,
                                  limit: 1,
                                  "file-list": _vm.bgList,
                                  text: "不大于2M的图片1张，参考尺寸1065*465px。",
                                  accept: _vm.$imgSel,
                                },
                                on: {
                                  handleSuccess: _vm.handleBgSuccess,
                                  handOperation: _vm.handleSaveImg,
                                },
                              }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "background-setting" }, [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "setting__title" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  label: 1,
                                  disabled: _vm.type === "edit",
                                },
                                model: {
                                  value: _vm.project_images.index_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.project_images,
                                      "index_type",
                                      $$v
                                    )
                                  },
                                  expression: "project_images.index_type",
                                },
                              },
                              [_vm._v("首页视频 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "setting__extend" },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#16a077",
                                "active-value": 1,
                                "inactive-value": 0,
                                "inactive-text": "填充屏幕",
                                disabled: _vm.type === "edit",
                              },
                              model: {
                                value: _vm.project_images.video_fill_status,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.project_images,
                                    "video_fill_status",
                                    $$v
                                  )
                                },
                                expression: "project_images.video_fill_status",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "file_content" },
                        [
                          _vm.type === "edit"
                            ? _c("div", { staticClass: "img-list" }, [
                                _c("div", { staticClass: "img" }, [
                                  _vm.project_images.index_video
                                    ? _c("video", {
                                        staticClass: "image-size",
                                        attrs: {
                                          src:
                                            _vm.imgUrl +
                                            _vm.project_images.index_video,
                                          controls: "",
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                              ])
                            : _c("GMainUpload", {
                                attrs: {
                                  "file-url": "public/video",
                                  limit: 1,
                                  "file-size": 100,
                                  action: _vm.videoURL,
                                  "list-type": _vm.listTypes,
                                  "file-list": _vm.bgVideoList,
                                  accept: _vm.$vidSel,
                                  text: "请上传视频文件，且大小不超过100MB",
                                },
                                on: {
                                  handleSuccess: _vm.handleVideoFileSuccess,
                                },
                              }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm.adminList.home_plate_project
                    ? _c("div", { staticClass: "background-container" }, [
                        _c("div", { staticClass: "background-setting" }, [
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "setting__title" },
                              [
                                _vm._v(" 项目介绍图 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "若无需展示，请联系SAAS端关闭！",
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-question" })]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "file_content" },
                            [
                              _vm.type === "edit"
                                ? _c("div", { staticClass: "img-list" }, [
                                    _c(
                                      "div",
                                      { staticClass: "img" },
                                      [
                                        _vm.infoData.project_images
                                          .event_images &&
                                        JSON.stringify(
                                          _vm.infoData.project_images
                                        ) !== "{}"
                                          ? _vm._l(
                                              _vm.infoData.project_images.event_images.split(
                                                ","
                                              ),
                                              function (item, _index) {
                                                return _c("el-image", {
                                                  key: _index,
                                                  staticClass: "image-size",
                                                  attrs: {
                                                    src: _vm.imgUrl + item,
                                                    fit: "contain",
                                                  },
                                                })
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ])
                                : _c("GMainUpload", {
                                    attrs: {
                                      "list-type": _vm.listTypes,
                                      text: "不大于2M的图片，参考尺寸宽度710px，高度不限。",
                                      "file-list": _vm.ActivityList,
                                      accept: _vm.$imgSel,
                                    },
                                    on: {
                                      handleSuccess: _vm.handleActivitySuccess,
                                      handOperation: _vm.handleSaveImg,
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.adminList.guide_map_show === 1
                    ? _c("div", { staticClass: "background-container" }, [
                        _c("div", { staticClass: "background-setting" }, [
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "setting__title" },
                              [
                                _vm._v(" 操作指引图 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "若无需展示，请联系SAAS端关闭！",
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-question" })]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "file_content" },
                            [
                              _vm.type === "edit"
                                ? _c("div", { staticClass: "img-list" }, [
                                    _c(
                                      "div",
                                      { staticClass: "img" },
                                      [
                                        _vm.infoData.project_images.guide &&
                                        JSON.stringify(
                                          _vm.infoData.project_images
                                        ) !== "{}"
                                          ? _vm._l(
                                              _vm.infoData.project_images.guide.split(
                                                ","
                                              ),
                                              function (item, _index) {
                                                return _c("el-image", {
                                                  key: _index,
                                                  staticClass: "image-size",
                                                  attrs: {
                                                    src: _vm.imgUrl + item,
                                                    fit: "contain",
                                                  },
                                                })
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ])
                                : _c("GMainUpload", {
                                    attrs: {
                                      "list-type": _vm.listTypes,
                                      text: "不大于2M的图片，参考尺寸宽度710px，高度不限。",
                                      "file-list": _vm.guideList,
                                      accept: _vm.$imgSel,
                                    },
                                    on: {
                                      handleSuccess: _vm.handleGuideSuccess,
                                      handOperation: _vm.handleSaveImg,
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "block mb0" }, [
                _c(
                  "div",
                  { staticClass: "text-box" },
                  [
                    _vm._m(0),
                    _c(
                      "el-row",
                      { staticClass: "list-img", attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "text-nowrap", attrs: { span: 2 } },
                          [
                            _c(
                              "div",
                              { staticClass: "input-name text-right" },
                              [
                                _c("span", { staticClass: "color-red" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v("项目联系电话："),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            attrs: {
                              span: _vm.isPC ? 21 : 24,
                              offset: _vm.isPC ? 1 : 0,
                            },
                          },
                          [
                            _vm.type === "edit"
                              ? _c("span", { staticClass: "texts" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.project_notice.project_tel) +
                                      " "
                                  ),
                                ])
                              : _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.project_notice.project_tel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.project_notice,
                                        "project_tel",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "project_notice.project_tel",
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "list-img", attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "text-nowrap", attrs: { span: 2 } },
                          [
                            _c(
                              "div",
                              { staticClass: "input-name text-right" },
                              [
                                _c("span", { staticClass: "color-red" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v("登录时须知名称："),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            attrs: {
                              span: _vm.isPC ? 21 : 24,
                              offset: _vm.isPC ? 1 : 0,
                            },
                          },
                          [
                            _vm.type === "edit"
                              ? _c("span", { staticClass: "texts" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.project_notice.name) + " "
                                  ),
                                ])
                              : _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    maxlength: "16",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.project_notice.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.project_notice,
                                        "name",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "project_notice.name",
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "list-img", attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "text-nowrap", attrs: { span: 2 } },
                          [
                            _c(
                              "div",
                              { staticClass: "input-name text-right" },
                              [
                                _c("span", { staticClass: "color-red" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v("登录时须知正文："),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            attrs: {
                              span: _vm.isPC ? 21 : 24,
                              offset: _vm.isPC ? 1 : 0,
                            },
                          },
                          [
                            _c("kind-editor", {
                              ref: "kindEditor",
                              attrs: {
                                readonly: _vm.type === "edit",
                                content: _vm.project_notice.notice_text,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "list-img", attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "text-nowrap", attrs: { span: 2 } },
                          [
                            _c(
                              "div",
                              { staticClass: "input-name text-right" },
                              [
                                _c("span", { staticClass: "color-red" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v("认购成功的通知："),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            attrs: {
                              span: _vm.isPC ? 21 : 24,
                              offset: _vm.isPC ? 1 : 0,
                            },
                          },
                          [
                            _c("kind-editor", {
                              ref: "kinContent",
                              attrs: {
                                id: "kindEditor-ids",
                                height: "100",
                                readonly: _vm.type === "edit",
                                content: _vm.project_notice.content,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "list-img", attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "text-nowrap", attrs: { span: 2 } },
                          [
                            _c(
                              "div",
                              { staticClass: "input-name text-right" },
                              [
                                _vm._v(" 确认订单提示: "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content:
                                        "客户端确认订单弹窗中，可展示自定义内容！",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                      staticStyle: { color: "#999" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            attrs: {
                              span: _vm.isPC ? 21 : 24,
                              offset: _vm.isPC ? 1 : 0,
                            },
                          },
                          [
                            _c("el-switch", {
                              staticStyle: { "margin-bottom": "15px" },
                              attrs: {
                                disabled: _vm.type === "edit",
                                "inactive-value": 0,
                                "active-value": 1,
                                "active-color": "#16a077",
                              },
                              model: {
                                value: _vm.project_notice.buy_prompt_show,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.project_notice,
                                    "buy_prompt_show",
                                    $$v
                                  )
                                },
                                expression: "project_notice.buy_prompt_show",
                              },
                            }),
                            _vm.project_notice.buy_prompt_show
                              ? _c("kind-editor", {
                                  ref: "kinContentBuy",
                                  attrs: {
                                    id: "kindEditor-buy",
                                    height: "100",
                                    readonly: _vm.type === "edit",
                                    content: _vm.project_notice.buy_prompt,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-box" },
                  [
                    _vm._m(1),
                    _vm.adminList.sign_up
                      ? _c(
                          "el-row",
                          { staticClass: "list-img", attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "text-nowrap",
                                attrs: { span: 2 },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-name text-right" },
                                  [
                                    _c("span", { staticClass: "color-red" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v("签约成功提示："),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: {
                                  span: _vm.isPC ? 21 : 24,
                                  offset: _vm.isPC ? 1 : 0,
                                },
                              },
                              [
                                _c("kind-editor", {
                                  ref: "kinContents",
                                  attrs: {
                                    id: "kindEditor-idss",
                                    height: "100",
                                    readonly: _vm.type === "edit",
                                    content: _vm.project_notice.sign_prompt,
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "margin-top": "15px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input-name text-right",
                                        staticStyle: { "margin-right": "10px" },
                                      },
                                      [_vm._v("提示内容复制功能：")]
                                    ),
                                    _vm.type === "edit"
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.project_notice
                                                  .sign_prompt_copy
                                                  ? "启用"
                                                  : "不启用"
                                              )
                                            ),
                                          ]
                                        )
                                      : [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: 1 },
                                              model: {
                                                value:
                                                  _vm.project_notice
                                                    .sign_prompt_copy,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.project_notice,
                                                    "sign_prompt_copy",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "project_notice.sign_prompt_copy",
                                              },
                                            },
                                            [_vm._v("启用")]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: 0 },
                                              model: {
                                                value:
                                                  _vm.project_notice
                                                    .sign_prompt_copy,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.project_notice,
                                                    "sign_prompt_copy",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "project_notice.sign_prompt_copy",
                                              },
                                            },
                                            [_vm._v("不启用")]
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    false
                      ? _c(
                          "el-row",
                          { staticClass: "list-img", attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "text-nowrap",
                                attrs: { span: 2 },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-name text-right" },
                                  [
                                    _c("span", { staticClass: "color-red" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v("认购成功弹窗设置："),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: {
                                  span: _vm.isPC ? 21 : 24,
                                  offset: _vm.isPC ? 1 : 0,
                                },
                              },
                              [_c("rengoSuccessPopup")],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ],
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "before-close": _vm.handelClose,
            title: _vm.unitTitle,
            "destroy-on-close": "",
            visible: _vm.isUnit,
            width: "500px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isUnit = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "min-height": "280px" } },
            [
              _c(
                "el-form",
                { ref: "room", attrs: { model: _vm.room, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.newtype == 2 ? "类型名称" : "户型名称",
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          maxlength: "10",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.room.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.room, "name", $$v)
                          },
                          expression: "room.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.newtype == 2 ? "类型结构" : "户型结构",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          maxlength: "10",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.room.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.room, "type", $$v)
                          },
                          expression: "room.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.newtype == 2 ? "类型图片" : "户型图片",
                        prop: "image",
                      },
                    },
                    [
                      _vm.isUnit
                        ? _c("GMainUpload", {
                            attrs: {
                              "list-type": _vm.listTypes,
                              text: "建议尺寸750*700px，不大于5M",
                              "file-list": _vm.roomFileList,
                              accept: _vm.$imgSel,
                            },
                            on: {
                              handleSuccess: _vm.handleRoomImg,
                              handOperation: _vm.handOperation,
                            },
                          })
                        : _vm._e(),
                      _c("el-input", {
                        staticClass: "node",
                        model: {
                          value: _vm.room.image,
                          callback: function ($$v) {
                            _vm.$set(_vm.room, "image", $$v)
                          },
                          expression: "room.image",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handelClose } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.unitLoading,
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleSaveRoom("room")
                    },
                  },
                },
                [_vm._v("保 存 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "before-close": _vm.handelClose,
            title: _vm.buildTitle,
            "destroy-on-close": "",
            visible: _vm.isBuild,
            width: "500px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isBuild = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "build",
              attrs: { model: _vm.build, rules: _vm.buildRules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.newtypeC == 2 ? "楼栋名称" : "楼栋名称",
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: "",
                      size: "small",
                      maxlength: "10",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.build.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.build, "name", $$v)
                      },
                      expression: "build.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "统计名称", prop: "type_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.build.type_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.build, "type_id", $$v)
                        },
                        expression: "build.type_id",
                      },
                    },
                    [
                      _vm.newtypeC === 2
                        ? _vm._l(_vm.car_build_type, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          })
                        : _vm._e(),
                      _vm.newtypeC === 1
                        ? _vm._l(_vm.room_build_type, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.newtypeC == 2 ? "楼栋图片" : "楼栋图片" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.build.typeImg,
                        callback: function ($$v) {
                          _vm.$set(_vm.build, "typeImg", $$v)
                        },
                        expression: "build.typeImg",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("使用默认图片"),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("自行上传"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.build.typeImg === 2
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        required: _vm.build.typeImg === 1 ? true : false,
                        label: "上传图片",
                        prop: "image",
                      },
                    },
                    [
                      _vm.isBuild
                        ? _c("GMainUpload", {
                            attrs: {
                              limit: 1,
                              "list-type": _vm.listTypes,
                              "file-list": _vm.buildList,
                              text: "建议尺寸670*560px，不大于5M.",
                              accept: _vm.$imgSel,
                            },
                            on: {
                              handleSuccess: _vm.handleBuildImg,
                              handOperation: _vm.handleBuildOperation,
                            },
                          })
                        : _vm._e(),
                      _c("el-input", {
                        staticClass: "node",
                        model: {
                          value: _vm.build.image,
                          callback: function ($$v) {
                            _vm.$set(_vm.build, "image", $$v)
                          },
                          expression: "build.image",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handelClose } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.buildLoading,
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.handleSaveBuild },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.isBatchBuildTitle,
            visible: _vm.isBatchBuild,
            width: "500px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isBatchBuild = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "BatchBuild",
              attrs: { model: _vm.BatchBuild, rules: _vm.buildBachRules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.newtypeC == 2 ? "区域图片" : "楼栋图片" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.BatchBuild.typeImg,
                        callback: function ($$v) {
                          _vm.$set(_vm.BatchBuild, "typeImg", $$v)
                        },
                        expression: "BatchBuild.typeImg",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("使用默认图片"),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("自行上传"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.BatchBuild.typeImg === 2
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        required: _vm.BatchBuild.typeImg === 1 ? true : false,
                        label: "上传图片",
                        prop: "image",
                      },
                    },
                    [
                      _vm.isBatchBuild
                        ? _c("GMainUpload", {
                            attrs: {
                              "list-type": _vm.listTypes,
                              "file-list": _vm.buildList,
                              text: "建议尺寸750*700px，不大于5M",
                              accept: _vm.$imgSel,
                            },
                            on: {
                              handleSuccess: _vm.handleBatchBuildSuccess,
                              handOperation: _vm.handleBatchBuild,
                            },
                          })
                        : _vm._e(),
                      _vm.isBatchBuild
                        ? _c("el-input", {
                            staticClass: "node",
                            model: {
                              value: _vm.BatchBuild.image,
                              callback: function ($$v) {
                                _vm.$set(_vm.BatchBuild, "image", $$v)
                              },
                              expression: "BatchBuild.image",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isBatchBuild = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.buildLoadingBatch,
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.handleSaveBatchBuild },
                },
                [_vm._v("保 存 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "预览",
            "destroy-on-close": "",
            visible: _vm.isPdf,
            center: "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isPdf = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "pdf-box" },
            [
              _c("pdf", {
                ref: "pdf",
                attrs: { src: _vm.pdfUrl, visible: _vm.isPdf },
                on: {
                  "update:visible": function ($event) {
                    _vm.isPdf = $event
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "预览", visible: _vm.isBg, width: "415px" },
          on: {
            "update:visible": function ($event) {
              _vm.isBg = $event
            },
          },
        },
        [
          _c("div", { staticClass: "bg-box" }, [
            _c("div", { staticClass: "bg", staticStyle: { "z-index": "2" } }),
            _c(
              "div",
              { staticClass: "bg-top" },
              [
                _c("el-image", {
                  staticStyle: {
                    "border-radius": "8px",
                    width: "356px",
                    height: "155px",
                  },
                  attrs: { src: _vm.imgUrl + _vm.project_images.login_image },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.editDialog },
          on: {
            "update:visible": function ($event) {
              _vm.editDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _vm.infoData.cate_room === 1 &&
              (_vm.customSetting.cate_villa === 1 ||
                _vm.customSetting.cate_shops === 1 ||
                _vm.customSetting.cate_car === 1)
                ? _c(
                    "el-radio-group",
                    {
                      attrs: { fill: "#22A271", size: "mini" },
                      model: {
                        value: _vm.radioType,
                        callback: function ($$v) {
                          _vm.radioType = $$v
                        },
                        expression: "radioType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("房源"),
                      ]),
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("车位"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.radioType === 0
                ? [
                    _c(
                      "el-table",
                      { attrs: { data: _vm.roomResidence } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "sort",
                            label: "排序",
                            width: "50",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "name",
                            label: "姓名",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "address",
                            label: "操作",
                            width: "120",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      [
                                        scope.$index !== 0
                                          ? _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "move_up",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleSort(
                                                    scope.$index,
                                                    -1
                                                  )
                                                },
                                              },
                                            })
                                          : _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens forbid",
                                                "icon-class": "move_up",
                                              },
                                            }),
                                        scope.$index !==
                                        _vm.roomResidence.length - 1
                                          ? _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "move_down",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleSort(
                                                    scope.$index,
                                                    1
                                                  )
                                                },
                                              },
                                            })
                                          : _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens forbid",
                                                "icon-class": "move_down",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2369828026
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.radioType === 1
                ? [
                    _c(
                      "el-table",
                      { attrs: { data: _vm.roomVilla } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "sort",
                            label: "排序",
                            width: "50",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "name",
                            label: "姓名",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            property: "address",
                            label: "操作",
                            width: "120",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      [
                                        scope.$index !== 0
                                          ? _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "move_up",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleSort(
                                                    scope.$index,
                                                    -1
                                                  )
                                                },
                                              },
                                            })
                                          : _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens forbid",
                                                "icon-class": "move_up",
                                              },
                                            }),
                                        scope.$index !==
                                        _vm.roomVilla.length - 1
                                          ? _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "move_down",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleSort(
                                                    scope.$index,
                                                    1
                                                  )
                                                },
                                              },
                                            })
                                          : _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens forbid",
                                                "icon-class": "move_down",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            969476160
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "title-h2",
        staticStyle: { display: "flex", "justify-content": "space-between" },
      },
      [_c("span", [_vm._v("文本资料")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "title-h2",
        staticStyle: { display: "flex", "justify-content": "space-between" },
      },
      [_c("span", [_vm._v("弹窗设置")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }