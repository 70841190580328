var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container sendNumber" },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "top_staus card top_row shadow" }, [
          _vm._m(0),
          _c("div", { staticClass: "state" }, [
            _c("div", { staticClass: "dot optional" }),
            _c("span", [_vm._v("空号")]),
            _c("div", { staticClass: "dot has" }),
            _c("span", [_vm._v("已占号")]),
            _c("div", { staticClass: "dot use" }),
            _c("span", [_vm._v("已派号")]),
            _c("div", { staticClass: "dot uses" }),
            _c("span", [_vm._v("已作废")]),
            _c("div", [
              _c(
                "span",
                {
                  staticClass: "name",
                  staticStyle: { "margin-left": "1.2rem" },
                },
                [
                  _vm._v(
                    " 已派(已占)最大号：" +
                      _vm._s(_vm.nowNumType.show_max_num) +
                      " "
                  ),
                ]
              ),
              _c("span", { staticClass: "slash" }, [_vm._v("/")]),
              _c("span", [
                _vm._v("已派号：" + _vm._s(_vm.nowNumType.count || 0) + "个"),
              ]),
              _c("span", { staticClass: "slash" }, [_vm._v("/")]),
              _c("span", [
                _vm._v(
                  "已占号：" + _vm._s(_vm.nowNumType.null_count || 0) + "个"
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "card num_card shadow" }, [
          _c("div", { staticClass: "card type_row" }, [
            _c(
              "div",
              { staticClass: "list" },
              [
                _vm._l(_vm.numTypeList, function (item, i) {
                  return [
                    item.prefix
                      ? _c(
                          "div",
                          {
                            key: i,
                            class: ["item", { ac_item: i == _vm.numTypeIndex }],
                            on: {
                              click: function ($event) {
                                return _vm.changeNumType(i)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.prefix) + " ")]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "option" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "sendno/select"],
                        expression: "['admin', 'sendno/select']",
                      },
                    ],
                    staticClass: "orange-btn",
                    attrs: { size: "small" },
                    on: { click: _vm.actualRowNum },
                  },
                  [_vm._v("实际选号")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "sendno/autoselect"],
                        expression: "['admin', 'sendno/autoselect']",
                      },
                    ],
                    staticClass: "orange-btn",
                    attrs: { size: "small" },
                    on: { click: _vm.endRowNum },
                  },
                  [_vm._v("末位选号")]
                ),
              ],
              1
            ),
            _vm.numTypeList.length
              ? _c(
                  "div",
                  { staticClass: "option mL10" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btnHollowGreen",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                        on: { click: _vm.toSendNumList },
                      },
                      [_vm._v(" 派号记录 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btnHollowGreen",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.reqNumList(true)
                          },
                        },
                      },
                      [_vm._v(" 刷新 ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "card num_list" },
            [
              _c("numList", {
                ref: "numList",
                attrs: {
                  audit: _vm.audit,
                  random: _vm.random,
                  "now-num": _vm.nowNum,
                  "one-group-num": _vm.oneGroupNum,
                  "type-index": _vm.numTypeIndex,
                  "list-obj": _vm.nowNumType.listObj,
                  "max-num": _vm.nowNumType.max_num,
                  "min-num": _vm.min,
                  total: _vm.nowNumType.total_count,
                  "num-types": _vm.numType,
                  is_show_four: _vm.audit.is_show_four,
                },
                on: {
                  selectNum: _vm.selectNum,
                  reqNumDetail: _vm.reqNumDetail,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "card select_num" }, [
          _vm._v(_vm._s(_vm.nowSelectNum)),
        ]),
        _vm.nowUser.tel
          ? _c("div", { staticClass: "card info_card" }, [
              _c(
                "div",
                { staticClass: "card title" },
                [
                  _c("auditState", {
                    attrs: {
                      "audit-type": 0,
                      "show-type": "inline",
                      state: _vm.nowUser.register_status,
                    },
                  }),
                  _vm.projectConfig.list.is_identify === 1
                    ? _c("auditState", {
                        attrs: {
                          "audit-type": 1,
                          "show-type": "inline",
                          state: _vm.nowUser.identify_status,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "icon", on: { click: _vm.clearNowUser } },
                    [_c("i", { staticClass: "el-icon-close" })]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card user_info" },
                [
                  _c("userAudit", {
                    attrs: {
                      "now-user": _vm.nowUser,
                      project: _vm.projectConfig,
                      "show-audit": false,
                      "show-owner": true,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "card options" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "sendno/index"],
                        expression: "['admin', 'sendno/index']",
                      },
                    ],
                    staticClass: "btn",
                    on: { click: _vm.confirmSpace },
                  },
                  [_vm._v("确认派号")]
                ),
                _vm.hasPrint
                  ? _c(
                      "div",
                      {
                        staticClass: "btn ac_btn",
                        on: {
                          click: function ($event) {
                            return _vm.confirmSpace(true)
                          },
                        },
                      },
                      [_vm._v("派号并打印")]
                    )
                  : _vm._e(),
              ]),
            ])
          : _c("div", { staticClass: "card info_card" }, [
              _c("div", { staticClass: "card title" }, [_vm._v("查找客户")]),
              _c(
                "div",
                { staticClass: "card info" },
                [
                  _vm._m(1),
                  _c("div", { staticClass: "search" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchText,
                          expression: "searchText",
                        },
                      ],
                      attrs: {
                        type: "text",
                        placeholder: "全部姓名\\手机\\身份证号;",
                      },
                      domProps: { value: _vm.searchText },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.search($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.searchText = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "btn", on: { click: _vm.search } },
                      [_vm._v("查询")]
                    ),
                  ]),
                  _c("id-card-drive", { ref: "cardDrive" }),
                ],
                1
              ),
              _c("div", { staticClass: "card options" }, [
                _c(
                  "div",
                  { staticClass: "btn", on: { click: _vm.showSpaceDialog } },
                  [_vm._v("占空位")]
                ),
              ]),
            ]),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            width: "576px",
            visible: _vm.dialog.show,
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "show", $event)
            },
          },
        },
        [
          _vm.dialog.type == 2
            ? [
                _c("div", { staticClass: "header" }, [_vm._v("未找到客户")]),
                _c("div", { staticClass: "content" }, [
                  _c("img", {
                    staticClass: "empty_img",
                    attrs: { src: require("@/assets/image/icon-qs.png") },
                  }),
                ]),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn ac_btn",
                      on: { click: _vm.hideDialog },
                    },
                    [_vm._v("关闭")]
                  ),
                ]),
              ]
            : _vm.dialog.type == 1
            ? [
                _c("div", { staticClass: "header" }, [_vm._v("找到以下客户")]),
                _c(
                  "div",
                  { staticClass: "user_list" },
                  _vm._l(_vm.userList, function (item, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        class: ["item", { ac_item: i == _vm.selectUserIndex }],
                        on: {
                          click: function ($event) {
                            return _vm.checkUser(i)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: {
                            src: require("@/assets/image/avatar_icon.png"),
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "box" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _vm._v(_vm._s(item.name || "未知客户")),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _vm._v("手机号：" + _vm._s(item.tel)),
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _vm._v("身份证号：" + _vm._s(item.value)),
                            ]),
                            item.owner
                              ? _vm._l(item.owner, function (o, idx) {
                                  return _c(
                                    "div",
                                    { key: idx, staticClass: "row" },
                                    [
                                      _vm._v(
                                        " 产权人" +
                                          _vm._s(idx + 1) +
                                          ": " +
                                          _vm._s(o.real_name) +
                                          " "
                                      ),
                                      o.card
                                        ? _c("span", [
                                            _vm._v("(" + _vm._s(o.card) + ")"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src: require("../../../assets/image/checked-icon-1.png"),
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.hideDialog } },
                    [_vm._v("关闭")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "btn ac_btn",
                      on: { click: _vm.userQualifications },
                    },
                    [_vm._v("确认")]
                  ),
                ]),
              ]
            : _vm.dialog.type == 3
            ? [
                _c("div", { staticClass: "header" }, [_vm._v("占号")]),
                _c("div", { staticClass: "select_num" }, [
                  _vm._v(_vm._s(_vm.nowSelectNum)),
                ]),
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "输入占号备注（非必填）",
                    maxlength: "100",
                    "show-word-limit": "",
                    autosize: true,
                  },
                  model: {
                    value: _vm.remark,
                    callback: function ($$v) {
                      _vm.remark = $$v
                    },
                    expression: "remark",
                  },
                }),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.hideDialog } },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "div",
                    { staticClass: "btn ac_btn", on: { click: _vm.spaceNum } },
                    [_vm._v("确认")]
                  ),
                ]),
              ]
            : _vm.dialog.type == 4
            ? [
                _c("div", { staticClass: "header" }, [_vm._v("占号成功")]),
                _c("div", { staticClass: "empty" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/image/choose-room-success.png"),
                    },
                  }),
                  _c("p", [_vm._v(_vm._s(_vm.dialog.tips))]),
                ]),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.dialogOption } },
                    [_vm._v("关闭")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "btn ac_btn",
                      on: {
                        click: function ($event) {
                          return _vm.dialogOption(true)
                        },
                      },
                    },
                    [_vm._v("查看详情")]
                  ),
                ]),
              ]
            : _vm.dialog.type == 5
            ? [
                _c("div", { staticClass: "header" }, [_vm._v("占号失败")]),
                _c("div", { staticClass: "empty" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/image/assistHouse-notSale-img.png"),
                    },
                  }),
                  _vm.dialog.tips
                    ? _c("p", [_vm._v(_vm._s(_vm.dialog.tips))])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn ac_btn",
                      on: {
                        click: function ($event) {
                          return _vm.dialogOption(true)
                        },
                      },
                    },
                    [_vm._v("查看详情")]
                  ),
                ]),
              ]
            : _vm.dialog.type == 6
            ? [
                _c("div", { staticClass: "header" }, [_vm._v("派号确认")]),
                _c("div", { staticClass: "bind_msg" }, [
                  _vm._v(
                    " 确认派号：" +
                      _vm._s(_vm.nowSelectNum) +
                      " " +
                      _vm._s(_vm.nowUser.name || "未知客户") +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.hideDialog } },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "btn ac_btn",
                      on: { click: _vm.bindNumToUser },
                    },
                    [_vm._v("确认")]
                  ),
                ]),
              ]
            : _vm.dialog.type == 7
            ? [
                _c("div", { staticClass: "header" }, [_vm._v("派号失败")]),
                _c("div", { staticClass: "empty" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/image/choose-room-fail.png"),
                    },
                  }),
                  _vm.dialog.tips
                    ? _c("p", [_vm._v(_vm._s(_vm.dialog.tips))])
                    : _vm._e(),
                ]),
                _vm.dialog.code == 1
                  ? _c("div", { staticClass: "footer" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.dialogOption(true)
                            },
                          },
                        },
                        [_vm._v("查看详情")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "btn ac_btn",
                          on: { click: _vm.againBindNumToUser },
                        },
                        [_vm._v("换号重派")]
                      ),
                    ])
                  : _vm._e(),
                _vm.dialog.code == 2
                  ? _c("div", { staticClass: "footer" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.dialogOption(true)
                            },
                          },
                        },
                        [_vm._v("查看详情")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "btn ac_btn",
                          on: { click: _vm.dialogOption },
                        },
                        [_vm._v("关闭")]
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.dialog.type == 8
            ? [
                _c("div", { staticClass: "header" }, [
                  _vm._v("未达到派号资格"),
                ]),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("userAudit", {
                      attrs: {
                        "now-user": _vm.userList[_vm.selectUserIndex],
                        project: _vm.projectConfig,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn ac_btn",
                      on: { click: _vm.hideDialog },
                    },
                    [_vm._v("关闭")]
                  ),
                ]),
              ]
            : _vm.dialog.type == 9
            ? [
                _c("div", { staticClass: "header" }, [_vm._v("退号确认")]),
                _c("div", { staticClass: "bind_msg" }, [
                  _vm._v("此操作不可恢复，确认退号？"),
                ]),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.hideDialog } },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "div",
                    { staticClass: "btn ac_btn", on: { click: _vm.returnNum } },
                    [_vm._v("确认")]
                  ),
                ]),
              ]
            : _vm.dialog.type == 10
            ? [
                _c("div", { staticClass: "header" }, [_vm._v("作废确认")]),
                _c("div", { staticClass: "bind_msg" }, [
                  _vm._v("此操作不可恢复，确认作废？"),
                ]),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.hideDialog } },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "btn ac_btn",
                      on: { click: _vm.returnSussess },
                    },
                    [_vm._v("确认")]
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            direction: _vm.drawer.direction,
            visible: _vm.drawer.show,
            "before-close": _vm.hideDrawer,
            "with-header": false,
            size: "570px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawer, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "detail" }, [
            _vm.numDetail.user_id
              ? _c("div", { staticClass: "top_row" }, [
                  _c("span", [_vm._v("已派号")]),
                  _c("div", { staticClass: "flexs" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "sendno/index"],
                            expression: "['admin', 'sendno/index']",
                          },
                        ],
                        staticClass: "btn",
                        on: {
                          click: function ($event) {
                            return _vm.showDialog(10)
                          },
                        },
                      },
                      [_vm._v("作废")]
                    ),
                    _c(
                      "div",
                      { staticClass: "btn", on: { click: _vm.returnConfirm } },
                      [_vm._v("退号")]
                    ),
                  ]),
                ])
              : _c("div", { staticClass: "top_row" }, [
                  _c("span", [_vm._v("已占号")]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "sendno/index"],
                          expression: "['admin', 'sendno/index']",
                        },
                      ],
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.showDialog(10)
                        },
                      },
                    },
                    [_vm._v("作废")]
                  ),
                ]),
            _c("div", { staticClass: "content" }, [
              _vm.numDetail.user_id
                ? _c("div", { staticClass: "user" }, [
                    _c("img", {
                      staticClass: "avatar",
                      attrs: { src: require("@/assets/image/avatar_icon.png") },
                    }),
                    _c("div", { staticClass: "box" }, [
                      _c("div", { staticClass: "row" }, [
                        _vm._v(
                          " " + _vm._s(_vm.numDetail.name || "未知客户") + " "
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _vm._v("手机号：" + _vm._s(_vm.numDetail.tel)),
                      ]),
                      _vm.numDetail.code
                        ? _c("div", { staticClass: "row" }, [
                            _vm._v("身份证号：" + _vm._s(_vm.numDetail.code)),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "audit" },
                      [
                        _c("auditState", {
                          attrs: {
                            "audit-type": 0,
                            state: _vm.numDetail.audit,
                          },
                        }),
                        _vm.projectConfig.list.is_identify
                          ? _c("auditState", {
                              attrs: {
                                "audit-type": 1,
                                state: _vm.numDetail.identify_audit,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { class: ["nums", { ac_num: !_vm.numDetail.user_id }] },
                [_vm._v(" " + _vm._s(_vm.numDetail.num_code) + " ")]
              ),
              _c("div", { staticClass: "info" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.numDetail.user_id ? "派号时间：" : "占号时间："
                    ) +
                    " " +
                    _vm._s(
                      _vm._f("dateFormat")(
                        _vm.numDetail.created_at * 1000,
                        "YYYY-MM-DD HH:mm"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "info" }, [
                _vm._v("操作人：" + _vm._s(_vm.numDetail.admin_name)),
              ]),
              !_vm.numDetail.user_id
                ? _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("备注：")]),
                    _c("div", { staticClass: "val" }, [
                      _vm._v(_vm._s(_vm.numDetail.node || "无")),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "options" },
              [
                _vm.numDetail.user_id
                  ? [
                      _vm.hasPrint
                        ? _c(
                            "div",
                            {
                              staticClass: "btn ac_btn",
                              on: { click: _vm.printReceipt },
                            },
                            [_vm._v("打印")]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          class: ["btn", { ac_btn: !_vm.hasPrint }],
                          on: { click: _vm.hideDrawer },
                        },
                        [_vm._v("关闭")]
                      ),
                    ]
                  : [
                      _c(
                        "div",
                        {
                          staticClass: "btn ac_btn",
                          on: { click: _vm.returnNum },
                        },
                        [_vm._v("释放")]
                      ),
                      _c(
                        "div",
                        { staticClass: "btn", on: { click: _vm.hideDrawer } },
                        [_vm._v("关闭")]
                      ),
                    ],
              ],
              2
            ),
          ]),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            direction: _vm.drawer.direction,
            visible: _vm.delShowDialog,
            "before-close": _vm.delHideDrawer,
            "with-header": false,
            size: "570px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.delShowDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "detail" }, [
            _vm.numDetail.user_id
              ? _c("div", { staticClass: "top_row" }, [
                  _c("span", [_vm._v("已作废")]),
                ])
              : _c("div", { staticClass: "top_row" }, [
                  _c("span", [_vm._v("已占号")]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "sendno/bind"],
                          expression: "['admin', 'sendno/bind']",
                        },
                      ],
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.showDialog(10)
                        },
                      },
                    },
                    [_vm._v("作废")]
                  ),
                ]),
            _c("div", { staticClass: "content" }, [
              _vm.numDetail.user_id
                ? _c("div", { staticClass: "user" }, [
                    _c("img", {
                      staticClass: "avatar",
                      attrs: { src: require("@/assets/image/avatar_icon.png") },
                    }),
                    _c("div", { staticClass: "box" }, [
                      _c("div", { staticClass: "row" }, [
                        _vm._v(
                          " " + _vm._s(_vm.numDetail.name || "未知客户") + " "
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _vm._v("手机号：" + _vm._s(_vm.numDetail.tel)),
                      ]),
                      _vm.numDetail.code
                        ? _c("div", { staticClass: "row" }, [
                            _vm._v("身份证号：" + _vm._s(_vm.numDetail.code)),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "audit" },
                      [
                        _c("auditState", {
                          attrs: {
                            "audit-type": 0,
                            state: _vm.numDetail.audit,
                          },
                        }),
                        _vm.projectConfig.list.is_identify
                          ? _c("auditState", {
                              attrs: {
                                "audit-type": 1,
                                state: _vm.numDetail.identify_audit,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { class: ["num", { ac_num: !_vm.numDetail.user_id }] },
                [_vm._v(" " + _vm._s(_vm.numDetail.num_code) + " ")]
              ),
              _c("div", { staticClass: "info" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.numDetail.user_id ? "派号时间：" : "占号时间："
                    ) +
                    " " +
                    _vm._s(
                      _vm._f("dateFormat")(
                        _vm.numDetail.created_at * 1000,
                        "YYYY-MM-DD HH:mm"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "info" }, [
                _vm._v("操作人：" + _vm._s(_vm.numDetail.admin_name)),
              ]),
              !_vm.numDetail.user_id
                ? _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("备注：")]),
                    _c("div", { staticClass: "val" }, [
                      _vm._v(_vm._s(_vm.numDetail.node || "无")),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "options" },
              [
                [
                  _c(
                    "div",
                    { staticClass: "btn ac_btn", on: { click: _vm.returnNum } },
                    [_vm._v("释放")]
                  ),
                  _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.delHideDrawer } },
                    [_vm._v("关闭")]
                  ),
                ],
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [_c("span", [_vm._v("派号表")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("picture", [
      _c("source", {
        attrs: {
          media: "(max-height: 600px)",
          srcset: require("../../../assets/image/IDCard-img-no.png"),
        },
      }),
      _c("source", {
        attrs: {
          media: "(max-height: 700px)",
          srcset: require("../../../assets/image/IDCard-img@1.png"),
        },
      }),
      _c("source", {
        attrs: {
          media: "(max-height: 850px)",
          srcset: require("../../../assets/image/IDCard-img@2.png"),
        },
      }),
      _c("source", {
        attrs: {
          media: "(max-height: 1024px)",
          srcset: require("../../../assets/image/IDCard-img@3.png"),
        },
      }),
      _c("img", {
        attrs: { src: require("../../../assets/image/IDCard-img@3.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }