var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-wrapper" },
    [
      _vm.eventList.length
        ? _c(
            "el-dropdown",
            { attrs: { trigger: "click" }, on: { command: _vm.selectHandle } },
            [
              _c("el-button", { attrs: { type: "primary", round: "" } }, [
                _vm._v(" " + _vm._s(_vm.selectEvent.name)),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.eventList, function (item) {
                  return _c(
                    "el-dropdown-item",
                    { key: item.id, attrs: { command: item } },
                    [_c("span", [_vm._v(_vm._s(item.name))])]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }