var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container" },
    [
      _c("div", { ref: "top", staticClass: "top-fixed" }, [
        _c(
          "div",
          { staticClass: "title-top-box align-items" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(" 房源详情 "),
              _c(
                "span",
                {
                  staticStyle: {
                    color: "black",
                    "font-size": "16px",
                    "font-weight": "500",
                    "margin-left": "20px",
                  },
                },
                [_vm._v(_vm._s(_vm.room.room))]
              ),
            ]),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "room/edit"],
                    expression: "['admin', 'room/edit']",
                  },
                ],
                staticClass: "greens-btn",
                attrs: { size: "small" },
                on: { click: _vm.handleEdit },
              },
              [_vm._v("修改")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "基本信息", name: "info" } }, [
            _c("div", { staticClass: "detail-box mT10" }, [
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("div", { staticClass: "title-h2" }, [
                    _vm._v(" 住宅信息 "),
                    _c("span", { staticClass: "orange" }, [
                      _vm._v(_vm._s(_vm.room.room)),
                    ]),
                  ]),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "detail-item" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v("是否非售："),
                              ]),
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  _vm._s(_vm.room.status === -1 ? "是" : "否")
                                ),
                              ]),
                            ]),
                          ]),
                          _vm.room.status === -1
                            ? _c("el-col", { attrs: { span: 24 } }, [
                                _c("div", { staticClass: "detail-item" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v("非售原因："),
                                  ]),
                                  _c("div", {
                                    staticClass: "text",
                                    domProps: {
                                      textContent: _vm._s(_vm.room.not_desc),
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "detail-item" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v("户型："),
                              ]),
                              _c("div", {
                                staticClass: "text",
                                domProps: {
                                  textContent: _vm._s(_vm.room.cate_name),
                                },
                              }),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "detail-item" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v("建筑面积："),
                              ]),
                              _c("div", {
                                staticClass: "text",
                                domProps: {
                                  textContent: _vm._s(_vm.room.build_area),
                                },
                              }),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "detail-item" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v("套内面积："),
                              ]),
                              _c("div", {
                                staticClass: "text",
                                domProps: {
                                  textContent: _vm._s(_vm.room.room_area),
                                },
                              }),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticStyle: { opacity: "0" } }, [
                          _vm._v("."),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c(
                            "div",
                            {
                              staticClass: "name",
                              staticStyle: { "flex-shrink": "0" },
                            },
                            [_vm._v("详情图片：")]
                          ),
                          _c(
                            "div",
                            { staticClass: "text flex" },
                            _vm._l(
                              _vm.images.split(","),
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    item
                                      ? _c("el-image", {
                                          staticClass: "imageBox",
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px",
                                          },
                                          attrs: { src: _vm.imgUrl + item },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              { staticClass: "detail-item text-item" },
                              [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v("总价："),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text",
                                    staticStyle: { flex: "1" },
                                  },
                                  [
                                    _vm.projectList.room_info_price_type === 1
                                      ? [
                                          _c("span", [
                                            _vm._v(
                                              "现价: " +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room.old_total_price,
                                                    _vm.preStatus,
                                                    _vm.zong
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      : _vm.projectList.room_info_price_type ===
                                        2
                                      ? [
                                          _c("span", [
                                            _vm._v(
                                              "原价: " +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room.old_total_price,
                                                    _vm.preStatus,
                                                    _vm.zong
                                                  )
                                                )
                                            ),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "优惠价: " +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room
                                                      .discount_total_price,
                                                    _vm.preStatus,
                                                    _vm.zong
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      : [
                                          _c("span", [
                                            _vm._v(
                                              "标准价: " +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room.old_total_price,
                                                    _vm.preStatus,
                                                    _vm.zong
                                                  )
                                                )
                                            ),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "一次性价:" +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room
                                                      .one_off_total_price,
                                                    _vm.preStatus,
                                                    _vm.zong
                                                  )
                                                )
                                            ),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "按揭价: " +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room
                                                      .pin_an_jie_total_price,
                                                    _vm.preStatus,
                                                    _vm.zong
                                                  )
                                                )
                                            ),
                                          ]),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              { staticClass: "detail-item text-item" },
                              [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v("单价："),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text",
                                    staticStyle: { flex: "1" },
                                  },
                                  [
                                    _vm.projectList.room_info_price_type === 1
                                      ? [
                                          _c("span", [
                                            _vm._v(
                                              "现价: " +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room.old_unit_price,
                                                    _vm.preStatus,
                                                    _vm.dan
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      : _vm.projectList.room_info_price_type ===
                                        2
                                      ? [
                                          _c("span", [
                                            _vm._v(
                                              "原价: " +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room.old_unit_price,
                                                    _vm.preStatus,
                                                    _vm.dan
                                                  )
                                                )
                                            ),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "优惠价: " +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room.discount_price,
                                                    _vm.preStatus,
                                                    _vm.dan
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      : [
                                          _c("span", [
                                            _vm._v(
                                              "标准价: " +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room.old_unit_price,
                                                    _vm.preStatus,
                                                    _vm.dan
                                                  )
                                                )
                                            ),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "一次性价:" +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room.one_off_unit_price,
                                                    _vm.preStatus,
                                                    _vm.dan
                                                  )
                                                )
                                            ),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "按揭价: " +
                                                _vm._s(
                                                  _vm.moneyData(
                                                    _vm.room
                                                      .pin_an_jie_unit_price,
                                                    _vm.preStatus,
                                                    _vm.dan
                                                  )
                                                )
                                            ),
                                          ]),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 8 } }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _vm.matching_order === 2
                            ? _c("el-col", { attrs: { span: 24 } }, [
                                _c("div", { staticClass: "detail-item" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v("车位最小认购数量："),
                                  ]),
                                  _c("div", {
                                    staticClass: "text",
                                    domProps: {
                                      textContent: _vm._s(_vm.room.buy_min_car),
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.matching_order === 2
                            ? _c("el-col", { attrs: { span: 24 } }, [
                                _c("div", { staticClass: "detail-item" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v("车位最大认购数量："),
                                  ]),
                                  _c("div", {
                                    staticClass: "text",
                                    domProps: {
                                      textContent: _vm._s(_vm.room.buy_max_car),
                                    },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "detail-item" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v("分类："),
                              ]),
                              _c("div", {
                                staticClass: "text",
                                domProps: {
                                  textContent: _vm._s(_vm.room.room_tag),
                                },
                              }),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "detail-item" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.projectList.pin_fu_zen +
                                      (_vm.projectList.pin_fu_zen_product ||
                                        "信息")
                                  ) + "："
                                ),
                              ]),
                              _c("div", {
                                staticClass: "text",
                                domProps: {
                                  textContent: _vm._s(_vm.room.gift_desc),
                                },
                              }),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "detail-item" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.projectList.pin_fu_zen +
                                      (_vm.projectList.pin_fu_zen_price ||
                                        "价值")
                                  ) + "："
                                ),
                              ]),
                              _c("div", {
                                staticClass: "text",
                                domProps: {
                                  textContent: _vm._s(_vm.room.gift_price),
                                },
                              }),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "detail-item" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v("其他系统FID："),
                              ]),
                              _c("div", {
                                staticClass: "text",
                                domProps: {
                                  textContent: _vm._s(_vm.room.other_f_id),
                                },
                              }),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "detail-item" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v("描述："),
                              ]),
                              _c("div", {
                                staticClass: "text",
                                domProps: {
                                  textContent: _vm._s(_vm.room.desc),
                                },
                              }),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "detail-item" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v("其他信息："),
                              ]),
                              _c("div", {
                                staticClass: "text",
                                domProps: {
                                  textContent: _vm._s(_vm.room.other),
                                },
                              }),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 16 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.roles.includes("admin") || _vm.roles.includes("room/heat")
            ? _c(
                "el-tab-pane",
                { attrs: { label: "关注情况", name: "attention" } },
                [
                  _c("attention", {
                    attrs: {
                      heat: _vm.heat,
                      list: _vm.list,
                      room: _vm.room,
                      "table-height": _vm.tableHeight,
                      type: "house",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.roles.includes("admin") || _vm.roles.includes("room/eventdata")
            ? _c(
                "el-tab-pane",
                { attrs: { label: "活动数据", name: "activity" } },
                [
                  _vm.isData
                    ? _c("dataInfo", { attrs: { room: _vm.room } })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.roles.includes("admin") || _vm.roles.includes("room/loglist")
            ? _c(
                "el-tab-pane",
                { attrs: { label: "日志", name: "log" } },
                [
                  _c("logView", {
                    ref: "logList",
                    attrs: { log: _vm.log },
                    on: { handleSearchClick: _vm.handleSearchClick },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }