var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container" },
    [
      _c("div", { ref: "top", staticClass: "top-fixed" }, [
        _c(
          "div",
          { staticClass: "title-top-box align-items" },
          [
            _c("div", { staticClass: "title flex align-items" }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.customSetting.villa_setting.house_res) + "信息详情"
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    color: "black",
                    "font-size": "16px",
                    "font-weight": "500",
                    "margin-left": "20px",
                  },
                },
                [_vm._v(_vm._s(_vm.room.room))]
              ),
            ]),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "car/edit"],
                    expression: "['admin', 'car/edit']",
                  },
                ],
                staticClass: "greens-btn",
                attrs: { size: "small" },
                on: { click: _vm.handleEdit },
              },
              [_vm._v("修改")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "基本信息", name: "info" } }),
          _c("el-tab-pane", {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: ["admin", "roomvilla/heat"],
                expression: "['admin', 'roomvilla/heat']",
              },
            ],
            attrs: { label: "关注情况", name: "attention" },
          }),
          _c("el-tab-pane", {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: ["admin", "roomvilla/eventdata"],
                expression: "['admin', 'roomvilla/eventdata']",
              },
            ],
            attrs: { label: "活动数据", name: "activity" },
          }),
          _c("el-tab-pane", {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: ["admin", "roomvilla/loglist"],
                expression: "['admin', 'roomvilla/loglist']",
              },
            ],
            attrs: { label: "日志", name: "log" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "info",
              expression: "activeName === 'info'",
            },
          ],
          staticClass: "detail-box mT10",
        },
        [
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("div", { staticClass: "title-h2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.customSetting.villa_setting.house_res) +
                    "信息 "
                ),
                _c("span", { staticClass: "orange" }, [
                  _vm._v(_vm._s(_vm.room.room)),
                ]),
              ]),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("是否非售："),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(_vm.room.status === -1 ? "是" : "否")
                            ),
                          ]),
                        ]),
                      ]),
                      _vm.room.status === -1
                        ? _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "detail-item" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v("非售原因："),
                              ]),
                              _c("div", {
                                staticClass: "text",
                                domProps: {
                                  textContent: _vm._s(_vm.room.not_desc),
                                },
                              }),
                            ]),
                          ])
                        : _vm._e(),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              _vm._s(
                                _vm.customSetting.villa_setting.house_type
                              ) + "："
                            ),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: {
                              textContent: _vm._s(_vm.room.cate_name),
                            },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("建筑面积："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: {
                              textContent: _vm._s(_vm.room.build_area),
                            },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("套内面积："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: {
                              textContent: _vm._s(_vm.room.room_area),
                            },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticStyle: { opacity: "0" } }, [_vm._v(".")]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "detail-item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "name",
                          staticStyle: { "flex-shrink": "0" },
                        },
                        [_vm._v("详情图片：")]
                      ),
                      _c(
                        "div",
                        { staticClass: "text flex" },
                        _vm._l(_vm.images.split(","), function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              item
                                ? _c("el-image", {
                                    staticClass: "imageBox",
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: { src: _vm.imgUrl + item },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item text-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("总价："),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text", staticStyle: { flex: "1" } },
                            [
                              _vm.projectList.room_info_price_type === 1
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        "现价: " +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.old_total_price,
                                              _vm.preStatus,
                                              _vm.dan
                                            )
                                          )
                                      ),
                                    ]),
                                  ]
                                : _vm.projectList.room_info_price_type === 2
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        "原价: " +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.old_total_price,
                                              _vm.preStatus,
                                              _vm.dan
                                            )
                                          )
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "优惠价: " +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.discount_total_price,
                                              _vm.preStatus,
                                              _vm.dan
                                            )
                                          )
                                      ),
                                    ]),
                                  ]
                                : [
                                    _c("span", [
                                      _vm._v(
                                        "标准价: " +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.old_total_price,
                                              _vm.preStatus,
                                              _vm.dan
                                            )
                                          )
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "一次性价:" +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.one_off_total_price,
                                              _vm.preStatus,
                                              _vm.dan
                                            )
                                          )
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "按揭价: " +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.pin_an_jie_total_price,
                                              _vm.preStatus,
                                              _vm.dan
                                            )
                                          )
                                      ),
                                    ]),
                                  ],
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item text-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("单价："),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text", staticStyle: { flex: "1" } },
                            [
                              _vm.projectList.room_info_price_type === 1
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        "现价: " +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.old_unit_price,
                                              _vm.preStatus,
                                              _vm.zong
                                            )
                                          )
                                      ),
                                    ]),
                                  ]
                                : _vm.projectList.room_info_price_type === 2
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        "原价: " +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.old_unit_price,
                                              _vm.preStatus,
                                              _vm.zong
                                            )
                                          )
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "优惠价: " +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.discount_price,
                                              _vm.preStatus,
                                              _vm.zong
                                            )
                                          )
                                      ),
                                    ]),
                                  ]
                                : [
                                    _c("span", [
                                      _vm._v(
                                        "标准价: " +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.old_unit_price,
                                              _vm.preStatus,
                                              _vm.zong
                                            )
                                          )
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "一次性价:" +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.one_off_unit_price,
                                              _vm.preStatus,
                                              _vm.zong
                                            )
                                          )
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        "按揭价: " +
                                          _vm._s(
                                            _vm.moneyData(
                                              _vm.room.pin_an_jie_unit_price,
                                              _vm.preStatus,
                                              _vm.zong
                                            )
                                          )
                                      ),
                                    ]),
                                  ],
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 8 } }),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("分类："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: {
                              textContent: _vm._s(_vm.room.room_tag),
                            },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              _vm._s(
                                _vm.projectList.pin_fu_zen +
                                  (_vm.projectList.pin_fu_zen_product || "信息")
                              ) + "："
                            ),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: {
                              textContent: _vm._s(_vm.room.gift_desc),
                            },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              _vm._s(
                                _vm.projectList.pin_fu_zen +
                                  (_vm.projectList.pin_fu_zen_price || "价值")
                              ) + "："
                            ),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: {
                              textContent: _vm._s(_vm.room.gift_price),
                            },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("其他系统FID："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: {
                              textContent: _vm._s(_vm.room.other_f_id),
                            },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("描述："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: { textContent: _vm._s(_vm.room.desc) },
                          }),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("其他信息："),
                          ]),
                          _c("div", {
                            staticClass: "text",
                            domProps: { textContent: _vm._s(_vm.room.other) },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("el-col"),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("attention", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeName === "attention",
            expression: "activeName === 'attention'",
          },
        ],
        attrs: {
          heat: _vm.heat,
          list: _vm.list,
          room: _vm.room,
          "table-height": _vm.tableHeight,
        },
      }),
      _vm.isData
        ? _c("dataInfo", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName === "activity",
                expression: "activeName === 'activity'",
              },
            ],
            attrs: { room: _vm.room },
          })
        : _vm._e(),
      _c("logView", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeName === "log",
            expression: "activeName === 'log'",
          },
        ],
        ref: "logList",
        attrs: { log: _vm.log },
        on: { handleSearchClick: _vm.getLogList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }