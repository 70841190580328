//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import stylePreview from "@/views/opening/components/stylePreview.vue";
export default {
  name: 'rengoSuccessPopup',
  components: {
    stylePreview: stylePreview
  },
  props: {},
  data: function data() {
    return {
      quotaSwicth: 1
    };
  },
  methods: {
    swicthquota: function swicthquota(val) {
      this.quotaSwicth = val;
    }
  }
};