var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.list.length > 0
        ? _vm._l(_vm.list, function (item, inx) {
            return _c(
              "div",
              { key: inx },
              [
                _c(
                  "div",
                  {
                    staticClass: "title-box flex space-between",
                    class: [, inx !== 0 ? "mT50" : "mT30"],
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "title-item",
                        staticStyle: { display: "flex" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-right": "10px",
                              display: "flex",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.seteventName(item, inx)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: item.is_show
                                ? "选房端不可见"
                                : "选房端可见",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "redEye",
                                class: item.is_show ? "eyeBg" : "eyeOpenBg",
                                on: {
                                  click: function ($event) {
                                    return _vm.handelIsSo(item.id, inx)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "class-name": "color-hui",
                                    "icon-class": item.is_show
                                      ? "eye"
                                      : "eye-open",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        item.event_status === 1
                          ? _c("span", { staticClass: "but1" }, [
                              _vm._v("进行中"),
                            ])
                          : item.event_status === 2
                          ? _c("span", { staticClass: "but2" }, [
                              _vm._v("已结束"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "type-item" },
                      [
                        item.event_status === 0
                          ? [
                              _vm.type === "add" && _vm.index !== 0
                                ? _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: ["admin", "event/preserve"],
                                          expression:
                                            "['admin', 'event/preserve']",
                                        },
                                      ],
                                      staticClass: "red",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handelListDel(item.id, inx)
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "class-name": "color-hui",
                                          "icon-class": "delete",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "el-row",
                  { staticClass: "list-bg" },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: { "label-width": _vm.isPC ? "160px" : "100px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "mT15",
                            attrs: { label: "开盘时间：" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "date align-items",
                                class: _vm.isPC ? "flex" : "",
                              },
                              [
                                _vm.type === "add"
                                  ? [
                                      _c("el-date-picker", {
                                        attrs: {
                                          format: "yyyy-MM-dd HH:mm:ss",
                                          "value-format": "timestamp",
                                          size: "small",
                                          type: "datetime",
                                          placeholder: "选择日期",
                                        },
                                        on: {
                                          change: function (val) {
                                            _vm.getstartTime(item, val)
                                          },
                                        },
                                        model: {
                                          value: item.start_time,
                                          callback: function ($$v) {
                                            _vm.$set(item, "start_time", $$v)
                                          },
                                          expression: "item.start_time",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "addtime flex ju-center align-items",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [_vm._v("加")]
                                          ),
                                          _c("el-input", {
                                            staticStyle: { width: "100px" },
                                            attrs: { type: "number" },
                                            on: {
                                              input: function (val) {
                                                _vm.addMinuteFunc(val, inx)
                                              },
                                            },
                                            model: {
                                              value: item.addMinute,
                                              callback: function ($$v) {
                                                _vm.$set(item, "addMinute", $$v)
                                              },
                                              expression: "item.addMinute",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "15px",
                                                "margin-left": "10px",
                                              },
                                            },
                                            [_vm._v("分钟至")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-date-picker", {
                                        attrs: {
                                          format: "yyyy-MM-dd HH:mm:ss",
                                          "value-format": "timestamp",
                                          size: "small",
                                          type: "datetime",
                                          placeholder: "选择日期",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleEndTime(inx)
                                          },
                                        },
                                        model: {
                                          value: item.end_time,
                                          callback: function ($$v) {
                                            _vm.$set(item, "end_time", $$v)
                                          },
                                          expression: "item.end_time",
                                        },
                                      }),
                                    ]
                                  : [
                                      _c("div", { staticClass: "text6" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                item.start_time
                                              )
                                            ) +
                                            " 至 " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                item.end_time
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                _vm.type === "add"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "orange",
                                        staticStyle: { "min-width": "150px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.toHHmmss(
                                              item.end_time,
                                              item.start_time
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _c("span", { staticClass: "text6" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.toHHmmss(
                                            item.end_time,
                                            item.start_time
                                          )
                                        )
                                      ),
                                    ]),
                              ],
                              2
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.list.length - 1 !== inx
                  ? _c("div", { staticClass: "dashed" })
                  : _vm._e(),
              ],
              1
            )
          })
        : _c(
            "div",
            { staticClass: "no-list" },
            [
              _c("el-image", {
                staticClass: "image-style",
                attrs: { src: require("../../../assets/image/icon-qs.png") },
              }),
              _c("span", [_vm._v("还未添加活动~")]),
            ],
            1
          ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改活动名称",
            visible: _vm.setName,
            width: _vm.isPC ? "25%" : "95%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.setName = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "setName" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "原名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: "",
                      size: "small",
                      maxlength: "10",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.usedName,
                      callback: function ($$v) {
                        _vm.usedName = $$v
                      },
                      expression: "usedName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "新名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "10",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.newName,
                      callback: function ($$v) {
                        _vm.newName = $$v
                      },
                      expression: "newName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.setName = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleSaveName },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }