var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-table-wrap" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "margin-bottom": "20px",
          },
        },
        [
          _c("div"),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "prints/varadd"],
                      expression: "['admin', 'prints/varadd']",
                    },
                  ],
                  staticClass: "greens-btn",
                  on: {
                    click: function ($event) {
                      return _vm.showLoading("add")
                    },
                  },
                },
                [_vm._v(" 新增变量 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-table",
        {
          attrs: {
            border: "",
            data: _vm.tableData,
            "header-cell-style": { background: "#fafafa" },
            "highlight-current-row": "",
            size: "medium",
          },
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: { width: "160", label: "变量名 ", prop: "new_key" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "pointer",
                        on: {
                          click: function ($event) {
                            return _vm.addSymbolCopy(row.new_key)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.addSymbol(row.new_key)) + " "),
                        _c("svg-icon", {
                          attrs: {
                            "class-name": "greens copy-icon",
                            "icon-class": "copy",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "160", label: "大写", prop: "is_cn" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "pointer",
                        on: {
                          click: function ($event) {
                            return _vm.addSymbolCopy(row.new_key, true)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.is_cn === 1
                                ? _vm.addSymbol(row.new_key + "_str")
                                : ""
                            ) +
                            " "
                        ),
                        row.is_cn === 1
                          ? _c("svg-icon", {
                              attrs: {
                                "class-name": "greens copy-icon",
                                "icon-class": "copy",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "运算公式", prop: "date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.key_one) +
                          " " +
                          _vm._s(
                            _vm.showContent(scope.row.operator, "operator")
                          ) +
                          " " +
                          _vm._s(scope.row.key_two) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "小数位",
              prop: "date",
              width: "80",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.showContent(scope.row.decimal, "reserve")
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "含义", prop: "meaning" } }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              { attrs: { content: "编辑" } },
                              [
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "prints/varedit"],
                                      expression: "['admin', 'prints/varedit']",
                                    },
                                  ],
                                  staticClass: "greens",
                                  attrs: { "icon-class": "edit" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showLoading("edit", scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              { attrs: { content: "删除" } },
                              [
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "prints/vardel"],
                                      expression: "['admin', 'prints/vardel']",
                                    },
                                  ],
                                  staticClass: "greens",
                                  attrs: { "icon-class": "delete" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteData(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "10vh",
            title: _vm.loading.title,
            visible: _vm.loading.visible,
            width: _vm.loading.width,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.loading, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formVerify",
              attrs: {
                model: _vm.loading,
                rules: _vm.verify,
                "label-width": "120px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "变量名称", prop: "new_key" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "grid",
                        "grid-template-columns": "2fr 1fr",
                        gap: "1em",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "20",
                          "show-word-limit": "",
                          placeholder:
                            "英文字母、数字等字符的组合；不能包含${}[]",
                        },
                        model: {
                          value: _vm.loading.new_key,
                          callback: function ($$v) {
                            _vm.$set(_vm.loading, "new_key", $$v)
                          },
                          expression: "loading.new_key",
                        },
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.newKey,
                              expression: "newKey",
                            },
                          ],
                          staticClass: "pointer",
                          on: {
                            click: function ($event) {
                              return _vm.copy(_vm.newKey)
                            },
                          },
                        },
                        [
                          _vm._v(" 点击复制: "),
                          _c("span", { staticStyle: { color: "#22a271" } }, [
                            _vm._v(_vm._s(_vm.newKey)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "变量含义", prop: "meaning" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      maxlength: "50",
                      "show-word-limit": "",
                      placeholder: "描述变量名称含义或使用场景",
                    },
                    model: {
                      value: _vm.loading.meaning,
                      callback: function ($$v) {
                        _vm.$set(_vm.loading, "meaning", $$v)
                      },
                      expression: "loading.meaning",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "数值小数位" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.loading.decimal,
                        callback: function ($$v) {
                          _vm.$set(_vm.loading, "decimal", $$v)
                        },
                        expression: "loading.decimal",
                      },
                    },
                    _vm._l(_vm.reserve, function (re) {
                      return _c("el-radio", { attrs: { label: re.value } }, [
                        _vm._v(_vm._s(re.label)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "自动增加大写" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "grid",
                      "grid-template-columns": "1fr 2fr",
                      gap: "1em",
                      height: "100%",
                      "align-items": "center",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.loading.is_cn,
                          callback: function ($$v) {
                            _vm.$set(_vm.loading, "is_cn", $$v)
                          },
                          expression: "loading.is_cn",
                        },
                      },
                      _vm._l(_vm.cn, function (c) {
                        return _c("el-radio", { attrs: { label: c.value } }, [
                          _vm._v(_vm._s(c.radioLabel)),
                        ])
                      }),
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading.is_cn === 1 && _vm.toUP,
                            expression: "loading.is_cn === 1 && toUP",
                          },
                        ],
                        staticClass: "pointer",
                        on: {
                          click: function ($event) {
                            return _vm.copy(_vm.toUP)
                          },
                        },
                      },
                      [
                        _vm._v(" 点击复制: "),
                        _c("span", { staticStyle: { color: "#22a271" } }, [
                          _vm._v(_vm._s(_vm.toUP)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "计算公式", prop: "status" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "statusFormVerify",
                      attrs: { model: _vm.loading, rules: _vm.statusverify },
                    },
                    [
                      _c("el-form-item", { attrs: { prop: "key_one" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "grid",
                              "grid-template-columns": "1fr 3fr",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "12em" },
                                attrs: { placeholder: "类型" },
                                on: { change: _vm.countOneChange },
                                model: {
                                  value: _vm.loading.count_one_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loading, "count_one_type", $$v)
                                  },
                                  expression: "loading.count_one_type",
                                },
                              },
                              _vm._l(_vm.countType, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            !_vm.loading.count_one_type
                              ? [
                                  _c("el-autocomplete", {
                                    attrs: {
                                      "fetch-suggestions": _vm.querySearchAsync,
                                      placeholder:
                                        "快速选择系统常用变量、手动录入自定义变量 或 录入数字",
                                      "value-key": "show_name",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "show_name" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.adminParameter.list
                                                        .sign_up_type === 1
                                                        ? item.show_name
                                                        : item.name
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "meaming" },
                                                [
                                                  _vm._v(
                                                    "含义: " +
                                                      _vm._s(item.meaming)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4134018529
                                    ),
                                    model: {
                                      value: _vm.loading.key_one,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.loading, "key_one", $$v)
                                      },
                                      expression: "loading.key_one",
                                    },
                                  }),
                                ]
                              : _vm.loading.count_one_type === 1
                              ? [
                                  _c("el-input", {
                                    staticClass: "def-input-number",
                                    attrs: {
                                      placeholder: "录入需要参与计算的具体数值",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.loading.key_one,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.loading, "key_one", $$v)
                                      },
                                      expression: "loading.key_one",
                                    },
                                  }),
                                ]
                              : _vm.loading.count_one_type === 2
                              ? [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择日期" },
                                      model: {
                                        value: _vm.loading.key_one,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.loading, "key_one", $$v)
                                        },
                                        expression: "loading.key_one",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "订单日期",
                                          value: "order_date",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "当前日期",
                                          value: "current_date",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _c("el-form-item", { attrs: { prop: "operator" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "mT10 yunshuanfu",
                            staticStyle: {
                              display: "grid",
                              "grid-template-columns": "1fr 3fr",
                            },
                          },
                          [
                            _c("div", [_vm._v("运算符")]),
                            _c(
                              "el-radio-group",
                              {
                                staticStyle: { "line-height": "36px" },
                                on: { change: _vm.changeL },
                                model: {
                                  value: _vm.loading.operator,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loading, "operator", $$v)
                                  },
                                  expression: "loading.operator",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("+"),
                                ]),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("-"),
                                ]),
                                _vm.loading.count_one_type !== 2
                                  ? [
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "rrrr",
                                          attrs: { label: 2 },
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _c("el-radio", { attrs: { label: 3 } }, [
                                        _vm._v("/"),
                                      ]),
                                      _c("el-radio", { attrs: { label: 4 } }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                          },
                                          [
                                            _vm._v(" 首付 "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content:
                                                    "变量-取整(变量*(1-首付比例%)/10000)*10000",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-question",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c("el-radio", { attrs: { label: 5 } }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                          },
                                          [
                                            _vm._v(" 增值税 "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content:
                                                    "变量 - 变量/1.09，1.09为默认的值，可在下面数值中修改",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-question",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-form-item", { attrs: { prop: "key_two" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "mT10",
                            staticStyle: {
                              display: "grid",
                              "grid-template-columns": "1fr 3fr",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "12em" },
                                attrs: {
                                  disabled:
                                    _vm.loading.operator === 4 ||
                                    _vm.loading.operator === 5 ||
                                    _vm.loading.count_one_type == 2,
                                  placeholder: "类型",
                                },
                                on: { change: _vm.countTwoChange },
                                model: {
                                  value: _vm.loading.count_two_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loading, "count_two_type", $$v)
                                  },
                                  expression: "loading.count_two_type",
                                },
                              },
                              _vm._l(_vm.countType, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            !_vm.loading.count_two_type
                              ? [
                                  _c("el-autocomplete", {
                                    attrs: {
                                      "fetch-suggestions": _vm.querySearchAsync,
                                      placeholder:
                                        "快速选择系统常用变量、手动录入自定义变量 或 录入数字",
                                      "value-key": "show_name",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "show_name" },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "meaming" },
                                                [_vm._v(_vm._s(item.meaming))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3775326801
                                    ),
                                    model: {
                                      value: _vm.loading.key_two,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.loading, "key_two", $$v)
                                      },
                                      expression: "loading.key_two",
                                    },
                                  }),
                                ]
                              : _vm.loading.count_two_type === 1
                              ? [
                                  _c("el-input", {
                                    staticClass: "def-input-number",
                                    attrs: {
                                      placeholder: _vm.two_tips,
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.loading.key_two,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.loading, "key_two", $$v)
                                      },
                                      expression: "loading.key_two",
                                    },
                                  }),
                                ]
                              : _vm.loading.count_two_type === 2
                              ? [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择日期" },
                                      model: {
                                        value: _vm.loading.key_two,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.loading, "key_two", $$v)
                                        },
                                        expression: "loading.key_two",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "订单日期",
                                          value: "order_date",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "当前日期",
                                          value: "current_date ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitData } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }