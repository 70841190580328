var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "noBanner", staticClass: "screen-wrapper" },
    [
      _vm._m(0),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowBegin && _vm.eventInfo.start == 1,
              expression: "isShowBegin && eventInfo.start == 1",
            },
          ],
          staticClass: "begin-countdown",
        },
        [
          _c("img", {
            staticStyle: {
              width: "100vw",
              height: "100vh",
              position: "absolute",
              top: "0",
              "z-index": "-10",
            },
            attrs: { src: _vm.imgUrl + _vm.waitJson.imgSrc },
          }),
          _c("my-screen"),
          _c("div", { staticClass: "title-wrapper" }, [
            _c(
              "div",
              {
                staticClass: "active-title",
                style: {
                  "background-color":
                    "rgba(255,255,255," +
                    (_vm.waitJson.title.show ? _vm.waitJson.title.opacity : 0) +
                    ")",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.waitJson.title.show ? _vm.waitJson.title.text : ""
                    ) +
                    " "
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "content-wrapper" }, [
            _c(
              "div",
              {
                staticClass: "active-content",
                style: {
                  "background-color":
                    "rgba(255,255,255," +
                    (_vm.waitJson.wait.show ? _vm.waitJson.wait.opacity : 0) +
                    ")",
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.waitJson.wait.show,
                        expression: "waitJson.wait.show",
                      },
                    ],
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isShowWord,
                            expression: "!isShowWord",
                          },
                        ],
                        staticClass: "content-tip",
                      },
                      [_vm._v("距离开盘还有")]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isShowWord,
                            expression: "isShowWord",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.waitJson.wait.text))]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isShowWord,
                            expression: "!isShowWord",
                          },
                        ],
                      },
                      [
                        _c(_vm.countDown, {
                          tag: "component",
                          attrs: {
                            "event-id": _vm.event_id,
                            project_id: _vm.project_id,
                            "is-show-all": false,
                            "project-info": _vm.projectInfo,
                            "led-info": _vm.ledInfo,
                            pin_ren_gou: _vm.parameterList.pin_ren_gou,
                          },
                          on: {
                            currenTime: _vm.currenTime,
                            countDownBlink: _vm.countDownBlink,
                            countDownOver: _vm.countDownOver,
                            batchTime: _vm.batchTimeInfo,
                            nextbatchTime: _vm.nextbatchTimeInfo,
                            uptoDate: _vm.uptoDate,
                            nextuptoDate: _vm.nextuptoDate,
                            nextThreeBatch: _vm.nextThreeBatch,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      ((_vm.eventInfo.start == 3 &&
        (_vm.ledConfInfo.show_end == 1 ||
          (_vm.eventInfo.event_type !== 1 && _vm.ledConfInfo.show_end == 0))) ||
        _vm.isSaleOver) &&
      _vm.ledConfInfo.img_id &&
      !_vm.pendingQueue.length
        ? _c("setLedActivityEnd", {
            attrs: {
              "is-set": false,
              "saled-num": _vm.allDatas.priceCount,
              "saled-price": _vm.addCommas(_vm.allDatas.price),
              "power-by": _vm.defaultSettings.powered,
              saleType: _vm.isSaleOver ? 1 : 0,
              Id: _vm.ledConfInfo.img_id,
            },
          })
        : _vm._e(),
      _vm.eventInfo.start == 2 || _vm.eventInfo.start == 3 || _vm.isShowRoom
        ? _c(
            "div",
            {
              staticClass: "screen-show",
              style: { "background-color": _vm.allColor.left.background_color },
            },
            [
              _vm.ledInfo.is_map === 1
                ? [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "calc(100vw - 246px)",
                          height: "calc(100vh - 20px)",
                          padding: "0 20px 20px",
                          margin: "10px",
                          "border-radius": "20px",
                        },
                        style: {
                          backgroundColor:
                            _vm.allColor.left.carPlan.border_color || "#f5222d",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              height: "40px",
                              padding: "0 20px",
                              "line-height": "40px",
                              "text-align": "center",
                            },
                            style: {
                              color:
                                _vm.allColor.left.carPlan.title_color ||
                                "#161414",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "25px" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    (_vm.houseRes[_vm.carouselIndex].map.name
                                      .build_name || "") +
                                      (_vm.houseRes[_vm.carouselIndex].map.name
                                        .unit_name || "") +
                                      (_vm.houseRes[_vm.carouselIndex].map.name
                                        .floor_name || "")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "下载平面图",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      right: "10px",
                                    },
                                  },
                                  [
                                    _c(
                                      "svg-icon",
                                      {
                                        staticStyle: {
                                          "font-size": "24px",
                                          cursor: "pointer",
                                        },
                                        attrs: {
                                          "icon-class": "plane-download",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getImage(
                                              _vm.carouselIndex
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("下载")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-carousel",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "indicator-position": "none",
                              autoplay: _vm.ledInfo.is_banner == 1,
                              interval: (_vm.ledInfo.carousel_num || 5) * 1000,
                              arrow: "never",
                              height: "calc(100vh - 80px)",
                            },
                            on: { change: _vm.onCarouselChange },
                          },
                          _vm._l(_vm.houseRes, function (item, index) {
                            return _c(
                              "el-carousel-item",
                              { key: index },
                              [
                                _c("carPlan", {
                                  ref: "carPlan",
                                  refInFor: true,
                                  attrs: {
                                    background_color:
                                      _vm.allColor.left.carPlan
                                        .background_color || "#ffffff",
                                    room_status_color:
                                      item.map.room_status_color,
                                    index: index,
                                    "right-width":
                                      _vm.ledInfo.show_cate == 1 ||
                                      _vm.ledInfo.show_count == 1 ||
                                      _vm.ledInfo.show_online == 1 ||
                                      _vm.ledInfo.show_event_time == 1 ||
                                      _vm.ledInfo.show_sale == 1 ||
                                      _vm.ledInfo.show_amount == 1 ||
                                      _vm.ledInfo.title == 1
                                        ? 286
                                        : 60,
                                    top: 80,
                                  },
                                  on: {
                                    onCarPlanCreated: _vm.onCarPlanCreated,
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _vm.carousels.length && _vm.ledInfo.is_banner == 1
                      ? [
                          _c("div", {
                            staticStyle: {
                              width: "100vw",
                              height: "100vh",
                              position: "absolute",
                              top: "0",
                              left: "0",
                              "z-index": "10000000",
                            },
                          }),
                          _c(
                            "el-carousel",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "indicator-position": "none",
                                autoplay: true,
                                interval:
                                  (_vm.ledInfo.carousel_num || 5) * 1000,
                                arrow: "never",
                                height: "100vh",
                              },
                            },
                            _vm._l(_vm.carousels, function (item, j) {
                              return _c(
                                "el-carousel-item",
                                { key: j },
                                [
                                  _c("screen-nobanner", {
                                    attrs: {
                                      "house-list": _vm.houseRes,
                                      carousels: item.carousels,
                                      "led-conf-info": _vm.ledConfInfo,
                                      "project-room-info": _vm.projectRoomInfo,
                                      allColor: _vm.allColor.left,
                                      allSize: _vm.allSize,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ]
                      : _vm.ledInfo.is_banner == 1
                      ? _c("screen-left", {
                          attrs: {
                            "house-list": _vm.houseRes,
                            "led-conf-info": _vm.ledConfInfo,
                            "project-info": _vm.projectInfo,
                            "project-room-info": _vm.projectRoomInfo,
                          },
                        })
                      : _c("screen-nobanner", {
                          attrs: {
                            "house-list": _vm.houseRes,
                            "led-conf-info": _vm.ledConfInfo,
                            "project-room-info": _vm.projectRoomInfo,
                            allColor: _vm.allColor.left,
                            allSize: _vm.allSize,
                          },
                        }),
                  ],
              !_vm.ledInfo.is_hide
                ? _c("ScreenRightNew", {
                    ref: "rightCommont",
                    attrs: {
                      eventInfo: _vm.eventInfo,
                      "all-data": _vm.allDatas,
                      "event-id": _vm.event_id,
                      "is-begin-show": _vm.isBeginShow,
                      "led-conf-info": _vm.ledConfInfo,
                      ledInfo: _vm.ledInfo,
                      oldprice: _vm.oldprice,
                      "oldremain-count": _vm.oldremainCount,
                      "project-info": _vm.projectInfo,
                      content: _vm.content,
                      "batch-time-data": _vm.batchTimeData,
                      nextbatchTime: _vm.nextbatchTime,
                      nextuptoText: _vm.nextuptoText,
                      times: _vm.times,
                      "card-type": "card",
                      "batch-text": _vm.batchText,
                      "batch-current": _vm.batchCurrent,
                      signEndTime: _vm.signEndTime,
                      eventStartTime: _vm.eventStartTime,
                      Last3Batch: _vm.Last3Batch,
                    },
                    on: { countDownOver: _vm.countDownOver },
                  })
                : _c("my-screen", {
                    staticStyle: {
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      "z-index": "10000",
                    },
                    attrs: { isShowText: false },
                  }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowTen,
              expression: "isShowTen",
            },
          ],
          staticClass: "count-down-ten-wrapper",
        },
        [
          _c("div", { staticClass: "count-down-ten" }),
          _c(
            "div",
            { staticClass: "count-down-img" },
            _vm._l(_vm.countDownImg, function (item, i) {
              return _c("div", { key: i, staticClass: "img" }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: i == _vm.countDownIndex,
                      expression: "i == countDownIndex",
                    },
                  ],
                  staticClass: "animate",
                  attrs: { src: item },
                }),
              ])
            }),
            0
          ),
        ]
      ),
      _vm.wsDialog && _vm.roles.includes("admin")
        ? _c("div", { staticClass: "ws-dialog" }, [
            _c("div", { staticClass: "ws-dialog-title" }, [
              _c("span", [_vm._v("scoket推送记录")]),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      _vm.wsDialog = false
                    },
                  },
                },
                [_vm._v("X")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "ws-record" },
              _vm._l(_vm.wsPush, function (item, index) {
                return _c("div", { key: index, staticClass: "ws-list" }, [
                  _c("div", { staticClass: "room-name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("div", { staticClass: "push-count" }, [
                    _vm._v(_vm._s(item.count) + "条"),
                  ]),
                  _c("div", { staticClass: "push-time" }, [
                    _vm._v(_vm._s(item.time)),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-reload-box" }, [
      _c("img", { attrs: { src: require("../../assets/image/lottery.png") } }),
      _c("img", {
        attrs: {
          src: require("../../assets/image/countDown/countdown-bg.png"),
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }