import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import items from '@/views/tool/rightSetting/common/items.vue';
import CountDown from '@/components/countDown';
import newCountdown from '@/components/screenRight/components/countdown.vue';
import currentTime from '@/components/screenRight/components/currentTime.vue';
import { mapGetters, mapState, mapMutations } from 'vuex';
import vueSeamlessScroll from 'vue-seamless-scroll';
import CountTo from 'vue-count-to';
import MyScreen from '@/components/my-screen/index.vue';
export default {
  name: 'screenRightNew',
  props: {
    batchTimeData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // nextbatchTime
    nextbatchTime: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    batchText: {
      type: String,
      default: ''
    },
    nextuptoText: {
      type: String,
      default: ''
    },
    batchCurrent: {
      //当前批次
      type: String,
      default: ''
    },
    // Last3Batch
    Last3Batch: {
      //当前批次
      type: String,
      default: ''
    },
    // 当前时间内容
    times: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 活动开始时间
    eventStartTime: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 签到截止时间
    signEndTime: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    oldremainCount: {
      type: Number,
      default: 0
    },
    oldprice: {
      type: Number,
      default: 0
    },
    // 显示配置
    ledInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 活动信息
    eventInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 项目信息
    projectInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    eventId: {
      type: [Number, String],
      default: ''
    },
    content: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    MyScreen: MyScreen,
    CountTo: CountTo,
    currentTime: currentTime,
    newCountdown: newCountdown,
    items: items,
    CountDown: CountDown,
    vueSeamlessScroll: vueSeamlessScroll
  },
  mounted: function mounted() {
    this.calculateRemainingSpace();
    window.addEventListener('resize', this.calculateRemainingSpace);
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    allData: function allData(state) {
      return state.screen.allDatas;
    },
    refreshCountDown: function refreshCountDown(state) {
      return state.screen.refreshCountDown;
    }
  })), mapGetters(['customSetting', 'afterCommontLIst', 'startCommontList', 'commontList'])), {}, {
    commontList: {
      get: function get() {
        var _this$$store;

        return (_this$$store = this.$store) === null || _this$$store === void 0 ? void 0 : _this$$store.getters.commontList;
      },
      set: function set(value, test) {}
    },
    commontShowList: function commontShowList() {
      var _this = this;

      var eventStart = this.eventInfo.start == 2; //活动是否开始

      var list = [];
      this.SWICTH_COMMONT(this.eventInfo.start);
      list = this.commontList;
      list.map(function (item, index) {
        if (item.type == 'time') {
          //时间处理
          if (item.type2 == 'eventTime') {
            item.title = eventStart ? '距离结束还有' : '距离开始还有';
          }

          if (item.type2 == 'batchStart') {
            item.title = _this.batchText;
          }

          if (item.type2 == 'batchEnd') {
            item.title = _this.nextuptoText;
          }

          if (item.type2 == 'singEnd') {}
        }

        if (item.type == 'amount') {
          //指标组件 赋值
          item.children.map(function (children) {
            var titleValue;

            if (children.title.indexOf('可选') == 0) {
              titleValue = '可选Case';
            } else if (children.title.indexOf('总数') > -1) {
              titleValue = '总数Case';
            } else if (children.title.indexOf('已选') > -1) {
              titleValue = '已选Case';
            } else {
              titleValue = children.title;
            }

            switch (titleValue) {
              case '参与人数':
                children.value = _this.allData.numberOnline;
                break;

              case '可选Case':
                children.value = children.unit == 'room' ? _this.allData.remainCount1 : _this.allData.remainCount2;
                break;

              case '总数Case':
                children.value = children.unit == 'room' ? _this.allData.roomCount1 : _this.allData.roomCount2;
                break;

              case '已选Case':
                children.value = children.unit == 'room' ? _this.allData.roomCount1 - _this.allData.remainCount1 : _this.allData.roomCount2 - _this.allData.remainCount2;
                break;

              case '已签到人数':
                console.log(_this.allData.signCount);
                children.value = String(_this.allData.signCount);
                break;

              case '未签到人数':
                children.value = String(_this.allData.userCount - _this.allData.signCount);
                break;

              default:
                console.log('没有匹配到');
            }
          });
        } else {
          //其他组件
          switch (item.type2) {
            case 'name':
              //项目名称
              item.value = _this.projectInfo.name;
              break;

            case 'afterBatch':
              item.value = _this.Last3Batch;
              break;

            case 'batch':
              item.value = !eventStart ? '批次未开始' : /\d/.test(_this.batchCurrent) ? "\u7B2C".concat(_this.batchCurrent.match(/\d+/g)[0], "\u6279") : '批次已结束';
              break;
          }
        }
      });
      return list;
    }
  }),
  data: function data() {
    return {
      countDown: 'countDown',
      isShowCountDown: true,
      scroll: 'vueSeamlessScroll',
      // 滚动配置
      classOption: {
        step: 0.5,
        // 数值越大速度滚动越快
        limitMoveNum: 8,
        // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        openWatch: true,
        // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000,
        // 单步运动停止的时间(默认值1000ms)
        autoPlay: true // 自动播放

      },
      classOptionC: {
        step: 0.5,
        // 数值越大速度滚动越快
        limitMoveNum: 3,
        // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        openWatch: true,
        // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000,
        // 单步运动停止的时间(默认值1000ms)
        autoPlay: true // 自动播放

      },
      remainingSpace: '',
      //滚动显示高度
      // 名字脱敏
      hideName: function hideName(name) {
        if (name && name.length == 2) {
          var userName = name.substring(0, 1) + '*'; // 截取name 字符串截取第一个字符，

          return userName;
        } else if (name && name.length == 3) {
          var _userName = name.substring(0, 1) + '*' + name.substring(2, 3); // 截取第一个和第三个字符


          return _userName;
        } else if (name && name.length > 3) {
          var _userName2 = name.substring(0, 1) + '*' + '*' + name.substring(name.length - 1, name.length); // 截取第一个和大于第4个字符


          return _userName2;
        }
      }
    };
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('rightSetting', ['SWICTH_COMMONT'])), {}, {
    calculateRemainingSpace: function calculateRemainingSpace() {
      var parent = this.$refs.screenRight;

      if (parent) {
        var children = parent.querySelectorAll('.child');
        var totalChildHeight = 0; // 遍历所有子元素并计算它们的总高度

        children.forEach(function (child) {
          totalChildHeight += child.offsetHeight + 10;
        });
        var parentHeight = parent.offsetHeight;
        var remainingSpace = parentHeight - totalChildHeight - 10;
        this.remainingSpace = remainingSpace;
      }
    },
    // 子组件更新批次倒计时名称
    uptoDate: function uptoDate(text) {
      console.log(text);
      this.batch_text = text;
    },
    // 倒计时结束
    countDownOver: function countDownOver() {
      var _this2 = this;

      this.isShowCountDown = false;
      this.$nextTick(function () {
        _this2.isShowCountDown = true;
      });
      this.$emit('countDownOver');
    }
  })
};