var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container" },
    [
      _vm.isPC
        ? _c(
            "div",
            { ref: "top", staticClass: "search-box" },
            [
              _c(
                "el-form",
                { ref: "formData", attrs: { inline: true, model: _vm.search } },
                [
                  _c(
                    "el-row",
                    {
                      attrs: {
                        gutter: 20,
                        justify: "space-between",
                        type: "flex",
                      },
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "el-height",
                          attrs: { xs: 20, sm: 12, md: 8, lg: 5 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "账号状态",
                                prop: "batch",
                                "label-width": "75px",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { size: "small", filterable: "" },
                                  model: {
                                    value: _vm.search.is_disable,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "is_disable", $$v)
                                    },
                                    expression: "search.is_disable",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { value: 0, label: "全部" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "已启用", value: 2 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "未启用", value: 1 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "el-height",
                          attrs: { xs: 20, sm: 12, md: 8, lg: 5 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "异常客户",
                                prop: "batch",
                                "label-width": "75px",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    filterable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.search.special,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "special", $$v)
                                    },
                                    expression: "search.special",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "无置业顾问",
                                      value: "no_admin",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "无客户分类",
                                      value: "no_level",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "无批次",
                                      value: "no_batch",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "身份证格式异常",
                                      value: "is_card",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "重复诚意单",
                                      value: "is_code",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "el-height",
                          attrs: { xs: 20, sm: 12, md: 8, lg: 5 },
                        },
                        [
                          _vm.event.is_batch
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "批次",
                                    prop: "batch",
                                    "label-width": "75px",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        size: "small",
                                        filterable: "",
                                        placeholder: "全部",
                                      },
                                      model: {
                                        value: _vm.search.batch,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "batch", $$v)
                                        },
                                        expression: "search.batch",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { value: 0, label: "全部" },
                                      }),
                                      _vm._l(
                                        _vm.activity.batch,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.num,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 20, sm: 12, md: 8, lg: 9 } },
                        [
                          _c("el-form-item", { attrs: { prop: "search" } }, [
                            _c(
                              "div",
                              { staticClass: "flex align-items operate" },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    width: "230px",
                                  },
                                  attrs: {
                                    type: "text",
                                    autocomplete: "on",
                                    size: "small",
                                    placeholder:
                                      "姓名\\手机\\" + _vm.list.user_code_name,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.getTableSearchList($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.search.search,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.search,
                                        "search",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "search.search",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-shrink mL10 flex align-items",
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "greens-btn",
                                        attrs: { size: "small" },
                                        on: { click: _vm.getTableSearchList },
                                      },
                                      [_vm._v("查询")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btnHollowReset",
                                        attrs: { size: "small" },
                                        on: { click: _vm.resetForm },
                                      },
                                      [_vm._v("重置")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "more",
                                        on: { click: _vm.bindMore },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.moreText) + " "
                                        ),
                                        _c("i", { class: _vm.moreIcon }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.is_more
                    ? _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("div", { staticClass: "dashedTop" }),
                          _c(
                            "el-col",
                            { attrs: { xs: 20, sm: 12, md: 8, lg: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "顾问",
                                    "label-width": "75px",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        size: "small",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.search.admin_id,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "admin_id", $$v)
                                        },
                                        expression: "search.admin_id",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        key: "0",
                                        attrs: { label: "全部", value: 0 },
                                      }),
                                      _vm._l(_vm.adminList, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 20, sm: 12, md: 8, lg: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "分类",
                                    "label-width": "75px",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        size: "small",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.search.level_id,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "level_id", $$v)
                                        },
                                        expression: "search.level_id",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._l(
                                        _vm.activity.level,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.level,
                                            attrs: {
                                              label: item.level,
                                              value: item.level,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 20, sm: 12, md: 8, lg: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "摇号分组",
                                    "label-width": "75px",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        size: "small",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.search.rand_group,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "rand_group",
                                            $$v
                                          )
                                        },
                                        expression: "search.rand_group",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._l(
                                        _vm.activity.rand_group,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.rand_group,
                                            attrs: {
                                              label: item.rand_group,
                                              value: item.rand_group,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.show_is_buy_rand
                            ? _c(
                                "el-col",
                                { attrs: { xs: 24, sm: 16, md: 12, lg: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "测试摇号结果",
                                        "label-width": "100px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            size: "small",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.search.is_rand,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.search,
                                                "is_rand",
                                                $$v
                                              )
                                            },
                                            expression: "search.is_rand",
                                          },
                                        },
                                        _vm._l(_vm.randList, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { xs: 20, sm: 12, md: 8, lg: 5 } },
                            [
                              _vm.event.is_round
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "活动",
                                        prop: "inxId",
                                        "label-width": "75px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            size: "small",
                                            filterable: "",
                                            placeholder: "全部",
                                          },
                                          model: {
                                            value: _vm.search.inxId,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.search, "inxId", $$v)
                                            },
                                            expression: "search.inxId",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              value: "all",
                                              label: "全部",
                                            },
                                          }),
                                          _vm._l(
                                            _vm.activity.project,
                                            function (item, i) {
                                              return _c("el-option", {
                                                key: i,
                                                attrs: {
                                                  label: item.name,
                                                  value: i,
                                                },
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "block-bottom mT16" }, [
        _c(
          "div",
          { staticClass: "button-box flex align-items space-between" },
          [
            _c(
              "div",
              { staticClass: "font-style" },
              [
                _c(
                  "el-dropdown",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: [
                          "admin",
                          "user/delall",
                          "user/del",
                          "user/setadminall",
                          "user/disableenableall",
                          "user/disableenable",
                          "user/israndall",
                          "user/updatelevels",
                        ],
                        expression:
                          "[\n\t\t\t\t\t\t\t'admin',\n\t\t\t\t\t\t\t'user/delall',\n\t\t\t\t\t\t\t'user/del',\n\t\t\t\t\t\t\t'user/setadminall',\n\t\t\t\t\t\t\t'user/disableenableall',\n\t\t\t\t\t\t\t'user/disableenable',\n\t\t\t\t\t\t\t'user/israndall',\n\t\t\t\t\t\t\t'user/updatelevels',\n\t\t\t\t\t\t]",
                      },
                    ],
                    attrs: { size: "small" },
                    on: { command: _vm.handleCommand },
                  },
                  [
                    _c(
                      "el-button",
                      { staticClass: "greens-btn", attrs: { size: "small" } },
                      [
                        _vm._v(" 批量操作 "),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "user/delall", "user/del"],
                                expression:
                                  "['admin', 'user/delall', 'user/del']",
                              },
                            ],
                            attrs: { command: "delAll" },
                          },
                          [_vm._v("批量删除")]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "user/setadminall"],
                                expression: "['admin', 'user/setadminall']",
                              },
                            ],
                            attrs: { command: "distribute" },
                          },
                          [_vm._v("分配顾问")]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: [
                                  "admin",
                                  "user/disableenableall",
                                  "user/disableenable",
                                ],
                                expression:
                                  "['admin', 'user/disableenableall', 'user/disableenable']",
                              },
                            ],
                            attrs: { command: "disable" },
                          },
                          [_vm._v("批量禁用")]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: [
                                  "admin",
                                  "user/disableenableall",
                                  "user/disableenable",
                                ],
                                expression:
                                  "['admin', 'user/disableenableall', 'user/disableenable']",
                              },
                            ],
                            attrs: { command: "enable" },
                          },
                          [_vm._v("批量启用")]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "user/updatelevels"],
                                expression: "['admin', 'user/updatelevels']",
                              },
                            ],
                            attrs: { command: "updateLevels" },
                          },
                          [_vm._v("批量修改客户分类")]
                        ),
                        _vm.show_is_buy_rand
                          ? _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["admin", "user/israndall"],
                                    expression: "['admin', 'user/israndall']",
                                  },
                                ],
                                attrs: { command: "hideRond" },
                              },
                              [_vm._v("隐藏测试摇号结果")]
                            )
                          : _vm._e(),
                        _vm.show_is_buy_rand
                          ? _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["admin", "user/israndall"],
                                    expression: "['admin', 'user/israndall']",
                                  },
                                ],
                                attrs: { command: "showRond" },
                              },
                              [_vm._v("显示测试摇号结果")]
                            )
                          : _vm._e(),
                        _c(
                          "el-dropdown-item",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "user/setbuycountall"],
                                expression: "['admin', 'user/setbuycountall']",
                              },
                            ],
                            attrs: { command: "batchaddQuota" },
                          },
                          [_vm._v("批量增加限购数量")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.selectedNum > 0
                  ? _c("span", { staticClass: "mL10" }, [
                      _vm._v("已选 " + _vm._s(_vm.selectedNum) + " 条"),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { class: _vm.isPC ? "flex" : "" },
              [
                _vm.isPC
                  ? [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "user/add"],
                              expression: "['admin', 'user/add']",
                            },
                          ],
                          staticClass: "orange-btn",
                          attrs: { size: "small" },
                          on: { click: _vm.handelAddUser },
                        },
                        [_vm._v("新增一个客户")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btnHollowGreen",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              _vm.downloadFile = true
                            },
                          },
                        },
                        [_vm._v("下载模板")]
                      ),
                      _c("UploadExcel", {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: [
                              "admin",
                              "user/import",
                              "user/addimport",
                              "user/importprice",
                              "user/sleepimport",
                            ],
                            expression:
                              "['admin', 'user/import', 'user/addimport', 'user/importprice', 'user/sleepimport']",
                          },
                        ],
                        staticClass: "mL10",
                        attrs: { text: "导入", url: "/User/import" },
                        on: { uploadSuccess: _vm.uplsuc },
                      }),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "将建发系统中客户FID同步至开盘系统",
                          },
                        },
                        [
                          _c(
                            "jian-fa",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: [
                                    "admin",
                                    "user/syncfid",
                                    "user/syncnouserfid",
                                  ],
                                  expression:
                                    "['admin', 'user/syncfid','user/syncnouserfid']",
                                },
                              ],
                              staticClass: "btnHollowGreen mL10",
                              attrs: { tag: "el-button", size: "small" },
                              on: { click: _vm.syncUserDialog },
                            },
                            [_vm._v("同步客户FID")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "user/autoauth"],
                              expression: "['admin', 'user/autoauth']",
                            },
                          ],
                          staticClass: "btnHollowGreen mL10",
                          attrs: { size: "small" },
                          on: { click: _vm.syncAutoAuth },
                        },
                        [_vm._v("认证同步")]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            placement: "top-end",
                            content: "仅同步正式活动签到编码",
                          },
                        },
                        [
                          _c(
                            "span",
                            [
                              _vm.sp.is_user_code_value == 2
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: ["admin", "user/syncsigncode"],
                                          expression:
                                            "['admin', 'user/syncsigncode']",
                                        },
                                      ],
                                      staticClass: "btnHollowGreen mL10",
                                      attrs: { size: "small" },
                                      on: { click: _vm.syncSignCode },
                                    },
                                    [_vm._v("同步签到编码")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            placement: "top-end",
                            content: "仅同步正式活动码牌编码",
                          },
                        },
                        [
                          _c(
                            "span",
                            [
                              _vm.sp.is_user_code_value == 1
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: ["admin", "user/syncqrcode"],
                                          expression:
                                            "['admin', 'user/syncqrcode']",
                                        },
                                      ],
                                      staticClass: "btnHollowGreen mL10",
                                      attrs: { size: "small" },
                                      on: { click: _vm.syncPlateCode },
                                    },
                                    [_vm._v("同步码牌编码")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: [
                                "admin",
                                "user/export",
                                "user/priceexport",
                                "user/sleepexport",
                              ],
                              expression:
                                "['admin', 'user/export', 'user/priceexport', 'user/sleepexport']",
                            },
                          ],
                          staticClass: "btnHollowGreen mL10",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              _vm.exportFile = true
                            },
                          },
                        },
                        [_vm._v("导出复核")]
                      ),
                    ]
                  : _vm._e(),
                !_vm.isPC
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btnHollowGreen mL10",
                        attrs: { size: "mini" },
                        on: { click: _vm.showTelMore },
                      },
                      [_vm._v("更多")]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "table-box" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.pictLoading,
                    expression: "pictLoading",
                  },
                ],
                attrs: {
                  border: "",
                  size: "medium",
                  data: _vm.tableData,
                  height: _vm.tableHeight,
                  "header-cell-style": { background: "#fafafa" },
                  "highlight-current-row": "",
                  "element-loading-background": "rgba(0, 0, 0, 0.5)",
                  "element-loading-text": "数据正在加载中",
                  "element-loading-spinner": "el-icon-loading",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { align: "center", type: "selection", width: "50" },
                }),
                _vm.isPC
                  ? _c("el-table-column", {
                      attrs: { align: "center", type: "index", label: "序号" },
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "name",
                    label: "姓名",
                    width: "100",
                  },
                }),
                _vm.isPC
                  ? _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "tel",
                        label: "登录手机号",
                        width: "200",
                      },
                    })
                  : _vm._e(),
                _vm.isPC
                  ? _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "admin_name",
                        label: "置业顾问",
                      },
                    })
                  : _vm._e(),
                _vm.isPC
                  ? _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "code",
                        label: _vm.list.user_code_name,
                      },
                    })
                  : _vm._e(),
                _vm.isPC
                  ? _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "buy_count",
                        label: "限购数量",
                        width: "70",
                      },
                    })
                  : _vm._e(),
                _vm.isPC
                  ? _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "level",
                        label: "客户分类",
                      },
                    })
                  : _vm._e(),
                _vm.isPC
                  ? _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "num",
                        label: "批次序号",
                        width: "100",
                      },
                    })
                  : _vm._e(),
                _vm.show_is_buy_rand
                  ? _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "测试摇号结果",
                        width: "120",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scoped) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "user/israndall"],
                                      expression: "['admin', 'user/israndall']",
                                    },
                                  ],
                                  staticClass: "flex align-items ju-center",
                                },
                                [
                                  _c("el-switch", {
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handelRand(scoped.row)
                                      },
                                    },
                                    model: {
                                      value: scoped.row.is_rand,
                                      callback: function ($$v) {
                                        _vm.$set(scoped.row, "is_rand", $$v)
                                      },
                                      expression: "scoped.row.is_rand",
                                    },
                                  }),
                                  scoped.row.is_rand
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "status1",
                                          staticStyle: { "margin-left": "0" },
                                        },
                                        [_vm._v("显示")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass: "status2",
                                          staticStyle: { "margin-left": "0" },
                                        },
                                        [_vm._v("隐藏")]
                                      ),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        3101043566
                      ),
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: [
                        "admin",
                        "user/disableenable",
                        "user/disableenableall",
                      ],
                      expression:
                        "['admin', 'user/disableenable', 'user/disableenableall']",
                    },
                  ],
                  attrs: { align: "center", label: "账号状态", width: "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return _c(
                          "div",
                          { staticClass: "flex align-items ju-center" },
                          [
                            _c("el-switch", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: [
                                    "admin",
                                    "user/disableenableall",
                                    "user/disableenable",
                                  ],
                                  expression:
                                    "['admin', 'user/disableenableall', 'user/disableenable']",
                                },
                              ],
                              staticStyle: { "margin-right": "10px" },
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              on: {
                                change: function ($event) {
                                  return _vm.handelSwitch(
                                    scoped.row,
                                    scoped.$index
                                  )
                                },
                              },
                              model: {
                                value: scoped.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scoped.row, "status", $$v)
                                },
                                expression: "scoped.row.status",
                              },
                            }),
                            scoped.row.status
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "status1",
                                    staticStyle: { "margin-left": "0" },
                                  },
                                  [_vm._v("启用")]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "status2",
                                    staticStyle: { "margin-left": "0" },
                                  },
                                  [_vm._v("禁用")]
                                ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "操作",
                    width: "160",
                    fixed: "right",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      height: "30px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "编辑",
                                          placement: "top-end",
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: ["admin", "user/edit"],
                                              expression:
                                                "['admin', 'user/edit']",
                                            },
                                          ],
                                          attrs: {
                                            "class-name": "greens",
                                            "icon-class": "edit",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handelEditUser(
                                                scoped.row
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      height: "30px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "详情",
                                          placement: "top-end",
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: ["admin", "user/details"],
                                              expression:
                                                "['admin', 'user/details']",
                                            },
                                          ],
                                          attrs: {
                                            "class-name": "greens",
                                            "icon-class": "info",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handelDetails(
                                                scoped.row
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      height: "30px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "登录口令",
                                          placement: "top-end",
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: [
                                                "admin",
                                                "user/captchainfo",
                                              ],
                                              expression:
                                                "['admin', 'user/captchainfo']",
                                            },
                                          ],
                                          attrs: {
                                            "class-name": "greens",
                                            "icon-class": "key",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handelCaptchaInfo(
                                                scoped.row.id
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      height: "30px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "删除",
                                          placement: "top-end",
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: [
                                                "admin",
                                                "user/del",
                                                "user/delall",
                                              ],
                                              expression:
                                                "['admin', 'user/del', 'user/delall']",
                                            },
                                          ],
                                          attrs: {
                                            "class-name": "greens",
                                            "icon-class": "delete",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handelDelList(
                                                scoped.row.id
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                small: _vm.isPC ? false : true,
                "page-sizes": _vm.sizes,
                "page-size": _vm.pageSize,
                "pager-count": _vm.isPC ? 7 : 5,
                layout: _vm.isPC ? _vm.layouts : _vm.telLayout,
                total: _vm.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分配顾问",
            visible: _vm.is_distribute,
            width: "500px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.is_distribute = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.postForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "顾问" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.adminDisabled,
                        size: "small",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.postForm.role,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.postForm,
                            "role",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "postForm.role",
                      },
                    },
                    _vm._l(_vm.adminList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name + " - " + item.tel,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设置为无顾问" } },
                [
                  _c("el-checkbox", {
                    attrs: { "true-label": 1, "false-label": 0 },
                    on: { change: _vm.handelAdminId },
                    model: {
                      value: _vm.postForm.is_adminList,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "is_adminList", $$v)
                      },
                      expression: "postForm.is_adminList",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.is_distribute = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleDistribute },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "更多操作",
            visible: _vm.isTelMore,
            width: "95%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isTelMore = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "formData", attrs: { inline: true, model: _vm.search } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "el-height",
                      attrs: { span: _vm.isPC ? 4 : 24 },
                    },
                    [
                      _vm.event.is_round
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动",
                                prop: "inxId",
                                "label-width": "50px",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    filterable: "",
                                    placeholder: "全部",
                                  },
                                  model: {
                                    value: _vm.search.inxId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "inxId", $$v)
                                    },
                                    expression: "search.inxId",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { value: "all", label: "全部" },
                                  }),
                                  _vm._l(
                                    _vm.activity.project,
                                    function (item, i) {
                                      return _c("el-option", {
                                        key: i,
                                        attrs: { label: item.name, value: i },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "el-height",
                      attrs: { span: _vm.isPC ? 4 : 24 },
                    },
                    [
                      _vm.event.is_batch
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "批次",
                                prop: "batch",
                                "label-width": "50px",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    filterable: "",
                                    placeholder: "全部",
                                  },
                                  model: {
                                    value: _vm.search.batch,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "batch", $$v)
                                    },
                                    expression: "search.batch",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { value: 0, label: "全部" },
                                  }),
                                  _vm._l(_vm.activity.batch, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.num,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "el-height",
                      attrs: { span: _vm.isPC ? 5 : 24 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "账号状态",
                            prop: "batch",
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "small", filterable: "" },
                              model: {
                                value: _vm.search.is_disable,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "is_disable", $$v)
                                },
                                expression: "search.is_disable",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 0, label: "全部" },
                              }),
                              _c("el-option", {
                                attrs: { label: "已启用", value: 2 },
                              }),
                              _c("el-option", {
                                attrs: { label: "未启用", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "el-height",
                      attrs: { span: _vm.isPC ? 5 : 24 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "特殊客户",
                            prop: "batch",
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "small",
                                filterable: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.search.special,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "special", $$v)
                                },
                                expression: "search.special",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "无置业顾问",
                                  value: "no_admin",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "无客户分类",
                                  value: "no_level",
                                },
                              }),
                              _c("el-option", {
                                attrs: { label: "无批次", value: "no_batch" },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "身份证格式异常",
                                  value: "is_card",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "重复诚意单",
                                  value: "is_code",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: _vm.isPC ? 6 : 24 } },
                    [
                      _c("el-form-item", { attrs: { prop: "search" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                autocomplete: "on",
                                size: "small",
                                placeholder: "姓名\\登录手机号",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getTableSearchList($event)
                                },
                              },
                              model: {
                                value: _vm.search.search,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.search,
                                    "search",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "search.search",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "flex-shrink mL10" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "greens-btn",
                                    attrs: { size: "small" },
                                    on: { click: _vm.getTableSearchList },
                                  },
                                  [_vm._v("查询")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btnHollowReset",
                                    attrs: { size: "small" },
                                    on: { click: _vm.resetForm },
                                  },
                                  [_vm._v("重置")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex ju-center" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "user/add"],
                      expression: "['admin', 'user/add']",
                    },
                  ],
                  staticClass: "orange-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.handelAddUser },
                },
                [_vm._v("新增一个客户")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex ju-center" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowGreen",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.downloadFile = true
                    },
                  },
                },
                [_vm._v("下载模板")]
              ),
              _c("UploadExcel", {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: [
                      "admin",
                      "user/import",
                      "user/addimport",
                      "user/importprice",
                    ],
                    expression:
                      "['admin', 'user/import', 'user/addimport', 'user/importprice']",
                  },
                ],
                staticClass: "mL10",
                attrs: { text: "导入", url: "/User/import" },
                on: { uploadSuccess: _vm.uplsuc },
              }),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "user/export", "user/priceexport"],
                      expression:
                        "['admin', 'user/export', 'user/priceexport']",
                    },
                  ],
                  staticClass: "btnHollowGreen mL10",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.exportFile = true
                    },
                  },
                },
                [_vm._v("导出复核")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "下载模板",
            "destroy-on-close": "",
            visible: _vm.downloadFile,
            width: "400px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.downloadFile = $event
            },
          },
        },
        [
          _c("div", { staticClass: "radio-list" }, [
            _c(
              "div",
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1" },
                    model: {
                      value: _vm.is_downloadFile,
                      callback: function ($$v) {
                        _vm.is_downloadFile = $$v
                      },
                      expression: "is_downloadFile",
                    },
                  },
                  [_vm._v("客户名单模板")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "user/importprice"],
                    expression: "['admin', 'user/importprice']",
                  },
                ],
                staticClass: "mT15",
              },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "2" },
                    model: {
                      value: _vm.is_downloadFile,
                      callback: function ($$v) {
                        _vm.is_downloadFile = $$v
                      },
                      expression: "is_downloadFile",
                    },
                  },
                  [_vm._v("诚意金信息模板")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "user/sleepimport"],
                    expression: "['admin', 'user/sleepimport']",
                  },
                ],
                staticClass: "mT15",
              },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "3" },
                    model: {
                      value: _vm.is_downloadFile,
                      callback: function ($$v) {
                        _vm.is_downloadFile = $$v
                      },
                      expression: "is_downloadFile",
                    },
                  },
                  [_vm._v("特殊客户名单模板")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticClass: "text-box" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btnHollowGreen",
                      attrs: { size: "small" },
                      on: { click: _vm.handelModel },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导出复核",
            "destroy-on-close": "",
            visible: _vm.exportFile,
            width: "400px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.exportFile = $event
            },
          },
        },
        [
          _c("div", { staticClass: "radio-list" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "user/export"],
                    expression: "['admin', 'user/export']",
                  },
                ],
              },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1" },
                    model: {
                      value: _vm.is_exportFile,
                      callback: function ($$v) {
                        _vm.is_exportFile = $$v
                      },
                      expression: "is_exportFile",
                    },
                  },
                  [_vm._v("复核客户名单")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "user/sleepexport"],
                    expression: "['admin', 'user/sleepexport']",
                  },
                ],
                staticClass: "mT15",
              },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "3" },
                    model: {
                      value: _vm.is_exportFile,
                      callback: function ($$v) {
                        _vm.is_exportFile = $$v
                      },
                      expression: "is_exportFile",
                    },
                  },
                  [_vm._v("复核特殊客户名单")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticClass: "text-box" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btnHollowGreen",
                      attrs: { size: "small" },
                      on: { click: _vm.handelExport },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改客户分类",
            visible: _vm.levelData.show,
            width: "500px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.levelData, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.levelData.data } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户分类：" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "80" },
                    model: {
                      value: _vm.levelData.data.level,
                      callback: function ($$v) {
                        _vm.$set(_vm.levelData.data, "level", $$v)
                      },
                      expression: "levelData.data.level",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.onLevelData("close")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.onLevelData("submit")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量增加限购数量",
            visible: _vm.addQuota.show,
            width: "500px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.addQuota, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addQuotarulesForm",
              attrs: {
                model: _vm.addQuota.data,
                rules: _vm.addQuotarules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户范围:", prop: "types" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { disabled: !_vm.delArray, label: 0 },
                      model: {
                        value: _vm.addQuota.data.types,
                        callback: function ($$v) {
                          _vm.$set(_vm.addQuota.data, "types", $$v)
                        },
                        expression: "addQuota.data.types",
                      },
                    },
                    [_vm._v("已勾选客户")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { disabled: !_vm.delArray, label: 1 },
                      model: {
                        value: _vm.addQuota.data.types,
                        callback: function ($$v) {
                          _vm.$set(_vm.addQuota.data, "types", $$v)
                        },
                        expression: "addQuota.data.types",
                      },
                    },
                    [_vm._v("全部客户")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "认购状态:", prop: "buy_status" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.addQuota.data.buy_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.addQuota.data, "buy_status", $$v)
                        },
                        expression: "addQuota.data.buy_status",
                      },
                    },
                    [_vm._v("已认购")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.addQuota.data.buy_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.addQuota.data, "buy_status", $$v)
                        },
                        expression: "addQuota.data.buy_status",
                      },
                    },
                    [_vm._v("未认购")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.addQuota.data.buy_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.addQuota.data, "buy_status", $$v)
                        },
                        expression: "addQuota.data.buy_status",
                      },
                    },
                    [_vm._v("全部")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户分类:", prop: "level" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        size: "small",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addQuota.data.level,
                        callback: function ($$v) {
                          _vm.$set(_vm.addQuota.data, "level", $$v)
                        },
                        expression: "addQuota.data.level",
                      },
                    },
                    _vm._l(_vm.activity.level, function (item) {
                      return _c("el-option", {
                        key: item.level,
                        attrs: { label: item.level, value: item.level },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "增加限购数量:", prop: "buy_count" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1 },
                    model: {
                      value: _vm.addQuota.data.buy_count,
                      callback: function ($$v) {
                        _vm.$set(_vm.addQuota.data, "buy_count", $$v)
                      },
                      expression: "addQuota.data.buy_count",
                    },
                  }),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "在客户现有限购数量上追加数量X",
                        placement: "top-start",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-question",
                        staticStyle: { "margin-left": "10px" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.onaddQuota("close")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.onaddQuota("submit")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.syncShow,
            width: "500px",
            title: "同步客户FID",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.syncShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-radio",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "user/syncfid"],
                      expression: "['admin', 'user/syncfid']",
                    },
                  ],
                  attrs: { label: 0 },
                  model: {
                    value: _vm.syncuserType,
                    callback: function ($$v) {
                      _vm.syncuserType = $$v
                    },
                    expression: "syncuserType",
                  },
                },
                [_vm._v("同步全部客户FID")]
              ),
              _c(
                "el-radio",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "user/syncnouserfid"],
                      expression: "['admin','user/syncnouserfid']",
                    },
                  ],
                  attrs: { label: 1 },
                  model: {
                    value: _vm.syncuserType,
                    callback: function ($$v) {
                      _vm.syncuserType = $$v
                    },
                    expression: "syncuserType",
                  },
                },
                [_vm._v("同步无FID客户")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.syncShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.syncUserFID()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }