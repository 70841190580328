import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import API from '@/api/activityData';
import { adminParameter, authGetEvent, projectConfigure } from '@/api/public';
import { userAdmin } from '@/api/clientSet';
import Api_index from '@/api/index';
import eventSelect from '@/components/eventSelect';
import utils from '@/utils/utils';
export default {
  name: 'UserTable',
  components: {
    eventSelect: eventSelect
  },
  data: function data() {
    return {
      //  账号状态
      status: 2,
      codeType: [{
        label: '全部',
        id: 0
      }, {
        label: '启用',
        id: 2
      }, {
        label: '已禁用',
        id: 1
      }],
      level_id: '',
      levelList: [],
      rand_group: '',
      randGroupList: [],
      batchNum: 0,
      // 签约
      reserved: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '有'
      }, {
        value: 2,
        label: '无'
      }],
      // 登录状态
      is_login: 0,
      // 登录状态数组
      loginArray: [{
        title: '全部',
        id: 0
      }, {
        title: '登录过',
        id: 1
      }, {
        title: '未登录过',
        id: 2
      }],
      is_collect: 0,
      // 是否有意向房源
      // 是否有意向房源 0全部 1有  2无
      is_collectOptions: [{
        label: '全部',
        id: 0
      }, {
        label: '有',
        id: 1
      }, {
        label: '无',
        id: 2
      }],
      is_online: 0,
      // 是否在线
      // 是否在线 0全部 1在线 2不在线
      is_onlineOPtions: [{
        label: '全部',
        id: 0
      }, {
        label: '有参与',
        id: 1
      }, {
        label: '无参与',
        id: 2
      }],
      is_sign: 0,
      // 是否签到
      // 是否签到 0全部 1签到  2未签到
      is_signOptions: [{
        label: '全部',
        id: 0
      }, {
        label: '已签到',
        id: 1
      }, {
        label: '未签到',
        id: 2
      }],
      is_order: 0,
      // 是否选房
      // 是否选房 0全部 1已选房 2未选房
      is_orderOptions: [{
        label: '全部',
        id: 0
      }, {
        label: '已选完',
        id: 1
      }, {
        label: '仅选部分名额',
        id: 2
      }, {
        label: '未选',
        id: 3
      }],
      is_printing: 0,
      // 签约筛选
      // 签约
      onLine: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '签约完毕'
      }, {
        value: 2,
        label: '仅签约部分订单'
      }, {
        value: 3,
        label: '未签约'
      }],
      pay_name: 0,
      // 定金状态
      // 交定金
      onLineDeposit: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '交定金完毕'
      }, {
        value: 2,
        label: '仅交部分订单'
      }, {
        value: 3,
        label: '未交定'
      }],
      // 打印认购书
      room_printing: 0,
      printing: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '打印完毕'
      }, {
        value: 2,
        label: '仅打印部分订单'
      }, {
        value: 3,
        label: '未打印'
      }],
      // 存档
      is_archive: 0,
      // 存档状态
      archive: [{
        title: '全部',
        id: 0
      }, {
        title: '存档完毕',
        id: 1
      }, {
        title: '仅存档部分订单',
        id: 2
      }, {
        title: '未存档',
        id: 3
      }],
      // 签约排号
      sign_row: 0,
      // 浅语排号数组
      is_sign_row: [{
        label: '全部',
        id: 0
      }, {
        label: '已到号',
        id: 1
      }, {
        label: '未到号',
        id: 2
      }],
      // 实名认证
      is_auth: 0,
      is_authArray: [{
        label: '全部',
        id: 0
      }, {
        label: '认证完毕',
        id: 1
      }, {
        label: '部分产权人认证',
        id: 2
      }, {
        label: '未认证',
        id: 3
      }],
      is_unusual: 0,
      // 产权人
      // 产权人异常 0全部 1产权人异常 2无异常
      propertySatus: [{
        label: '全部',
        id: 0
      }, {
        label: '产权人异常',
        id: 1
      }, {
        label: '无异常',
        id: 2
      }],
      // 页面高度
      appHeight: 0,
      // div高度
      topHeight: 0,
      // 摇号排号 批次
      lotteryList: [],
      // 选中的顾问
      admin_id: 0,
      // 是否展开
      is_more: false,
      // 是否展开text
      moreText: '展开',
      // 是否展开 icon
      moreIcon: 'el-icon-arrow-down',
      currentPage4: 1,
      form: {},
      value: '',
      input: '',
      // 客户状态表 参数
      page: 1,
      // 分页
      limit: 10,
      // 数量
      total: 0,
      // 总数
      is_reserv: 0,
      // 是否有预留
      is_rand: 0,
      // 是否到号
      search: '',
      // 搜索关键字
      // 活动列表
      event_list: '',
      // 列表
      list: [],
      // 表格loading
      tableLoading: false,
      // 是否有预留 0全部 1有 2 无
      is_reservOptions: [{
        label: '全部',
        id: 0
      }, {
        label: '有',
        id: 1
      }, {
        label: '无',
        id: 2
      }],
      // 是否到号 0全部 1到号 2未到号
      is_randOptions: [{
        label: '全部',
        id: 0
      }, {
        label: '到号',
        id: 1
      }, {
        label: '未到号',
        id: 2
      }],
      sign_up: true,
      is_price: true,
      // table字段
      isTableText: '',
      adminListUser: [],
      // 是否展示存档
      data_file: false,
      sign_up_rand: 0,
      // 获取批次列表
      batchList: [],
      // 公共信息批次
      eventData: {},
      // 排序数组
      sortRoom: [],
      adminList: {},
      select_event: {},
      // 选择的活动和场次id集
      eventList: [],
      // 项目活动列表
      //  选中的数量
      selectedNum: 0,
      delArray: '',
      userIdArray: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles', 'customSetting'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 142;
    }
  }),
  watch: {
    is_more: function is_more() {
      this.$nextTick(function () {
        this.topHeight = this.$refs.top.offsetHeight;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.appHeight = this.$refs.app.offsetHeight;
    this.topHeight = this.$refs.top.offsetHeight;

    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
      _this.topHeight = _this.$refs.top.offsetHeight;
    };

    userAdmin().then(function (res) {
      if (res.status === 200) {
        var list = res.data.list;
        _this.adminListUser = list;
      }
    });
    Api_index.projectLists().then(function (res) {
      var _res$data;

      _this.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
    projectConfigure().then(function (res) {
      if (res.status === 200) {
        _this.batchList = res.data.list.batch;
        _this.levelList = res.data.list.level;
        _this.randGroupList = res.data.list.rand_group;
      }
    }); // 获取event

    this.getMes();
  },
  destroyed: function destroyed() {
    window.onresize = null;
  },
  methods: {
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      this.select_event = {
        event_id: e.event.id,
        eventid: e.event.id
      };

      if (this.roles.includes('userstatus/index') || this.roles.includes('admin')) {
        this.getCustomList();
      }
    },
    // 排序触发事件
    sortChange: function sortChange(column) {
      this.sortRoom = []; // 房号排序

      if (column.prop === 'code') {
        if (column.order === 'descending') {
          this.sortRoom.push('u.code desc');
        } else if (column.order === 'ascending') {
          this.sortRoom.push('u.code asc');
        } else {
          this.sortRoom.push('');
        } // 下单时间排序

      } else if (column.prop === 'rand_name') {
        if (column.order === 'descending') {
          this.sortRoom.push('u.num desc');
        } else if (column.order === 'ascending') {
          this.sortRoom.push('u.num asc');
        } else {
          this.sortRoom.push('');
        }
      }

      this.getCustomList();
    },
    // 批量操作
    handleCommand: function handleCommand(type) {
      switch (type) {
        case 'login':
          this.loginUser();
          break;

        case 'enable':
          this.handleStatusList(1);
          break;

        case 'disable':
          this.handleStatusList(0);
          break;

        case 'join':
          this.joinUser();
          break;

        case 'signin':
          this.signin();
          break;
      }
    },
    // 批量签到
    signin: function signin() {
      var _this2 = this;

      var ids = this.delArray;

      if (!ids) {
        this.$message.warning('请选择后操作');
        return;
      }

      API.signIn(_objectSpread({
        ids: ids
      }, this.select_event)).then(function (res) {
        _this2.getCustomList();

        _this2.$message.success(res.data.msg);
      }).catch(function (_) {// this.$message.error('操作失败');
      });
    },
    // 参与用户
    joinUser: function joinUser() {
      var _this3 = this;

      var ids = this.delArray;

      if (!ids) {
        this.$message.warning('请选择后操作');
        return;
      }

      API.joins(_objectSpread({
        ids: ids
      }, this.select_event)).then(function (res) {
        _this3.getCustomList();

        _this3.$message.success(res.data.msg);
      }).catch(function (_) {
        _this3.$message.error('操作失败');
      });
    },
    // 登录用户
    loginUser: function loginUser() {
      var _this4 = this;

      var ids = this.delArray;

      if (!ids) {
        this.$message.warning('请选择后操作');
        return;
      }

      API.login({
        ids: ids
      }).then(function (res) {
        _this4.getCustomList();

        _this4.$message.success('操作成功');
      }).catch(function (_) {
        _this4.$message.error('操作失败');
      });
    },
    // 批量禁用启用
    handleStatusList: function handleStatusList(e) {
      var _this5 = this;

      var ids = this.delArray;

      if (!ids) {
        this.$message({
          type: 'warning',
          message: '选中数据再进行操作'
        });
        return;
      }

      API.disableEnableAll({
        ids: ids,
        status: e
      }).then(function (res) {
        if (res.status === 200) {
          _this5.$message.success(res.data.msg);

          _this5.getCustomList();
        }
      }).catch(function (err) {
        var _err$response = err.response,
            data = _err$response.data,
            status = _err$response.status;

        if (status === 400 && data.is_audit) {
          _this5.$confirm(data.msg + '禁止修改~', '提示', {
            confirmButtonText: '去处理',
            cancelButtonText: '关闭',
            type: 'warning'
          }).then(function () {
            _this5.$router.push({
              name: 'UserAudit'
            });
          }).catch(function (err) {});
        }
      });
    },
    // table 选中的数据
    handleSelectionChange: function handleSelectionChange(row) {
      this.selectedNum = row.length;
      var array = [];
      row.forEach(function (item) {
        array.push(item.id);
      });
      this.delArray = array.join(',');
      this.userIdArray = array;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.limit = val;
      this.getCustomList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getCustomList();
    },
    // 展开& 收起
    bindMore: function bindMore() {
      this.is_more = !this.is_more;
      this.moreText = this.is_more ? '收起' : '展开';
      this.moreIcon = this.is_more ? 'el-icon-arrow-up' : 'el-icon-arrow-down';
    },
    // 获取客户状态表
    getCustomList: function getCustomList() {
      var _this6 = this;

      var params = _objectSpread(_objectSpread({}, this.select_event), {}, {
        page: this.page,
        // 分页
        limit: this.limit,
        // 数量
        is_login: this.is_login,
        // 是否登录过
        is_reserv: this.is_reserv,
        // 是否有预留
        // is_collect: this.is_collect, //是否有意向房源
        is_online: this.is_online,
        // 是否在线
        is_sign: this.is_sign,
        // 是否签到
        is_rand: this.is_rand,
        // 是否到号
        is_order: this.is_order,
        // 是否选房
        is_contract: this.is_printing,
        // 签约筛选
        search: this.search,
        // 搜索关键字
        room_printing: this.room_printing,
        // 打印认购书
        pay_status: this.pay_name,
        // 交定金
        sign_row: this.sign_row,
        // 签约排号
        admin_id: this.admin_id,
        // 职业顾问id
        is_auth: this.is_auth,
        //  实名认证
        is_unusual: this.is_unusual,
        // 产权人
        no_admin: this.admin_id === 'no_admin' ? 1 : 0,
        // 没有职业顾问默认不筛选
        is_archive: this.is_archive,
        // 存档
        batch: this.batchNum,
        // 批次号筛选
        sort: this.sortRoom.join(','),
        status: this.status,
        rand_group: this.rand_group,
        level: this.level_id
      });

      this.tableLoading = true;
      API.customLists(params).then(function (res) {
        _this6.list = res.data.list || [];
        _this6.total = res.data.count;

        if (_this6.adminList.buy_rand === 1) {
          if (res.data.rand_info.type === 1) {
            _this6.isTableText = '摇号';
          } else {
            _this6.isTableText = '选房排号';
          }

          _this6.lotteryList = [{
            label: '全部',
            id: 0
          }, {
            label: '已到',
            id: 1
          }, {
            label: '未到',
            id: 2
          }];
        } else {
          if (_this6.eventData.is_batch) {
            _this6.isTableText = '批次';
            _this6.lotteryList = [{
              label: '全部',
              id: 0
            }, {
              label: '已到',
              id: 1
            }, {
              label: '未到',
              id: 2
            }];
          }
        }
      }).finally(function () {
        _this6.tableLoading = false;
      });
    },
    // 查询
    searchClick: function searchClick() {
      this.getCustomList();
    },
    // 重置
    resetClick: function resetClick() {
      this.is_reserv = 0;
      this.is_collect = 0;
      this.is_online = 0;
      this.is_sign = 0;
      this.is_rand = 0;
      this.is_order = 0;
      this.is_printing = 0;
      this.search = '';
      this.room_printing = 0;
      this.pay_name = 0;
      this.is_login = 0;
      this.admin_id = 0;
      this.no_admin = 0;
      this.is_archive = 0;
      this.is_auth = 0;
      this.is_unusual = 0;
      this.sign_row = 0;
      this.status = 0;
      this.batchNum = 0; // 批次号筛选

      this.getCustomList();
    },
    // 导出
    exportMes: function exportMes() {
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      var params = _objectSpread(_objectSpread({}, this.select_event), {}, {
        page: this.page,
        // 分页
        limit: this.limit,
        // 数量
        is_login: this.is_login,
        // 是否登录过
        is_reserv: this.is_reserv,
        // 是否有预留
        // is_collect: this.is_collect, //是否有意向房源
        is_online: this.is_online,
        // 是否在线
        is_sign: this.is_sign,
        // 是否签到
        is_rand: this.is_rand,
        // 是否到号
        is_order: this.is_order,
        // 是否选房
        is_contract: this.is_printing,
        // 签约筛选
        search: this.search,
        // 搜索关键字
        room_printing: this.room_printing,
        // 打印认购书
        pay_status: this.pay_name,
        // 交定金
        sign_row: this.sign_row,
        // 签约排号
        admin_id: this.admin_id,
        // 职业顾问id
        is_auth: this.is_auth,
        //  实名认证
        no_admin: this.admin_id === 'no_admin' ? 1 : 0,
        // 没有职业顾问默认不筛选
        is_archive: this.is_archive,
        // 存档
        batch: this.batchNum,
        // 批次号筛选
        sort: this.sortRoom.join(','),
        status: this.status,
        rand_group: this.rand_group,
        level: this.level_id
      });

      API.exportCustomLists(params).then(function (res) {
        // let url = res.data.url
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix); // window.location.href = this.host + url;

        loading.close();
      }).catch(function () {
        loading.close();
      });
    },
    // 客户详情
    toUserDetail: function toUserDetail(scope) {
      var id = scope.row.id;
      this.$router.push({
        name: 'UserDetails',
        query: {
          id: id,
          round: this.eventData.is_round
        }
      });
    },
    // 获取信息
    getMes: function getMes() {
      var _this7 = this;

      adminParameter().then(function (res) {
        _this7.event = res.data.event;
        var _res$data2 = res.data,
            list = _res$data2.list,
            event = _res$data2.event;
        _this7.adminList = list; // 是否显示签约 相关

        _this7.sign_up = list.is_display.includes('sign_up'); // 是否显示定金 相关

        _this7.is_price = list.is_display.includes('price');
        _this7.data_file = list.is_display.includes('data_file'); // 是否显示签约排号

        _this7.sign_up_rand = list.sign_up_rand;
        _this7.eventData = event;
      });
    }
  }
};