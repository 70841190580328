import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    eventList: {
      // 项目活动数据
      type: Array,
      default: function _default() {
        return [];
      }
    },
    is_round: {
      // 可以不区分场次
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      selectEvent: {},
      selectRound: {}
    };
  },
  watch: {
    eventList: function eventList() {
      if (this.eventList.length && !this.selectEvent.id) {
        this.selectEventHandle();
        this.selectRoundHandle();
      }
    },
    // 选中的场次发生变化 --- 充当场次选择事件
    selectRound: function selectRound() {
      if (this.selectRound.hasOwnProperty('id')) {
        this.$emit('select_round', {
          event: this.selectEvent,
          round: this.selectRound
        });
      }
    }
  },
  methods: {
    // 活动选择事件
    selectHandle: function selectHandle(e) {
      this.selectEvent = _objectSpread({}, e);
      this.selectRoundHandle();
      this.$emit('select_event', {
        event: this.selectEvent,
        round: this.selectRound
      });
    },
    // 活动选择逻辑
    selectEventHandle: function selectEventHandle() {
      var _this = this;

      var nowTime = Date.parse(new Date()) / 1000;

      var _sortEventList = JSON.parse(JSON.stringify(this.eventList));

      _sortEventList.sort(function (pre, next) {
        return pre.end_time - next.start_time;
      });

      var first_event = _sortEventList[0]; // 第一个活动

      var last_event = _sortEventList[_sortEventList.length - 1]; // 最后一个活动

      if (nowTime < first_event.start_time) {
        // 整个活动没开始
        this.selectEvent = _objectSpread({}, first_event);
      } else if (nowTime > last_event.end_time) {
        // 整个活动结束了
        this.selectEvent = _objectSpread({}, last_event);
      } else {
        _sortEventList.forEach(function (item) {
          if (nowTime >= item.start_time && nowTime < item.end_time) {
            // 当前正在进行 的活动
            _this.selectEvent = _objectSpread({}, item);
          }
        });

        if (!this.selectEvent.id) {
          // 没有活动在进行中, 取马上要进行的一场活动
          for (var i = 0; i < _sortEventList.length; i++) {
            var item = _sortEventList[i];

            if (nowTime <= item.start_time) {
              // 取马上要进行的一场活动
              this.selectEvent = _objectSpread({}, item);
              break;
            }
          }
        }

        if (!this.selectEvent.id) {
          // 也没有马上要进行的活动，取最近结束的一场活动
          for (var _i = 0; _i < _sortEventList.length; _i++) {
            var _item2 = _sortEventList[_i];

            if (nowTime >= _item2.start_time) {
              // 取最进结束的一场活动
              this.selectEvent = _objectSpread({}, _item2);
              break;
            }
          }
        }
      }
    },
    // 场次选择逻辑
    selectRoundHandle: function selectRoundHandle() {
      var _this2 = this;

      if (this.is_round || !this.selectEvent.round_list || !this.selectEvent.round_list.length) {
        this.selectRound = {
          id: 0
        };
        return;
      }

      var nowTime = Date.parse(new Date()) / 1000;

      if (nowTime >= this.selectEvent.start_time && nowTime < this.selectEvent.end_time) {
        // 当前正在进行 的活动
        this.selectEvent.round_list.forEach(function (_item) {
          if (nowTime >= _item.start_time && nowTime < _item.end_time) {
            // 当前正在进行 的场次
            _this2.selectRound = _objectSpread({}, _item);
          }
        });
      } else if (nowTime >= this.selectEvent.end_time) {
        // 活动已结束
        this.selectRound = _objectSpread({}, this.selectEvent.round_list[this.selectEvent.round_list.length - 1]);
      } else {
        // 活动没开始
        this.selectRound = _objectSpread({}, this.selectEvent.round_list[0]);
      }
    },
    // 判断进行状态
    statusFn: function statusFn(info) {
      var nowTime = Date.parse(new Date()) / 1000;
      if (nowTime >= info.start_time && nowTime < info.end_time) return '进行中';
      if (nowTime < info.start_time) return '未开始';
      if (nowTime >= info.end_time) return '已结束';
    }
  }
};