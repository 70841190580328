import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import left_page from './components/three/index';
import right_page from './components/rightPage';
import { carSetMap, carMapIndex, villaArea, carMapCarUp } from '@/api/roomCar';
export default {
  components: {
    left_page: left_page,
    right_page: right_page
  },
  data: function data() {
    return {
      // 所有车位房源数据
      allCartList: [],
      // 平面图中存在的车位房源数据
      threeCartList: [],
      // 所有车位房源的id集合
      carIds: [],
      // 需要进行平面图渲染的车位房源（位置等）
      listData: [],
      // 平面图背景
      imgPath: '',
      // 缩放灵敏度
      sensitivity: {
        zoom: 0.6,
        move: 2
      },
      // 是否是正在添加车位房源
      isCkickAddData: false,
      // 是否正在框选车位房源
      selectCar: false,
      // 框选div数据
      divData: {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      },
      // 平面图背景大小
      imgWidth: 0,
      imgHeight: 0,
      // 楼栋楼层单元选择数据
      map_car: {
        all: [],
        select: {
          build: [],
          floor: [],
          region: []
        },
        build: 0,
        floor: 0,
        region: 0,
        default: {
          build: 0,
          floor: 0,
          region: 0
        }
      },
      // 当前是否加载了平面图
      isOnCart: false,
      // 相机位置，算出大小
      cameraPositionZ: 0,
      room_status_color: [{
        room_status: -1,
        text: '非售',
        color: '#666666',
        show: true
      }, {
        room_status: 0,
        text: '待售',
        color: '#2878FF',
        show: true
      }, {
        room_status: [1, 2, 3],
        text: '已售',
        color: '#FF5722',
        show: true
      }],
      // 当前平面图中的车位房源最新位置数据
      sceneChildrens: [],
      heartIf: false,
      // 当前能否拖动车位位置
      isCarClick: true,
      // 平面图id
      planeId: 0,
      // 是否修改或者删除或者添加过车位
      isSetAdd: false,
      // 房源平面图还是车位平面图   1：房源，2：车位
      cate: 2,
      listTotal: 0,
      //总数
      generatedCount: 0,
      //已生成数量
      progress: 0,
      modifyPermission: []
    };
  },
  created: function created() {
    this.cate = +this.$route.query.cate;

    if (this.cate == 1) {
      //区分房源车位平面图权限
      this.modifyPermission = ['admin', 'room/setmap'];
    } else {
      this.modifyPermission = ['admin', 'car/setmap'];
    }

    this.duGetVillaArea();
  },
  methods: {
    format: function format() {
      this.progress = Math.floor(this.generatedCount / this.listTotal * 100);
      return '已生成' + this.generatedCount;
    },

    /**
     * 获取楼栋楼层区域
     */
    duGetVillaArea: function duGetVillaArea() {
      var _this = this;

      var loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      villaArea({}, this.cate).then(function (res) {
        _this.map_car.all = res.data.list.build; // 获取所有的楼栋

        if (res.data.list.build.length > 1) {
          _this.map_car.select.build = res.data.list.build;
        } else if (res.data.list.build.length === 1) {
          // 如果name存在就是只有一个楼栋
          if (res.data.list.build[0].name) {
            _this.map_car.select.build = res.data.list.build;
          } else {
            _this.map_car.build = res.data.list.build[0].id; // 获取楼层

            if (res.data.list.build[0].unit.length > 1) {
              _this.map_car.select.floor = res.data.list.build[0].unit;
            } else if (res.data.list.build[0].unit.length === 1) {
              // 如果name存在就是只有一个楼层
              if (res.data.list.build[0].unit[0].name) {
                _this.map_car.select.floor = res.data.list.build[0].unit;
              } else {
                _this.map_car.floor = res.data.list.build[0].unit[0].id; // 获取区域

                if (res.data.list.build[0].unit.length > 1) {
                  _this.map_car.select.region = res.data.list.build[0].unit[0].floor;
                }
              }
            }
          }
        }

        loading.close();
      }).catch(function (res) {
        loading.close();
      });
    },

    /**
     * 选择楼栋楼层区域
     * @param key
     */
    onChange: function onChange(key) {
      var _this2 = this;

      if (this.isSetAdd) {
        this.$confirm('您已修改过当前平面图并没有保存，确定要切换吗？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(function () {
          _this2.doChange(key);
        }).catch(function () {
          _this2.map_car.build = _this2.map_car.default.build;
          _this2.map_car.floor = _this2.map_car.default.floor;
          _this2.map_car.region = _this2.map_car.default.region;
        });
      } else {
        this.doChange(key);
      }
    },
    doChange: function doChange(key) {
      this.isOnCart = false;

      if (key === 'build') {
        this.map_car.select.floor = [];
        this.map_car.select.region = [];
        this.map_car.floor = 0;
        this.map_car.region = 0;

        for (var i = 0; i < this.map_car.select.build.length; i++) {
          if (this.map_car[key] === this.map_car.select.build[i].id) {
            if (this.map_car.select.build[i].unit.length > 1) {
              this.map_car.select.floor = this.map_car.select.build[i].unit;
            } else if (this.map_car.select.build[i].unit.length === 1 && this.map_car.select.build[i].unit[0].name) {
              this.map_car.select.floor = this.map_car.select.build[i].unit;
            }

            this.map_car.select.region = [];
            this.map_car.floor = 0;
            this.map_car.region = 0;
            break;
          }
        }
      } else if (key === 'floor') {
        this.map_car.select.region = [];
        this.map_car.region = 0;

        for (var _i = 0; _i < this.map_car.select.floor.length; _i++) {
          if (this.map_car[key] === this.map_car.select.floor[_i].id) {
            if (this.map_car.select.floor[_i].floor.length > 1) {
              this.map_car.select.region = this.map_car.select.floor[_i].floor;
            } else if (this.map_car.select.floor[_i].floor.length === 1 && this.map_car.select.floor[_i].floor[0].name) {
              this.map_car.select.region = this.map_car.select.floor[_i].floor;
            }

            this.map_car.region = 0;
            break;
          }
        }
      } else if (key === 'region') {} // 清空为默认值


      if (this.$refs['left_page']) this.$refs['left_page'].doBeforeDestroy();
      this.map_car.default = {
        build: this.map_car.build,
        floor: this.map_car.floor,
        region: this.map_car.region
      };
      this.allCartList = [];
      this.threeCartList = [];
      this.carIds = [];
      this.listData = [];
      this.imgPath = '';
      this.imgWidth = 0;
      this.imgHeight = 0;
      this.isCkickAddData = false;
      this.selectCar = false;
      this.isOnCart = false;
      this.cameraPositionZ = 0;
      this.room_status_color = [{
        room_status: -1,
        text: '非售',
        color: '#666666',
        show: true
      }, {
        room_status: 0,
        text: '待售',
        color: '#2878FF',
        show: true
      }, {
        room_status: [1, 2, 3],
        text: '已售',
        color: '#FF5722',
        show: true
      }];
      this.sceneChildrens = [];
      this.heartIf = false;
      this.isCarClick = true;
      this.planeId = 0;
      this.isSetAdd = false;
    },

    /**
     * 点击确定，需要生成当前条件的车位
     */
    onCart: function onCart() {
      var _this3 = this;

      this.heartIf = true;
      setTimeout(function () {
        _this3.heartIf = false;
      }, 1000);

      if (this.map_car.select.build.length && this.map_car.build || this.map_car.select.floor.length && this.map_car.floor || this.map_car.select.region.length && this.map_car.region) {
        this.isOnCart = true;
        var loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        carMapIndex({
          build_id: this.map_car.build,
          //楼栋
          unit_id: this.map_car.floor,
          //楼层
          floor_id: this.map_car.region //区域

        }, this.cate).then(function (res) {
          _this3.imgPath = '';
          var datas = [];
          var listIds = [],
              carIds = [];
          var list = res.data.info.map_info ? JSON.parse(res.data.info.map_info) : [];

          for (var i = 0; i < list.length; i++) {
            listIds.push(list[i].id);
          }

          _this3.listData = list;
          _this3.planeId = res.data.info.id;

          if (res.data.info[_this3.cate === 1 ? 'rooms' : 'cars'] && res.data.info[_this3.cate === 1 ? 'rooms' : 'cars'].length) {
            datas = res.data.info[_this3.cate === 1 ? 'rooms' : 'cars'].filter(function (item) {
              item.id_room = item.id + '_room_' + item.room;

              if (_this3.map_car.region) {
                return _this3.map_car.region === item.floor_id;
              } else if (_this3.map_car.floor) {
                return _this3.map_car.floor === item.unit_id;
              } else if (_this3.map_car.build) {
                return _this3.map_car.build === item.build_id;
              }
            });
          }

          _this3.allCartList = JSON.parse(JSON.stringify(datas));
          _this3.threeCartList = datas.filter(function (item) {
            carIds.push(item.id);
            return listIds.indexOf(item.id) < 0;
          });
          _this3.listTotal = list.length + _this3.threeCartList.length; //总数

          _this3.generatedCount = list.length; //已生成

          _this3.carIds = carIds;

          if (res.data.info.room_status_color) {
            _this3.room_status_color = JSON.parse(res.data.info.room_status_color);
          }

          _this3.$nextTick(function () {
            _this3.imgPath = res.data.info.url;

            if (_this3.imgPath) {
              setTimeout(function () {
                _this3.$refs['left_page'].doRefreshData(_this3.imgPath ? _this3.imgUrl + _this3.imgPath : '', list, true);
              }, 10);
            }
          });

          _this3.$refs['right_page'].addCart = res.data.info.add_car_config ? JSON.parse(res.data.info.add_car_config) : {
            x: 0,
            y: 0,
            width: 50,
            height: 50,
            radius: 12,
            rotationZ: 0,
            round: true,
            fontSize: 14,
            sort: 'left',
            isFont: false,
            //修改添加名称默认值为不显示
            fontColor: '#FFFFFF',
            ids: [],
            background_color: 1,
            xType: 'all',
            yType: 'all'
          };

          _this3.$refs['right_page'].onOper('setData', {
            x: 0,
            y: 0,
            width: 50,
            height: 50,
            radius: 25,
            rotationZ: 0,
            round: false
          });

          loading.close();
          _this3.isSetAdd = false;
        }).catch(function (res) {
          loading.close();
        });
      } else {
        this.$message({
          type: 'error',
          message: '请先选择楼栋或者楼层或者区域！'
        });
      }
    },
    updatamapIndex: function updatamapIndex() {
      var _this4 = this;

      carMapIndex({
        build_id: this.map_car.build,
        //楼栋
        unit_id: this.map_car.floor,
        //楼层
        floor_id: this.map_car.region //区域

      }, this.cate).then(function (res) {
        var datas = [];
        var carIds = [],
            listIds = [];
        var list = res.data.info.map_info ? JSON.parse(res.data.info.map_info) : [];

        for (var i = 0; i < list.length; i++) {
          listIds.push(list[i].id);
        }

        if (res.data.info[_this4.cate === 1 ? 'rooms' : 'cars'] && res.data.info[_this4.cate === 1 ? 'rooms' : 'cars'].length) {
          datas = res.data.info[_this4.cate === 1 ? 'rooms' : 'cars'].filter(function (item) {
            item.id_room = item.id + '_room_' + item.room;

            if (_this4.map_car.region) {
              return _this4.map_car.region === item.floor_id;
            } else if (_this4.map_car.floor) {
              return _this4.map_car.floor === item.unit_id;
            } else if (_this4.map_car.build) {
              return _this4.map_car.build === item.build_id;
            }
          });
        }

        _this4.allCartList = JSON.parse(JSON.stringify(datas));
        _this4.threeCartList = datas.filter(function (item) {
          carIds.push(item.id);
          return listIds.indexOf(item.id) < 0;
        });
        _this4.carIds = carIds;
      });
    },

    /**
     * 右边配置项上传图片成功调用
     * @param imgPath
     */
    onUpload: function onUpload(imgPath) {
      var _this5 = this;

      this.imgPath = '';
      this.$nextTick(function () {
        _this5.imgPath = imgPath;
        setTimeout(function () {
          _this5.$refs['left_page'].doRefreshData(_this5.imgPath ? _this5.imgUrl + _this5.imgPath : '', _this5.listData);
        }, 10);
      });
      this.$refs['right_page'].onOper('setData', {
        x: 0,
        y: 0,
        width: 50,
        height: 50,
        radius: 25,
        rotationZ: 0,
        round: false
      });
    },

    /**
     * 左右两边添加车位数据交互
     * @param data
     */
    doCkickAddData: function doCkickAddData(data) {
      try {
        if (data.key === 'three') {
          this.$refs['right_page'].onOper('addData', data.data);
        } else if (data.key === 'addCart') {
          this.$refs['left_page'].doList(data.data);
          this.isSetAdd = true;
          this.isCkickAddData = false;
        }
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * 左右两边修改车位数据交互
     * @param data
     */
    doCkickSetData: function doCkickSetData(data) {
      if (data.key === 'three') {
        this.$refs['right_page'].onOper('setData', data.data);
      } else if (data.key === 'setCart' || data.key === 'delCart' || data.key === 'dels_id' || data.key === 'sets_children') {
        this.isSetAdd = true;
        this.$refs['left_page'].onSetCart(data);
      }
    },

    /**
     * 保存数据
     * @param key
     */
    onSubmit: function onSubmit(key, allDelete) {
      var _this6 = this;

      // 保存
      if (key === 'submit') {
        this.$refs['left_page'].doGetList();
      } // 画面居中
      else if (key === 'reset') {
        this.$refs['left_page'].doReset(0, 0);
      } // 更新房源或者车位
      else if (key === 'updateCars') {
        var names = [],
            newListData = [],
            ids = [],
            newCarIds = [];

        for (var i = 0; i < this.listData.length; i++) {
          if (this.carIds.indexOf(this.listData[i].id) < 0) {
            names.push(this.listData[i].name);
            ids.push(this.listData[i].id);
          } else {
            newCarIds.push(this.listData[i].id);
            newListData.push(this.listData[i]);
          }
        }

        if (names.length) {
          this.isCarClick = false;
          var str = "<p style=' font-size: 16px;font-weight: bold;'>确定要删除吗？点击确定后只会在本地删除异常" + (this.cate === 1 ? '房源' : '车位') + '重新渲染，不会保存数据，如需保存请点击保存！</p>' + "<p style='margin-top:10px;max-height: 400px;overflow-y: auto'>" + (this.cate === 1 ? '房源' : '车位') + '名称：' + names.join(',') + '</p>';
          this.$confirm(str, '当前有' + names.length + '个' + (this.cate === 1 ? '房源' : '车位') + '异常', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            type: 'warning'
          }).then(function () {
            _this6.listData = newListData;
            _this6.carIds = newCarIds;

            _this6.$refs['left_page'].onSetCart({
              key: 'dels_id',
              ids: ids
            });
          }).finally(function () {
            _this6.isCarClick = true;
          });
        } else {
          this.$message({
            type: 'success',
            message: '当前所有' + (this.cate === 1 ? '房源' : '车位') + '没有异常！'
          });
        } // 更新车位分类数据


        carMapCarUp({
          room_ids: newCarIds,
          id: this.planeId
        }, this.cate).then(function (res) {}).catch(function (res) {
          _this6.$message({
            type: 'error',
            message: res.data.msg
          });
        });
      } // 框选车位
      else if (key === 'carClick') {
        this.selectCar = 'sets';
        this.isCarClick = true;
        this.$refs['right_page'].activeName = 'set';
      } else {
        if (this.imgPath) {
          var add_car_config = JSON.parse(JSON.stringify(this.$refs['right_page'].addCart));
          add_car_config.ids = [];
          carSetMap({
            build_id: this.map_car.build,
            //楼栋
            unit_id: this.map_car.floor,
            //楼层
            floor_id: this.map_car.region,
            //区域
            url: this.imgPath,
            //背景图
            map_info: key.data,
            //jsonn数据,
            room_ids: key.ids,
            room_status_color: this.room_status_color,
            add_car_config: add_car_config,
            plane_image_info: {
              width: this.imgWidth,
              height: this.imgHeight
            }
          }, this.cate).then(function (res) {
            _this6.$message({
              type: 'success',
              message: res.data.msg
            });

            _this6.isSetAdd = false;

            _this6.updatamapIndex();

            var allIds = _this6.allCartList.map(function (item) {
              return item.id;
            });

            if (allDelete) {
              _this6.doCkickSetData({
                key: 'dels_id',
                ids: allIds
              });

              _this6.generatedCount = 0;
            } // this.onCart();//修改保存不更新平面图

          }).catch(function (res) {});
        } else {
          this.$message({
            type: 'error',
            message: '请先上传背景图！'
          });
        }
      }
    },

    /**
     * 删除
     * @param key
     */
    onDropdownDel: function onDropdownDel(key) {
      var _this7 = this;

      this.isCarClick = false;

      if (key === 'all') {
        this.$confirm('确定要删除全部' + (this.cate === 1 ? '房源' : '车位') + '和背景图吗？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(function () {
          var add_car_config = JSON.parse(JSON.stringify(_this7.$refs['right_page'].addCart));
          add_car_config.ids = [];
          carSetMap({
            build_id: _this7.map_car.build,
            //楼栋
            unit_id: _this7.map_car.floor,
            //楼层
            floor_id: _this7.map_car.region,
            //区域
            url: '',
            //背景图
            map_info: [],
            //jsonn数据,
            room_ids: [],
            room_status_color: _this7.room_status_color,
            add_car_config: add_car_config,
            plane_image_info: {
              width: _this7.imgWidth,
              height: _this7.imgHeight
            }
          }, _this7.cate).then(function (res) {
            _this7.$message({
              type: 'success',
              message: res.data.msg
            });

            _this7.isSetAdd = false;

            _this7.onCart();
          });
        }).catch(function () {}).finally(function () {
          _this7.isCarClick = true;
        });
      } else if (key === 'allCar') {
        this.$confirm('确定要删除全部' + (this.cate === 1 ? '房源' : '车位') + '吗？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(function () {
          _this7.onSubmit({
            ids: [],
            data: []
          }, true);
        }).catch(function () {}).finally(function () {
          _this7.isCarClick = true;
        });
      } else if (key === 'selectDelCar') {
        this.$confirm('请在左边平面图中鼠标左键长按拖动框选' + (this.cate === 1 ? '房源' : '车位') + '实现框选删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(function () {
          _this7.selectCar = 'dels';
        }).catch(function () {}).finally(function () {
          _this7.isCarClick = true;
        });
      }
    }
  }
};