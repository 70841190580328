import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import vueSeamlessScroll from 'vue-seamless-scroll';
import CountDown from '@/components/countDown';
import currentTime from './components/currentTime.vue';
import newCountdown from './components/countdown.vue';
import CountTo from 'vue-count-to';
import { mapState, mapGetters } from 'vuex';
import MyScreen from '@/components/my-screen';
export default {
  name: 'ScreenRight',
  components: {
    vueSeamlessScroll: vueSeamlessScroll,
    CountDown: CountDown,
    currentTime: currentTime,
    newCountdown: newCountdown,
    CountTo: CountTo,
    MyScreen: MyScreen
  },
  props: {
    batchTimeData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    cardType: {
      // card显示户型图 list显示选房记录
      type: String,
      default: 'card'
    },
    listData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    projectInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 显示配置
    ledInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    ledConfInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 倒计时开始时间 结束时间
    beginTime: {
      type: Number,
      default: 0
    },
    endTime: {
      type: Number,
      default: 0
    },
    oldprice: {
      type: Number,
      default: 0
    },
    oldremainCount: {
      type: Number,
      default: 0
    },
    // 是否提前显示房源
    isBeginShow: {
      type: Boolean,
      default: false
    },
    eventId: {
      type: [Number, String],
      default: ''
    },
    content: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 当前时间内容
    times: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    batchText: {
      type: String,
      default: ''
    },
    batchCurrent: {
      type: String,
      default: ''
    },
    // 颜色配置
    allColor: {
      type: Object,
      default: function _default() {
        return {
          // 项目名称
          title: {
            color: '#ffffff'
          },
          // 当前时间
          show_now_time: {
            background_color: '#454343',
            title_color: '#ffffff',
            time_color: '#ff3131',
            time_background_color: '#ffffff'
          },
          // 项目倒计时
          show_event_time: {
            background_color: '#454343',
            title_color: '#ffffff',
            time_color: '#ff3131',
            time_background_color: '#ffffff'
          },
          // 销售额
          show_amount: {
            background_color: '#454343',
            title_color: '#ffffff',
            color: '#ffffff'
          },
          // 批次倒计时
          show_batch_time: {
            background_color: '#454343',
            title_color: '#ffffff',
            time_color: '#ff3131',
            time_background_color: '#ffffff'
          },
          // 可选房源
          show_online: {
            background_color: '#454343',
            color: '#ffffff'
          },
          // 选房记录
          show_count: {
            background_color: '#454343',
            user_background_color: '#3D3C3C',
            color: '#ffffff'
          }
        };
      }
    }
  },
  data: function data() {
    return {
      batch_text: '批次倒计时',
      countDown: 'countDown',
      scroll: 'vueSeamlessScroll',
      isShowCountDown: true,
      // 名字脱敏
      hideName: function hideName(name) {
        if (name && name.length == 2) {
          var userName = name.substring(0, 1) + '*'; // 截取name 字符串截取第一个字符，

          return userName;
        } else if (name && name.length == 3) {
          var _userName = name.substring(0, 1) + '*' + name.substring(2, 3); // 截取第一个和第三个字符


          return _userName;
        } else if (name && name.length > 3) {
          var _userName2 = name.substring(0, 1) + '*' + '*' + name.substring(3, name.length); // 截取第一个和大于第4个字符


          return _userName2;
        }
      },
      addCommas: function addCommas(val) {
        while (/(\d+)(\d{3})/.test(val.toString())) {
          val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
        }

        return val;
      },
      // 滚动配置
      classOption: {
        step: 0.5,
        // 数值越大速度滚动越快
        limitMoveNum: 8,
        // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        openWatch: true,
        // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000,
        // 单步运动停止的时间(默认值1000ms)
        autoPlay: true // 自动播放

      },
      classOptionC: {
        step: 0.5,
        // 数值越大速度滚动越快
        limitMoveNum: 3,
        // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        openWatch: true,
        // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000,
        // 单步运动停止的时间(默认值1000ms)
        autoPlay: true // 自动播放

      }
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    allData: function allData(state) {
      return state.screen.allDatas;
    },
    refreshCountDown: function refreshCountDown(state) {
      return state.screen.refreshCountDown;
    }
  })), mapGetters(['customSetting'])), {}, {
    baseUrl: function baseUrl() {
      return this.imgUrl;
    }
  }),
  watch: {
    'allData.roomLog': {
      handler: function handler(newVal, oldVal) {
        var _this = this;

        if (newVal == null) {
          return;
        } else {
          if (newVal && newVal.length == 9) {
            this.scroll = null;
            setTimeout(function () {
              _this.scroll = 'vueSeamlessScroll';
            }, 100);
          }
        }
      },
      immediate: true
    },
    refreshCountDown: {
      handler: function handler(newVal, oldVal) {
        var _this2 = this;

        this.isShowCountDown = false;
        this.$nextTick(function () {
          _this2.isShowCountDown = true;
        });
      }
    }
  },
  methods: {
    // 子组件更新批次倒计时名称
    uptoDate: function uptoDate(text) {
      console.log(text);
      this.batch_text = text;
    },
    // 倒计时结束
    countDownOver: function countDownOver() {
      var _this3 = this;

      this.isShowCountDown = false;
      this.$nextTick(function () {
        _this3.isShowCountDown = true;
      });
      this.$emit('countDownOver');
    },
    reloadCom: function reloadCom() {
      console.log('reload com');
    }
  }
};