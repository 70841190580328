import request from '@/utils/request';
export default {
  // 获取列表
  userList: function userList(data) {
    return request({
      url: '/register/list',
      method: 'post',
      data: data
    });
  },
  //  新增客户
  userAdd: function userAdd(data) {
    return request({
      url: '/register/add',
      method: 'post',
      data: data
    });
  },
  //  导出认筹表单
  exportList: function exportList(data) {
    return request({
      url: '/register/export',
      method: 'post',
      data: data
    });
  },
  //  导出诚意登记客户总表
  exportAll: function exportAll(data) {
    return request({
      url: '/register/exportAll',
      method: 'post',
      data: data
    });
  },

  /** 装户分析 */
  analyse: function analyse(data) {
    return request({
      url: 'register/analyse',
      method: 'POST',
      data: data
    });
  },

  /** 车位装户分析 */
  carAnalyse: function carAnalyse(data) {
    return request({
      url: 'register/carAnalyse',
      method: 'POST',
      data: data
    });
  },

  /** 房源意向统计 */
  exportCollect: function exportCollect(params) {
    return request({
      url: 'register/exportCollect',
      method: 'GET',
      params: params
    });
  },

  /** 客户意向统计 */
  userCollect: function userCollect(params) {
    return request({
      url: 'register/userCollect',
      method: 'GET',
      params: params
    });
  },

  /** 导出认筹日报 */
  registerDaily: function registerDaily(data) {
    return request({
      url: 'register/Daily',
      method: 'post',
      data: data
    });
  },
  // 转入开盘客户
  intoList: function intoList(data) {
    return request({
      url: '/register/into',
      method: 'post',
      data: data
    });
  },
  delAll: function delAll(data) {
    return request({
      url: '/register/delAll',
      method: 'post',
      data: data
    });
  },
  // 转入开盘客户
  userEdit: function userEdit(data) {
    return request({
      url: '/register/edit',
      method: 'post',
      data: data
    });
  },
  // 转入开盘客户
  userDel: function userDel(data) {
    return request({
      url: '/register/del',
      method: 'post',
      data: data
    });
  },
  // 打印认筹单
  userPrint: function userPrint(data) {
    return request({
      url: '/register/print',
      method: 'post',
      data: data
    });
  },
  // 打印报名签约单
  regPrint: function regPrint(data) {
    return request({
      url: '/register/regPrint',
      method: 'post',
      data: data
    });
  },
  // 打印认筹签约单
  idePrint: function idePrint(data) {
    return request({
      url: '/register/idePrint',
      method: 'post',
      data: data
    });
  },
  // 打印升筹签约单
  raiPrint: function raiPrint(data) {
    return request({
      url: '/register/raiPrint',
      method: 'post',
      data: data
    });
  },
  // 下载图片
  downloadImg: function downloadImg(params) {
    return request({
      url: '/register/down',
      method: 'get',
      params: params
    });
  },
  // 批量下载图片
  downloadImgMass: function downloadImgMass(data) {
    return request({
      url: '/register/downAll',
      method: 'POST',
      data: data
    });
  },
  // 预约
  appointmentDownload: function appointmentDownload(data) {
    return request({
      url: '/register/timeDown',
      method: 'POST',
      data: data
    });
  },
  // 批量从阿里云下载图片
  registerTimeData: function registerTimeData(data) {
    return request({
      url: '/register/timeData',
      method: 'POST',
      data: data
    });
  },
  // 获取下载数据
  downloadAppointmentDownload: function downloadAppointmentDownload(params) {
    return request({
      url: '/register/timeDown',
      method: 'get',
      params: params
    });
  },
  // 获取下载数据
  getInfo: function getInfo(params) {
    return request({
      url: '/register/info',
      method: 'get',
      params: params
    });
  },
  // 获取下载数据
  ossUpload: function ossUpload(url, data) {
    return request({
      url: url,
      method: 'post',
      data: data
    });
  },
  removeLogin: function removeLogin(data) {
    return request({
      url: '/register/removeLogin',
      method: 'POST',
      data: data
    });
  },
  CaptchaInfo: function CaptchaInfo(data) {
    return request({
      url: '/register/CaptchaInfo',
      method: 'POST',
      data: data
    });
  },
  admin: function admin(params) {
    return request({
      url: '/register/admin',
      method: 'GET',
      params: params
    });
  },
  sendeas: function sendeas(data) {
    return request({
      url: '/register/sendEas',
      method: 'POST',
      data: data
    });
  }
};