import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { AuditIde0, AuditIde1, AuditIde2, identifyAuditRejectAgain, identifyAuditAgainRejected } from '@/api/sincerityRegister';
import { exportAuditStatus0, exportAuditStatus1, exportAuditStatus2, auditDetail } from '@/api/audit';
import moment from 'moment';
import { adminParameter } from '@/api/public';
import userRegister from '../components/user-register.vue';
import { mapGetters } from 'vuex';
import toolReviewMixin from '../toolReviewMixin';
import switchBtn from './components/switchBtn';
export default {
  name: 'ReviewDetails',
  components: {
    userRegister: userRegister,
    switchBtn: switchBtn
  },
  mixins: [toolReviewMixin],
  props: {
    row: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      regData: {},
      ide_attach_img_arr: [],
      ide_img_arr: [],
      activeName: '0',
      // 审核内容
      statusInfo: [],
      // 审核信息
      auditInfo: [],
      index: 1,
      info: {},
      //  是否显示填写附表按钮
      is_button: true,
      // 是否填写附表
      showChangeSchedule: false,
      // 打开弹窗
      isTips: false,
      // 驳回原因
      textarea: '',
      // 上次驳回原因
      isReason: false,
      // 判断 审核方式
      types: 'ide1',
      // 驳回原因数据
      turnInfo: '',
      // 顶部div高度
      divHeight: 0,
      // 顶部button 判断
      intoInfo: {},
      // 日志info
      infoTips: [],
      checked: true,
      // saasSetting
      saasSetting: {
        audit: {}
      } // // 审核附表图片
      // attach_img_arr: [],
      // // 客户登记
      // img_arr: []

    };
  },
  computed: _objectSpread({}, mapGetters(['roles'])),
  created: function created() {
    this.getDataInfo();
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.divHeight = _this.$refs.topDiv.offsetHeight;
    });
  },
  methods: {
    // 打开驳回弹窗
    handleShowDialog: function handleShowDialog(type) {
      this.retrunType = type;
      this.isTips = true;
    },
    // 确定驳回
    handleChange: function handleChange() {
      this.handleTurnDown(this.retrunType, 'reject');
    },
    // 获取页面数据
    getDataInfo: function getDataInfo() {
      var _this2 = this;

      // let types = this.$route.query.status;
      var row = this.toolReviewRow;
      var types = row.types;
      this.types = types;
      adminParameter().then(function (res) {
        if (res.status === 200) {
          var audit = res.data.audit;
          _this2.saasSetting = res.data;

          if (types === 'ide0') {
            exportAuditStatus0({
              id: row.id
            }).then(function (res) {
              if (res.status === 200) {
                var _res$data = res.data,
                    auditInfo = _res$data.auditInfo,
                    info = _res$data.info,
                    collect = _res$data.collect,
                    collect_room_cate = _res$data.collect_room_cate;
                _this2.statusInfo = _this2.getInfoData(info.dataList, collect, collect_room_cate);
                _this2.auditInfo = auditInfo;
                _this2.info = info.logList;
                _this2.turnInfo = info.turnInfo;
                _this2.intoInfo = info.intoInfo;
                _this2.adminInfo = info.adminInfo;
                _this2.createInfo = info.createInfo;
                _this2.is_button = _this2.getScheduleType(info.dataList); // 客户登记表 审核附表 图片

                _this2.ide_attach_img_arr = info.attach_img_arr;
                _this2.ide_img_arr = info.img_arr;

                _this2.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);

                _this2.getList();

                _this2.doShowParent(); // 判断是否显示填写附表


                if (audit.register_schedule == 1) {
                  _this2.showChangeSchedule = true;
                }
              }
            });
          } else if (types === 'ide1') {
            exportAuditStatus1({
              id: row.id
            }).then(function (res) {
              if (res.status === 200) {
                var _res$data2 = res.data,
                    auditInfo = _res$data2.auditInfo,
                    info = _res$data2.info,
                    collect = _res$data2.collect,
                    collect_room_cate = _res$data2.collect_room_cate;
                _this2.statusInfo = _this2.getInfoData(info.dataList, collect, collect_room_cate);
                _this2.auditInfo = auditInfo;
                _this2.info = info.logList;
                _this2.turnInfo = info.turnInfo;
                _this2.intoInfo = info.intoInfo;
                _this2.createInfo = info.createInfo;
                _this2.adminInfo = info.adminInfo;
                _this2.is_button = _this2.getScheduleType(info.dataList); // 客户登记表 审核附表 图片

                _this2.ide_attach_img_arr = info.attach_img_arr;
                _this2.ide_img_arr = info.img_arr;

                _this2.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);

                _this2.getList();

                _this2.doShowParent(); // 判断是否显示填写附表


                if (audit.register_schedule == 1 && audit.register_schedule_admin == 1) {
                  _this2.showChangeSchedule = true;
                }
              }
            });
          } else if (types === 'ide2') {
            exportAuditStatus2({
              id: row.id
            }).then(function (res) {
              if (res.status === 200) {
                var _res$data3 = res.data,
                    auditInfo = _res$data3.auditInfo,
                    info = _res$data3.info,
                    collect = _res$data3.collect,
                    collect_room_cate = _res$data3.collect_room_cate;
                _this2.statusInfo = _this2.getInfoData(info.dataList, collect, collect_room_cate);
                _this2.auditInfo = auditInfo;
                _this2.info = info.logList;
                _this2.turnInfo = info.turnInfo;
                _this2.intoInfo = info.intoInfo;
                _this2.createInfo = info.createInfo;
                _this2.userInfo = info.userInfo;
                _this2.adminInfo = info.adminInfo;
                _this2.is_button = _this2.getScheduleType(info.dataList);
                _this2.ide_attach_img_arr = info.attach_img_arr;
                _this2.ide_img_arr = info.img_arr; // 客户登记表 审核附表 图片

                _this2.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);

                _this2.getList();

                _this2.doShowParent(); // 判断是否显示填写附表
                // 附表需要填写&&复审人填写


                if (audit.register_schedule == 1 && audit.register_schedule_admin == 2) {
                  _this2.showChangeSchedule = true;
                } // 初审人填写
                // else if (audit.register_schedule_admin === 1) {
                //     this.showChangeSchedule = true
                // }

              }
            });
          }

          if (_this2.roles.includes('admin') || _this2.roles.includes('identifyaudit/auditdetail')) {
            // 获取客户登记表数据
            auditDetail({
              id: row.id
            }).then(function (res) {
              if (res.status === 200) {
                var _res$data$data$reg = res.data.data.reg,
                    reg_adminInfo = _res$data$data$reg.reg_adminInfo,
                    reg_createInfo = _res$data$data$reg.reg_createInfo,
                    reg_intoInfo = _res$data$data$reg.reg_intoInfo,
                    reg_audit_info = _res$data$data$reg.reg_audit_info,
                    reg_logList = _res$data$data$reg.reg_logList,
                    reg_turnInfo = _res$data$data$reg.reg_turnInfo,
                    reg_dataList = _res$data$data$reg.reg_dataList,
                    reg_attach_img_arr = _res$data$data$reg.reg_attach_img_arr,
                    reg_userInfo = _res$data$data$reg.reg_userInfo,
                    reg_img_arr = _res$data$data$reg.reg_img_arr;
                var _res$data$data = res.data.data,
                    collect = _res$data$data.collect,
                    collect_room_cate = _res$data$data.collect_room_cate;
                _this2.regData = {
                  adminInfo: reg_adminInfo,
                  createInfo: reg_createInfo,
                  intoInfo: reg_intoInfo,
                  auditInfo: reg_audit_info,
                  info: reg_logList,
                  turnInfo: reg_turnInfo,
                  userInfo: reg_userInfo,
                  statusInfo: _this2.getInfoData(reg_dataList, collect, collect_room_cate),
                  attach_img_arr: reg_attach_img_arr,
                  img_arr: reg_img_arr
                };

                _this2.doShowParent(true);

                if (_this2.index === 3) {
                  _this2.getList();
                }
              }
            });
          }
        }
      });
    },
    // 获取
    getList: function getList() {
      var infoTips = [];
      var status = this.auditInfo.status;
      var types = this.row.types;
      var createInfo = this.createInfo;
      var userInfo = this.userInfo;
      var adminInfo = this.adminInfo;
      var intoInfo = this.intoInfo;

      if (this.index === 3) {
        status = this.regData.auditInfo.status;
        createInfo = this.regData.createInfo;
        userInfo = this.regData.userInfo;
        adminInfo = this.regData.adminInfo;
        intoInfo = this.regData.intoInfo;
      } // 待审核


      if (status === 1) {
        infoTips = [[{
          name: '客户提交时间',
          value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
        }, {
          name: '客户姓名',
          value: createInfo.name
        }, {
          name: '登录手机号',
          value: this.getTel(createInfo.tel)
        }]];
      } // 已驳回、已通过


      if (status === -2 || status === 2) {
        infoTips = [[{
          name: '客户提交时间',
          value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
        }, {
          name: '客户姓名',
          value: createInfo.name
        }, {
          name: '登录手机号',
          value: this.getTel(createInfo.tel)
        }], [{
          name: '审核时间',
          value: moment(adminInfo.audit_at * 1000).format('YYYY-MM-DD HH:mm')
        }, {
          name: '审核人',
          value: adminInfo.name
        }]];
      } // 复审


      if (types === 'ide2') {
        // 待审核
        if (status === 1) {
          // infoTips = [
          //   [
          //     { name: '客户提交时间', value: moment(userInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '客户姓名', value: userInfo.name },
          //     { name: '登录手机号', value: this.getTel(userInfo.tel) }
          //   ],
          //   [
          //     { name: '初审通过时间', value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '审核人', value: createInfo.name }
          //   ]
          // ]
          infoTips = [[{
            name: '客户提交时间',
            value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
          }, {
            name: '客户姓名',
            value: createInfo.name
          }, {
            name: '登录手机号',
            value: this.getTel(createInfo.tel)
          }]];
        } // 已驳回、已通过


        if (status === -2 || status === 2) {
          // infoTips = [
          //   [
          //     { name: '客户姓名', value: userInfo.name },
          //     { name: '客户提交时间', value: moment(userInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '登录手机号', value: this.getTel(userInfo.tel) }
          //   ],
          //   [
          //     { name: '初审通过时间', value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '审核人', value: createInfo.name }
          //   ],
          //   [
          //     { name: '复审审核时间', value: moment(adminInfo.audit_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '审核人', value: adminInfo.name }
          //   ]
          // ]
          infoTips = [[{
            name: '客户姓名',
            value: createInfo.name
          }, {
            name: '客户提交时间',
            value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
          }, {
            name: '登录手机号',
            value: this.getTel(createInfo.tel)
          }], [{
            name: '审核时间',
            value: moment(adminInfo.audit_at * 1000).format('YYYY-MM-DD HH:mm')
          }, {
            name: '审核人',
            value: adminInfo.name
          }]];
        }
      }

      if (this.intoInfo != undefined) {
        if (this.intoInfo.is_into) {
          infoTips.push([// { name: '转入开盘客户时间', value: moment(intoInfo.into_time * 1000).format('YYYY-MM-DD HH:mm') },
          {
            name: '操作人',
            value: intoInfo.name
          }]);
        }
      }

      this.infoTips = infoTips;

      if (this.index === 3) {
        this.regData.infoTips = infoTips;
      }
    },
    // 处理数据
    getInfoData: function getInfoData(data, collect, collect_room_cate) {
      data.forEach(function (item) {
        item.typeNumber = 0;
        item.typeUserNumber = 0; // 其他类型

        if (item.type === 0) {
          item.forms.forEach(function (forms) {
            if (forms.fill_in_type === 2) {
              item.typeNumber = item.typeNumber + 1;
            } else if (forms.fill_in_type === 1) {
              item.typeUserNumber = item.typeUserNumber + 1;
            }

            if (forms.form_type === 'radio') {
              if (forms.normal_result.value !== '') {
                // 婚姻状态
                if (forms.owner_type === 6) {
                  var index = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 8 && forms.children[index].name === '未婚') {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  }); //   付款方式
                } else if (forms.owner_type === 7) {
                  var _index = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });

                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 9 && (forms.children[_index].name === '全款' || forms.children[_index].name === '一次性付款')) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                } else if (forms.owner_type === 10) {
                  var _index2 = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });

                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 12 && forms.children[_index2].owner_type === 11) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                } else if (forms.owner_type === 103) {
                  // 100 首付金额 101 首付金额230万  102首付金额350万 103认筹金是否本人支付  104本人支付 105他人支付 106代付承诺书
                  var _index3 = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });

                  item.forms.forEach(function (_forms_) {
                    if (_forms_.owner_type === 106) _forms_.is_show_tp = forms.children[_index3].owner_type == 104 ? 1 : 0;
                  });
                }
              }
            }
          });
        } else if (item.owners && item.type === 1) {
          item.owners.forEach(function (owners) {
            owners.forms.map(function (forms) {
              if (forms.fill_in_type === 2) {
                item.typeNumber = item.typeNumber + 1;
              } else {
                item.typeUserNumber = item.typeUserNumber + 1;
              }
            });
          });
        } else if (item.banks && item.type === 2) {
          item.banks.forEach(function (owners) {
            owners.forms.map(function (forms) {
              if (forms.fill_in_type === 2) {
                item.typeNumber = item.typeNumber + 1;
              } else {
                item.typeUserNumber = item.typeUserNumber + 1;
              }
            });
          });
        }
      });
      return data;
    },
    // 查看驳回原因
    checkReason: function checkReason() {
      this.isReason = true;
    },
    // 判断是否出现填写附表
    getScheduleType: function getScheduleType(data) {
      var type = true;
      data.forEach(function (item) {
        // 其他类型
        if (item.type === 0) {
          item.forms.map(function (forms) {
            if (forms.fill_in_type === 2) {
              if (forms.normal_result.value !== '') {
                type = false;
              }
            }
          }); //  产权人
        } else if (item.owners && item.type === 1) {
          item.owners.forEach(function (owners) {
            owners.forms.map(function (forms) {
              if (forms.fill_in_type === 2) {
                if (forms.owner_result.value !== '') {
                  type = false;
                }
              }
            });
          });
        } // 银行资料
        else if (item.banks && item.type === 2) {
          item.banks.forEach(function (banks) {
            banks.forms.map(function (forms) {
              if (forms.fill_in_type === 2) {
                if (forms.bank_result.value !== '') {
                  type = false;
                }
              }
            });
          });
        }
      });
      return true;
    },
    tabClick: function tabClick(tab) {
      if (tab == 2) {
        this.CHANGE_ALL_IMG([this.regData.attach_img_arr, this.regData.img_arr]);
      } else {
        this.CHANGE_ALL_IMG([this.ide_attach_img_arr, this.ide_img_arr]);
      }

      this.CHANGE_IMG_PREVIEW(false);
      this.CHANGE_BTN_TYPE(tab == 2 ? 0 : tab);

      if (tab == 0) {
        this.index = 1;
      } else if (tab == 1) {
        this.index = 2;
      } else if (tab == 2) {
        this.index = 3;
        this.getInfoData(this.regData.statusInfo);
        this.getList();
      }

      this.getInfoData(this.statusInfo);
    },
    // 跳转去填写附表
    handleSchedule: function handleSchedule() {
      var row = this.toolReviewRow;
      row.id = this.auditInfo.id;
      this.CHANGE_ROW(row);
      this.CHANGE_DRAWER_TYPE(0); // this.$router.push({
      //   name: 'Schedule',
      //   query: { id: this.auditInfo.id, status: this.row.types }
      // })
    },
    // 修改登记变信息
    handleEdit: function handleEdit() {
      var row = this.toolReviewRow;
      row.id = this.auditInfo.id;
      row.index = this.index;
      this.CHANGE_ROW(row);
      this.CHANGE_DRAWER_TYPE(2); // this.$router.push({
      //   name: 'EditReviewDetails',
      //   query: { id: this.auditInfo.id, status: this.row.types, types: this.index },
      // })
    },
    // 驳回审核,提交审核
    handleTurnDown: function handleTurnDown(type, operation) {
      var _this3 = this;

      var types = this.toolReviewRow.types;

      if (this.textarea === '' && (type === 'return' || type === 'refuse' || type === 'repulse')) {
        this.$message({
          type: 'error',
          message: '驳回原因必填'
        });
        return; // this.postSave(type)
      } else if (this.textarea !== '' && type === 'return') {
        this.postSave(type);
      } else if (this.textarea !== '' && type === 'refuse') {
        this.postSave(type);
      } else if (this.textarea !== '' && type === 'repulse') {
        this.postSave(type);
      } else if (this.textarea !== '' && type === 'return2') {
        this.doReturnRefuse2('return');
      } else if (this.textarea !== '' && type === 'refuse2') {
        this.doReturnRefuse2('refuse');
      } else {
        var text = '';

        if (this.roles.includes('identifyaudit/auditcomplexlist')) {
          text = types === 'ide1' ? '是否确认通过审核？' : '是否确认通过审核？';
        } else {
          text = '是否确认通过审核？';
        }

        if (operation == 'reject') {
          text = '是否确认驳回？';
        }

        this.$confirm(text, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this3.postSave(type);
        }).catch(function () {});
      }
    },
    postSave: function postSave(type) {
      var _this4 = this;

      var data = {
        id: parseInt(this.toolReviewRow.id),
        type: type,
        err_note: this.textarea,
        next: this.checked ? 1 : 0
      };
      var types = this.toolReviewRow.types;

      if (types === 'ide0') {
        AuditIde0(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;

            _this4.$message({
              type: 'success',
              message: res.data.msg
            });

            if (_this4.checked && JSON.stringify(next) !== '{}') {
              var row = _this4.toolReviewRow;
              row.id = next.id;

              _this4.CHANGE_ROW(row);

              _this4.CHANGE_DRAWER_TYPE(3);

              setTimeout(function () {
                _this4.CHANGE_DRAWER_TYPE(1);
              }, 300); // this.$router.push({
              //   name: 'ReviewDetails',
              //   query: { id: next.id, status: next.types },
              // })
            } else {
              if (_this4.checked && _this4.type === 'commit') {
                setTimeout(function () {
                  _this4.$message({
                    type: 'warning',
                    message: '已经是最后一条了'
                  });
                }, 1000);
              }
            }

            _this4.isTips = false;

            _this4.getDataInfo(); // 刷新列表页面


            _this4.$emit('handleRefresh');
          }
        });
      } else if (types === 'ide1') {
        if (data.err_note == '') {
          this.$message.error('驳回理由必填！');
          return;
        }

        AuditIde1(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;

            _this4.$message({
              type: 'success',
              message: res.data.msg
            });

            if (_this4.checked && JSON.stringify(next) !== '{}') {
              var row = _this4.toolReviewRow;
              row.id = next.id;

              _this4.CHANGE_ROW(row);

              _this4.CHANGE_DRAWER_TYPE(3);

              setTimeout(function () {
                _this4.CHANGE_DRAWER_TYPE(1);
              }, 300); // this.$router.push({
              //   name: 'ReviewDetails',
              //   query: { id: next.id, status: next.types },
              // })
            } else {
              if (_this4.checked && _this4.type === 'commit') {
                setTimeout(function () {
                  _this4.$message({
                    type: 'warning',
                    message: '已经是最后一条了'
                  });
                }, 1000);
              }
            }

            _this4.isTips = false;

            _this4.getDataInfo(); // 刷新列表页面


            _this4.$emit('handleRefresh');
          }
        });
      } else if (types === 'ide2') {
        AuditIde2(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;

            _this4.$message({
              type: 'success',
              message: res.data.msg
            });

            if (_this4.checked && JSON.stringify(next) !== '{}') {
              var row = _this4.toolReviewRow;
              row.id = next.id;

              _this4.CHANGE_ROW(row);

              _this4.CHANGE_DRAWER_TYPE(3);

              setTimeout(function () {
                _this4.CHANGE_DRAWER_TYPE(1);
              }, 300); // this.$router.push({
              //   name: 'ReviewDetails',
              //   query: { id: next.id, status: next.types },
              // })
            } else {
              if (_this4.checked && _this4.type === 'commit') {
                setTimeout(function () {
                  _this4.$message({
                    type: 'warning',
                    message: '已经是最后一条了'
                  });
                }, 1000);
              }
            }

            _this4.isTips = false;

            _this4.getDataInfo(); // 刷新列表页面


            _this4.$emit('handleRefresh');
          }
        });
      }
    },
    // 审核通过后再次驳回和再次驳回并拒绝提交
    doReturnRefuse2: function doReturnRefuse2(type) {
      var _this5 = this;

      var data = {
        id: parseInt(this.toolReviewRow.id),
        type: type,
        err_note: this.textarea
      };

      if (type === 'return') {
        identifyAuditAgainRejected(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;

            _this5.$message({
              type: 'success',
              message: res.data.msg
            });

            _this5.isTips = false;

            _this5.getDataInfo(); // 刷新列表页面


            _this5.$emit('handleRefresh');
          }
        });
      } else if (type === 'refuse') {
        identifyAuditRejectAgain(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;

            _this5.$message({
              type: 'success',
              message: res.data.msg
            });

            _this5.isTips = false;

            _this5.getDataInfo(); // 刷新列表页面


            _this5.$emit('handleRefresh');
          }
        });
      }
    },

    /**
     * 父级影响显示隐藏
     * @param parent_id  当前id（父级）
     * @param parent_values 当前选项ids
     * @param isReg 是否是客户登记表数据
     * @param isShowParent 是否开始判断父级隐藏
     */
    doShowParent: function doShowParent(isReg, isShowParent, parent_id, parent_values) {
      var _this6 = this;

      if (isShowParent) {
        var forms = isReg ? JSON.parse(JSON.stringify(this.regData.statusInfo)) : JSON.parse(JSON.stringify(this.statusInfo));

        for (var i = 0; i < forms.length; i++) {
          // 产权人或者银行资料
          if (forms[i].type === 1 || forms[i].type === 2) {
            // 1为产权人 2为银行资料
            var ownerBankKey = forms[i].type == 1 ? 'owner' : 'bank';

            for (var l = 0; l < forms[i][ownerBankKey + 's'].length; l++) {
              for (var a = 0; a < forms[i][ownerBankKey + 's'][l].forms.length; a++) {
                var item = forms[i][ownerBankKey + 's'][l].forms[a];

                if (parent_id === item.parent_pid && item.parent_pid !== 0) {
                  var parent_ids = [];

                  if (item.parent_id) {
                    parent_ids = item.parent_id.split(',');
                  }

                  var parent_cause_show = false;

                  for (var c = 0; c < parent_ids.length; c++) {
                    if (parent_values.indexOf(parent_ids[c]) >= 0) {
                      parent_cause_show = true;
                    }
                  } // 隐藏时清空之前的值


                  if (!parent_cause_show) {
                    item.normal_result.value = '';
                    item[ownerBankKey + '_result'].value = '';
                    item.normal_result.value2 = '';
                    item[ownerBankKey + '_result'].value2 = '';
                  }

                  item.parent_cause_show = parent_cause_show;
                } else if (item.parent_pid === 0) {
                  item.parent_cause_show = true;
                }

                forms[i][ownerBankKey + 's'][l].forms[a] = item;
              }
            }
          } // 其他类型
          else {
            for (var _a = 0; _a < forms[i].forms.length; _a++) {
              var _item2 = forms[i].forms[_a];

              if (parent_id === _item2.parent_pid && _item2.parent_pid !== 0) {
                var _parent_ids = [];

                if (_item2.parent_id) {
                  _parent_ids = _item2.parent_id.split(',');
                }

                var _parent_cause_show = false;

                for (var _c = 0; _c < _parent_ids.length; _c++) {
                  if (parent_values.indexOf(_parent_ids[_c]) >= 0) {
                    _parent_cause_show = true;
                  }
                } // 隐藏时清空之前的值


                if (!_parent_cause_show) {
                  _item2.normal_result.value = '';
                  _item2.normal_result.value2 = '';
                }

                _item2.parent_cause_show = _parent_cause_show;
              } else if (_item2.parent_pid === 0) {
                _item2.parent_cause_show = true;
              }

              forms[i].forms[_a] = _item2;
            }
          }
        }

        if (isReg) {
          this.regData.statusInfo = JSON.parse(JSON.stringify(forms));
        } else {
          this.statusInfo = JSON.parse(JSON.stringify(forms));
        }
      } else {
        var formData = isReg ? JSON.parse(JSON.stringify(this.regData.statusInfo)) : JSON.parse(JSON.stringify(this.statusInfo));
        formData.forEach(function (item) {
          if (item.type != 1 && item.type != 2) {
            item.forms.forEach(function (_item) {
              var selectId = [];

              if (_item.form_type == '置业顾问') {
                // 置业顾问
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  // 默认选择
                  selectId = _item.admins[0].id.toString().split(',');
                }

                _this6.doShowParent(isReg, true, _item.id, selectId);
              } else if (_item.form_type == 'radio' || _item.form_type == 'select') {
                // 单选 || 多选
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  if (_item.form_type == 'radio') {
                    selectId = _item.children[0].id.toString().split(',');
                  } else {
                    _item.normal_result.value = selectId.join(',');
                  }
                }

                _this6.doShowParent(isReg, true, _item.id, selectId);
              }
            });
          }
        });
      }
    }
  }
};