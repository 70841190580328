import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import inputText from '@/components/inputText';
import { roomDetails, roomEdit } from '@/api/roomCar';
import { adminParameter, projectConfigure } from '@/api/public';
import GMainUpload from '../../components/my-upload';
import utils from '@/utils/utils';
export default {
  name: 'HouseEdit',
  components: {
    inputText: inputText,
    GMainUpload: GMainUpload
  },
  data: function data() {
    return {
      isPC: utils.isPC(),
      // 页面全部数据
      dataForm: {
        name: ''
      },
      form: {
        room: {
          status: 1
        }
      },
      // 增加产权人button
      is_addButton: true,
      // 表单验证
      rules: {
        'user.room_cate_id': [{
          required: true,
          message: '请选择户型',
          trigger: 'blur'
        }]
      },
      // 获取公共数据
      activity: {},
      projectList: {},
      listTypes: 'picture-card',
      images: [],
      // 0不开启 1以客户配比 2以房源配比
      matching_order: 0
    };
  },
  created: function created() {
    this.getInfo();
    this.getUnit();
  },
  methods: {
    // 上传图片返回值
    handleRoomImg: function handleRoomImg(url) {
      this.form.room.images = url.join(',');
    },
    // 公共数据
    getUnit: function getUnit() {
      var _this = this;

      adminParameter().then(function (res) {
        var data = res.data,
            _res$data = res.data,
            event = _res$data.event,
            list = _res$data.list;
        _this.project = data; // 活动场次批次控制

        _this.event = event; // 全局配置

        _this.projectList = list; // 是否开启配比认购

        _this.matching_order = res.data.matching_order;
      });
      projectConfigure().then(function (res) {
        var list = res.data.list;
        _this.activity = list;
      });
    },
    // 获取详情数据
    getInfo: function getInfo() {
      var _this2 = this;

      roomDetails({
        id: this.$route.query.id
      }).then(function (res) {
        var list = res.data.list;
        _this2.form.room = list.room; //  不等于空才执行

        if (list.room.images !== '') {
          list.room.images.split(',').forEach(function (item) {
            _this2.images.push({
              url: _this2.imgUrl + item
            });
          });
        }
      });
    },
    // 提交修改
    handleSave: function handleSave() {
      var _this3 = this;

      // if (this.projectList.room_info_price_type === 1) {
      //     if (this.form.room.old_total_price === '') {
      //         this.$message({
      //             type: 'error',
      //             message: '总价现价必填'
      //         });
      //         utils.jumpFun('jump1')
      //         return;
      //     }
      //
      //     if (this.form.room.old_unit_price === '') {
      //         this.$message({
      //             type: 'error',
      //             message: '单价现价必填'
      //         });
      //         utils.jumpFun('jump2')
      //         return;
      //     }
      //
      // } else if (this.projectList.room_info_price_type === 2) {
      //     if (this.form.room.old_total_price === '') {
      //
      //         this.$message({
      //             type: 'error',
      //             message: '总价原价必填'
      //         });
      //         utils.jumpFun('jump3')
      //         return;
      //     }
      //
      //     if (this.form.room.discount_total_price === '') {
      //
      //         this.$message({
      //             type: 'error',
      //             message: '总价优惠价必填'
      //         });
      //         utils.jumpFun('jump4')
      //         return;
      //     }
      //
      //     if (this.form.room.old_unit_price === '') {
      //
      //         this.$message({
      //             type: 'error',
      //             message: '单价原价必填'
      //         });
      //         utils.jumpFun('jump5')
      //         return;
      //     }
      //
      //     if (this.form.room.discount_price === '') {
      //
      //         this.$message({
      //             type: 'error',
      //             message: '单价优惠价必填'
      //         });
      //         utils.jumpFun('jump6')
      //         return;
      //     }
      //
      // } else {
      //
      //     if (this.form.room.old_total_price === '') {
      //
      //         this.$message({
      //             type: 'error',
      //             message: '总价标准价必填'
      //         });
      //         utils.jumpFun('jump7')
      //         return;
      //     }
      //
      //
      //     if (this.form.room.pin_an_jie_total_price === '') {
      //
      //         this.$message({
      //             type: 'error',
      //             message: '总价按揭价必填'
      //         });
      //         utils.jumpFun('jump8')
      //         return;
      //     }
      //
      //     if (this.form.room.one_off_total_price === '') {
      //
      //         this.$message({
      //             type: 'error',
      //             message: '总价一次性价必填'
      //         });
      //         utils.jumpFun('jump9')
      //         return;
      //     }
      //
      //     if (this.form.room.old_unit_price === '') {
      //
      //         this.$message({
      //             type: 'error',
      //             message: '单价标准价必填'
      //         });
      //         utils.jumpFun('jump10')
      //         return;
      //     }
      //
      //     if (this.form.room.pin_an_jie_unit_price === '') {
      //
      //         this.$message({
      //             type: 'error',
      //             message: '单价按揭价必填'
      //         });
      //         utils.jumpFun('jump11')
      //         return;
      //     }
      //
      //     if (this.form.room.one_off_unit_price === '') {
      //
      //         this.$message({
      //             type: 'error',
      //             message: '单价一次性价必填'
      //         });
      //         utils.jumpFun('jump12')
      //         return;
      //     }
      // }
      this.form.room.build_area = this.form.room.build_area === '' ? 0 : this.form.room.build_area;
      this.form.room.room_area = this.form.room.room_area === '' ? 0 : this.form.room.room_area;
      this.form.room.discount_price = parseFloat(this.form.room.discount_price);
      this.form.room.discount_total_price = parseFloat(this.form.room.discount_total_price); // this.form.room.gift_price = this.form.room.gift_price

      this.form.room.old_total_price = parseFloat(this.form.room.old_total_price);
      this.form.room.old_unit_price = parseFloat(this.form.room.old_unit_price);
      this.form.room.one_off_total_price = parseFloat(this.form.room.one_off_total_price);
      this.form.room.one_off_unit_price = parseFloat(this.form.room.one_off_unit_price);
      this.form.room.pin_an_jie_total_price = parseFloat(this.form.room.pin_an_jie_total_price);
      this.form.room.pin_an_jie_unit_price = parseFloat(this.form.room.pin_an_jie_unit_price);
      this.form.room.not_sale = this.form.room.status === -1 ? '是' : '否';
      this.form.room.buy_min_car = this.form.room.buy_min_car || 0;
      this.form.room.buy_max_car = this.form.room.buy_max_car || 0; // 提交数据

      roomEdit(this.form).then(function (res) {
        if (res.status === 200) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          }); // 修改成功跳详情


          _this3.$router.push({
            name: 'HouseInfo',
            query: {
              id: _this3.form.room.id,
              round: _this3.$route.query.round
            }
          });
        }
      });
    },
    // 车位最小限制数量blur
    onInputUserBlur: function onInputUserBlur(key) {
      if (key === 'buy_min_car') {
        if (this.form.room.buy_min_car > this.form.room.buy_max_car) {
          this.form.room.buy_min_car = this.form.room.buy_max_car > 0 ? this.form.room.buy_max_car : 0;
          this.$message({
            message: '车位最小限制数量不能大于最大限制',
            type: 'info'
          });
        } else if (this.form.room.buy_min_car < 0) {
          this.form.room.buy_min_car = 0;
        }
      } else if (key === 'buy_max_car') {
        if (this.form.room.buy_max_car < this.form.room.buy_min_car) {
          this.form.room.buy_max_car = this.form.room.buy_min_car > 0 ? this.form.room.buy_min_car : 0;
          this.$message({
            message: '车位最大限制数量不能小于最小限制',
            type: 'info'
          });
        } else if (this.form.room.buy_max_car < 0) {
          this.form.room.buy_max_car = 0;
        }
      }
    }
  }
};