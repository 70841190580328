import request from '@/utils/request';
import qs from 'qs';
export function login(data) {
  return request({
    url: 'auth/login',
    method: 'post',
    data: data
  });
} // 二次登录多个项目

export function logins(data) {
  return request({
    url: 'auth/login',
    method: 'post',
    data: data
  });
} // 保存选择项目

export function AddProject(data) {
  return request({
    url: 'auth/AddProject',
    method: 'post',
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: 'auth/info',
    method: 'get' // params: { token }

  });
}
export function logout() {
  return request({
    url: '/auth/loginOut',
    method: 'post'
  });
} // 获取项目

export function selProject() {
  return request({
    url: 'Index/SelProject',
    method: 'get'
  });
} // 获取页面 项目列表

export function adminsSetPass(data) {
  return request({
    url: 'admins/SetPass',
    method: 'post',
    data: data
  });
} // 获取页面 项目列表

export function authSetPass(data) {
  return request({
    url: 'auth/SetPass',
    method: 'post',
    data: data
  });
} //  获取验证码

export function getCode(params) {
  return request({
    url: 'auth/getCode',
    method: 'get',
    params: params
  });
} // 提交新密码

export function resetPwd(data) {
  return request({
    url: 'auth/resetPwd',
    method: 'post',
    data: data
  });
}