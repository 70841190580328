import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { adminParameter, projectConfigure } from '@/api/public';
import eventSelect from '@/components/eventSelect';
import mixins from './mixins/card_socket';
import Api_index from '@/api';
import { ReleaseLog, admissionCancel, ReleaseExport } from '@/api/admission';
import utils from '@/utils/utils';
export default {
  name: 'ReleaseLog',
  components: {
    eventSelect: eventSelect
  },
  mixins: [mixins],
  data: function data() {
    return {
      eventList: [],
      // 项目活动列表
      adminList: {},
      // container 高度
      appHeight: '',
      // 顶部高度
      topHeight: '',
      search: {
        search: '',
        sign_status: 0,
        //是否入场  1 已入场 2未入场
        level: ''
      },
      activity: {
        level: []
      },
      is_group: 1,
      page: {
        limit: 10,
        page: 1,
        total: 0
      },
      tableLoading: false,
      tableData: [],
      // 展开收起图片
      moreIcon: 'el-icon-arrow-down',
      // 是否展开text
      moreText: '展开',
      is_more: false,
      timingLists: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles', 'customSetting'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 145;
    }
  }),
  watch: {
    is_more: function is_more() {
      this.$nextTick(function () {
        this.topHeight = this.$refs.apptop.offsetHeight;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.appHeight = this.$refs.app.offsetHeight;
    this.topHeight = this.$refs.apptop.offsetHeight;

    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
      _this.topHeight = _this.$refs.apptop.offsetHeight;
    }; // -------接口相关----------


    Api_index.projectLists().then(function (res) {
      var _res$data;

      _this.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
  },
  methods: {
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      var _this2 = this;

      this.select_event = {
        event_id: e.event.id
      };
      this.type = e.event.type == 1 ? 2 : 1; // 系统参数

      adminParameter().then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
              list = _res$data2.list,
              help = _res$data2.help,
              matching_order = _res$data2.matching_order,
              event = _res$data2.event; // 订单 头 前缀

          _this2.code_top = list.order_prefix;
          _this2.adminList = list;
          _this2.adminEvent = event; // 是否显示签约 相关

          _this2.sign_up = list.is_display.includes('sign_up'); // 是否显示定金 相关

          _this2.is_price = list.is_display.includes('price'); // 核控

          _this2.help = help; // 是否开启配比认购

          _this2.matching_order = matching_order;

          if (list.cate_room == 1 && list.cate_car == 1) {
            _this2.is_radio = true;
          } else {
            // 房源等1
            if (list.cate_room == 1) {
              _this2.search.room_type = 1;
            } else if (list.cate_car == 1) {
              _this2.search.room_type = 2;
            }

            _this2.is_radio = false;
          }

          if (_this2.roles.includes('signuser/signlog') || _this2.roles.includes('admin')) {
            _this2.doGetList();
          }
        }
      });
      projectConfigure().then(function (res) {
        var list = res.data.list;
        _this2.activity = list;
      });
    },
    // 查询
    handleSearch: function handleSearch() {
      this.page.page = 1;
      this.doGetList();
    },
    // 展开& 收起
    bindMore: function bindMore() {
      this.is_more = !this.is_more;
      this.moreText = this.is_more ? '收起' : '展开';
      this.moreIcon = this.is_more ? 'el-icon-arrow-up' : 'el-icon-arrow-down';
    },
    // 重置
    handleReset: function handleReset() {
      this.page = {
        limit: 10,
        page: 1,
        total: 0
      };
      this.search = {
        search: '',
        sign_status: 0,
        //是否入场  1 已入场 2未入场
        level: ''
      };
      this.doGetList();
    },
    // 获取数据列表
    doGetList: function doGetList() {
      var _this3 = this;

      var params = _objectSpread(_objectSpread({}, this.select_event), {}, {
        page: this.page.page,
        limit: this.page.limit
      }, this.search);

      this.tableLoading = true;
      ReleaseLog(params).then(function (res) {
        if (res.status === 200) {
          _this3.tableData = res.data.logList;
          _this3.is_group = res.data.is_group;
          _this3.page.total = res.data.count;
        } else {
          _this3.$message({
            type: 'error',
            message: res.data.msg
          });
        }
      }).finally(function () {
        _this3.tableLoading = false;
      });
    },
    // 数据分页相关
    handleSizeChange: function handleSizeChange(val) {
      this.page.limit = val;
      this.doGetList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.page.page = val;
      this.doGetList();
    },
    // 导出
    exportHistory: function exportHistory() {
      var params = _objectSpread(_objectSpread({}, this.select_event), {}, {
        page: this.page.page,
        limit: this.page.limit
      }, this.search);

      ReleaseExport(params).then(function (res) {
        // window.location.href = this.host + res.data.path
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
      });
    },
    onCancel: function onCancel(row) {
      var _this4 = this;

      this.$confirm('确定要取消吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        admissionCancel({
          id: row.url_id
        }).then(function (res) {
          _this4.$message({
            message: res.data.msg,
            type: 'success'
          });

          _this4.doGetList();
        }).catch(function () {
          _this4.tableLoading = false;
        });
      });
    },

    /**
     * 转换时间格式
     * @param time  时间
     * @param format  格式
     */
    doFormatDate: function doFormatDate(time, format) {
      return utils.formatDate(new Date(time), format);
    },
    _indexMethod: function _indexMethod(index) {
      return (this.page.page - 1) * this.page.limit + index + 1;
    }
  }
};