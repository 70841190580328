var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "screen-right",
      style: { backgroundColor: _vm.allColor.background_color },
    },
    [
      _c(
        "div",
        [
          _vm.ledInfo.title == 1
            ? _c(
                "div",
                {
                  staticClass: "title",
                  style: { color: _vm.allColor.title.color },
                },
                [_vm._v(" " + _vm._s(_vm.projectInfo.name) + " ")]
              )
            : _vm._e(),
          _c("my-screen", {
            staticStyle: { "margin-top": "-15px" },
            attrs: { isShowText: false },
          }),
          _vm.ledInfo.show_now_time == 1
            ? _c(
                "div",
                {
                  staticClass: "count-down",
                  style: {
                    backgroundColor:
                      _vm.allColor.show_now_time.background_color,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "count-down-text",
                      style: { color: _vm.allColor.show_now_time.title_color },
                    },
                    [_vm._v("当前时间")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "count-down-box",
                      style: {
                        backgroundColor:
                          _vm.allColor.show_now_time.time_background_color,
                      },
                    },
                    [
                      _c("current-time", {
                        attrs: {
                          color: _vm.allColor.show_now_time.time_color,
                          times: _vm.times,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.ledInfo.show_event_time == 1
            ? _c(
                "div",
                {
                  staticClass: "count-down",
                  style: {
                    backgroundColor:
                      _vm.allColor.show_event_time.background_color,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "count-down-text",
                      style: {
                        color: _vm.allColor.show_event_time.title_color,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.isBeginShow ? "距离开盘还有" : "距离结束还有"
                        )
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "count-down-box",
                      style: {
                        backgroundColor:
                          _vm.allColor.show_event_time.time_background_color,
                      },
                    },
                    [
                      _vm.isShowCountDown
                        ? _c(_vm.countDown, {
                            tag: "component",
                            attrs: {
                              "event-id": _vm.eventId,
                              "is-change-style": true,
                              "project-id": _vm.projectInfo.saas_project_id,
                              content: _vm.content,
                              color: _vm.allColor.show_event_time.time_color,
                            },
                            on: { countDownOver: _vm.countDownOver },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", [
        _vm.ledInfo.show_amount == 1 && _vm.ledInfo.show_cate == 0
          ? _c(
              "div",
              {
                staticClass: "sales-volume",
                style: {
                  backgroundColor: _vm.allColor.show_amount.background_color,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "sales-title",
                    style: { color: _vm.allColor.show_amount.title_color },
                  },
                  [_vm._v("销售额")]
                ),
                _c(
                  "div",
                  { staticClass: "sales-price" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "price-unit",
                        style: { color: _vm.allColor.show_amount.color },
                      },
                      [_vm._v("¥")]
                    ),
                    _c("count-to", {
                      staticClass: "price-num",
                      style: { color: _vm.allColor.show_amount.color },
                      attrs: {
                        duration: 800,
                        "end-val": _vm.allData.price,
                        "start-val": _vm.oldprice,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.ledInfo.show_batch_time == 1
          ? _c(
              "div",
              {
                staticClass: "count-down",
                style: {
                  backgroundColor:
                    _vm.allColor.show_batch_time.background_color,
                  height: _vm.batchCurrent ? "123px" : "86px",
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "count-down-text",
                    style: { color: _vm.allColor.show_batch_time.title_color },
                  },
                  [_vm._v(_vm._s(_vm.batchText))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "count-down-box",
                    style: {
                      backgroundColor:
                        _vm.allColor.show_batch_time.time_background_color,
                    },
                  },
                  [
                    _c("new-countdown", {
                      attrs: {
                        "batch-time-data": _vm.batchTimeData,
                        "event-id": _vm.eventId,
                        "project-id": _vm.projectInfo.saas_project_id,
                        "led-info": _vm.ledInfo,
                        color: _vm.allColor.show_batch_time.time_color,
                      },
                      on: { uptoDate: _vm.uptoDate },
                    }),
                  ],
                  1
                ),
                _vm.batchCurrent
                  ? _c(
                      "span",
                      {
                        staticClass: "count-down-text",
                        staticStyle: { "margin-top": "10px" },
                        style: {
                          color: _vm.allColor.show_batch_time.title_color,
                        },
                      },
                      [
                        _vm._v("当前进度：第"),
                        _c("span", { staticStyle: { color: "#f81f2f" } }, [
                          _vm._v(_vm._s(_vm.batchCurrent) + "批次"),
                        ]),
                        _vm._v("正在认购！"),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        (_vm.ledInfo.show_online == 1 || _vm.ledInfo.show_sale == 1) &&
        _vm.ledInfo.show_cate != 1
          ? _c(
              "div",
              {
                staticClass: "online-person",
                style: {
                  backgroundColor: _vm.allColor.show_online.background_color,
                },
              },
              [
                _vm.ledInfo.show_sale == 1 && _vm.ledInfo.show_cate == 0
                  ? _c("div", { staticClass: "online-person-left" }, [
                      _c(
                        "div",
                        {
                          staticClass: "online-top-text",
                          style: { color: _vm.allColor.show_online.color },
                        },
                        [_vm._v("可选" + _vm._s(_vm.customSetting.title))]
                      ),
                      _c(
                        "div",
                        [
                          _c("count-to", {
                            staticClass: "online-bottom-text",
                            style: { color: _vm.allColor.show_online.color },
                            attrs: {
                              duration: 800,
                              "end-val": _vm.allData.remainCount,
                              "start-val": _vm.oldremainCount,
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "online-bottom-text",
                              style: { color: _vm.allColor.show_online.color },
                            },
                            [_vm._v("套")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.ledInfo.show_online == 1 && _vm.ledInfo.show_sale == 1
                  ? _c(
                      "div",
                      {
                        staticClass: "online-person-center",
                        style: { color: _vm.allColor.show_online.color },
                      },
                      [_vm._v(" / ")]
                    )
                  : _vm._e(),
                _vm.ledInfo.show_online == 1 && _vm.ledInfo.show_cate == 0
                  ? _c(
                      "div",
                      {
                        staticClass: "online-person-right",
                        style: { color: _vm.allColor.show_online.color },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "online-top-text",
                            style: { color: _vm.allColor.show_online.color },
                          },
                          [_vm._v("参与人数")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "online-bottom-text",
                            style: { color: _vm.allColor.show_online.color },
                          },
                          [_vm._v(_vm._s(_vm.allData.numberOnline) + "人")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm.ledInfo.show_count == 1
          ? _c(
              "div",
              {
                staticClass: "choice-history",
                style: {
                  backgroundColor: _vm.allColor.show_count.background_color,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "choice-history-lists" },
                  [
                    _c(
                      _vm.scroll,
                      {
                        tag: "component",
                        attrs: {
                          "class-option": _vm.classOption,
                          data:
                            _vm.allData.roomLog == undefined
                              ? []
                              : _vm.allData.roomLog,
                        },
                      },
                      _vm._l(_vm.allData.roomLog, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "choice-history-items",
                            style: {
                              backgroundColor:
                                _vm.allColor.show_count.user_background_color,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "choice-history-header" },
                              [
                                item.avatar == "" || item.avatar == undefined
                                  ? _c("svg-icon", {
                                      staticClass: "img",
                                      attrs: { "icon-class": "avater0" },
                                    })
                                  : _c("img", {
                                      staticClass: "img",
                                      attrs: { src: item.avatar },
                                    }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "choice-history-mes" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "choice-history-name",
                                  style: {
                                    color: _vm.allColor.show_count.color,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.hideName(item.name)))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "choice-history-house",
                                  style: {
                                    color: _vm.allColor.show_count.color,
                                  },
                                },
                                [_vm._v(_vm._s(item.room))]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm.ledInfo.show_cate == 1
        ? _c("div", { staticClass: "house-type" }, [
            _c(
              "div",
              { staticClass: "house-type-wrapper" },
              [
                _c(
                  "vue-seamless-scroll",
                  {
                    attrs: {
                      "class-option": _vm.classOptionC,
                      data:
                        _vm.allData.cateList == undefined
                          ? []
                          : _vm.allData.cateList,
                    },
                  },
                  _vm._l(_vm.allData.cateList, function (item, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "house-type-items" },
                      [
                        _c("div", { staticClass: "house-type-title" }, [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "14px" } },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]),
                        _c("div", { staticClass: "house-type-img" }, [
                          _c("div", { staticClass: "img-wrapper" }, [
                            item.image == ""
                              ? _c("img", {
                                  staticClass: "house-img",
                                  attrs: {
                                    src: require("../../assets/image/default.png"),
                                  },
                                })
                              : _c("img", {
                                  staticClass: "house-img",
                                  attrs: { src: _vm.baseUrl + item.image },
                                }),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "bottom-logo",
          style: { backgroundColor: _vm.allColor.bottom.background_color },
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "point" }),
          _c(
            "div",
            {
              staticClass: "logo-text",
              style: { color: _vm.allColor.bottom.color },
            },
            [_vm._v("数字化开盘专家")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: require("../../assets/image/logo-black.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }