var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "screenRight",
      staticClass: "right",
      style: "backgroundColor:" + _vm.ledInfo.background,
    },
    [
      _c(
        "div",
        { staticClass: "screen" },
        [_c("my-screen", { attrs: { isShowText: false } })],
        1
      ),
      _vm._l(_vm.commontShowList, function (item, index2) {
        return _c(
          "div",
          {
            key: index2,
            class: [
              "type-item",
              item.type2 == "flow" || item.type2 == "houseType"
                ? "flex1"
                : "child",
              item.type2 == "watermark" ? "watermark-item" : "",
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "zj-document",
                style:
                  item.type2 == "flow" || item.type2 == "houseType"
                    ? "height:" + _vm.remainingSpace + "px;overflow:hidden"
                    : "",
              },
              [
                _c(
                  "items",
                  {
                    attrs: {
                      type: item.type,
                      type2: item.type2,
                      title: item.title,
                      value: item.value,
                      children: item.children,
                      flex1:
                        item.type2 == "flow" || item.type2 == "houseType"
                          ? true
                          : false,
                      color: item.styleForm,
                      isSlot: true,
                      lineBreaTitle: item.lineBreaTitle,
                      lineBreak: item.lineBreak,
                      batchCurrent: _vm.batchCurrent,
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "timeValue" }, slot: "timeValue" },
                      [
                        _vm.isShowCountDown && item.type2 == "eventTime"
                          ? _c(_vm.countDown, {
                              tag: "component",
                              attrs: {
                                color: item.styleForm.time_Color,
                                "event-id": _vm.eventId,
                                "is-change-style": true,
                                "project-id": _vm.projectInfo.saas_project_id,
                                content: _vm.content,
                              },
                              on: { countDownOver: _vm.countDownOver },
                            })
                          : _vm._e(),
                        item.type2 == "currentTime"
                          ? _c("current-time", {
                              attrs: {
                                color: item.styleForm.time_Color,
                                fontSize: item.styleForm.time_size,
                                times: _vm.times,
                              },
                            })
                          : _vm._e(),
                        item.type2 == "startTime"
                          ? _c("current-time", {
                              attrs: {
                                color: item.styleForm.time_Color,
                                fontSize: item.styleForm.time_size,
                                times: _vm.eventStartTime,
                              },
                            })
                          : _vm._e(),
                        item.type2 == "singEnd"
                          ? _c("current-time", {
                              attrs: {
                                color: item.styleForm.time_Color,
                                fontSize: item.styleForm.time_size,
                                times: _vm.signEndTime,
                              },
                            })
                          : _vm._e(),
                        item.type2 == "batchStart"
                          ? _c("new-countdown", {
                              attrs: {
                                color: item.styleForm.time_Color,
                                "batch-time-data": _vm.batchTimeData,
                              },
                              on: { uptoDate: _vm.uptoDate },
                            })
                          : _vm._e(),
                        item.type2 == "batchEnd"
                          ? _c("new-countdown", {
                              attrs: {
                                color: item.styleForm.time_Color,
                                "batch-time-data": _vm.nextbatchTime,
                              },
                              on: { uptoDate: _vm.uptoDate },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { attrs: { slot: "salesVolume" }, slot: "salesVolume" },
                      [
                        _c(
                          "span",
                          {
                            style: {
                              color: item.styleForm.data_Color,
                              fontSize: "20px",
                            },
                          },
                          [_vm._v("¥")]
                        ),
                        _c("count-to", {
                          staticClass: "price-num",
                          style: {
                            color: item.styleForm.data_Color,
                            fontSize: item.styleForm.data_size,
                          },
                          attrs: {
                            duration: 800,
                            "end-val": _vm.allData.price,
                            "start-val": _vm.oldprice,
                          },
                        }),
                      ],
                      1
                    ),
                    item.type2 == "flow"
                      ? _c(
                          "div",
                          {
                            staticClass: "choice-history-lists",
                            attrs: { slot: "flowScroll" },
                            slot: "flowScroll",
                          },
                          [
                            _c(
                              _vm.scroll,
                              {
                                tag: "component",
                                attrs: {
                                  "class-option": _vm.classOption,
                                  data:
                                    _vm.allData.roomLog == undefined
                                      ? []
                                      : _vm.allData.roomLog,
                                },
                              },
                              _vm._l(_vm.allData.roomLog, function (datas, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "choice-history-items",
                                    style: {
                                      backgroundColor: item.styleForm.record_bg,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "choice-history-header" },
                                      [
                                        datas.avatar == "" ||
                                        datas.avatar == undefined
                                          ? _c("svg-icon", {
                                              staticClass: "img",
                                              attrs: {
                                                "icon-class": "avater0",
                                              },
                                            })
                                          : _c("img", {
                                              staticClass: "img",
                                              attrs: { src: datas.avatar },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "choice-history-mes" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "choice-history-name",
                                            style:
                                              "color:" +
                                              item.styleForm.record_name_Color +
                                              ";font-size:" +
                                              item.styleForm.record_name_size +
                                              "px",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.hideName(datas.name)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "choice-history-house",
                                            style:
                                              "color:" +
                                              item.styleForm
                                                .record_house_Color +
                                              ";font-size:" +
                                              item.styleForm.record_house_size +
                                              "px",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(datas.room) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type2 == "houseType"
                      ? _c(
                          "div",
                          {
                            staticClass: "house-type",
                            attrs: { slot: "houseScroll" },
                            slot: "houseScroll",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "house-type-wrapper" },
                              [
                                _c(
                                  "vue-seamless-scroll",
                                  {
                                    attrs: {
                                      "class-option": _vm.classOptionC,
                                      data:
                                        _vm.allData.cateList == undefined
                                          ? []
                                          : _vm.allData.cateList,
                                    },
                                  },
                                  _vm._l(
                                    _vm.allData.cateList,
                                    function (datas, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass: "house-type-items",
                                          style: {
                                            backgroundColor:
                                              item.styleForm.record_bg,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "house-type-title" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  style:
                                                    "color:" +
                                                    item.styleForm
                                                      .houseType_Color +
                                                    ";font-size:" +
                                                    item.styleForm
                                                      .houseType_size +
                                                    "px",
                                                },
                                                [_vm._v(_vm._s(datas.name))]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "img-wrapper" },
                                            [
                                              datas.image == ""
                                                ? _c("img", {
                                                    staticClass: "house-img",
                                                    attrs: {
                                                      src: require("../../../assets/image/default.png"),
                                                    },
                                                  })
                                                : _c("img", {
                                                    staticClass: "house-img",
                                                    attrs: {
                                                      src:
                                                        _vm.baseUrl +
                                                        datas.image,
                                                    },
                                                  }),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { attrs: { slot: "bactch" }, slot: "bactch" }, [
                      item.type2 == "batch"
                        ? _c("span", [_vm._v(_vm._s(_vm.batchText))])
                        : _vm._e(),
                      item.type2 == "afterBatch"
                        ? _c("span", [_vm._v(_vm._s(_vm.Last3Batch))])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }