import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { adminParameter, authGetEvent } from '@/api/public';
import { eventRandClose, eventRandList, eventRandReset, eventRandSelGroup, getStartYao, isBaas, PrintRoundList } from '@/api/lottery';
import { debounce } from '@/utils/debounce';
import listItem from './compontens/listItem.vue';
import Api_index from '@/api/index';
import eventSelect from '@/components/eventSelect';
import utils from '@/utils/utils';
export default {
  name: 'LotteryList',
  components: {
    listItem: listItem,
    eventSelect: eventSelect
  },
  data: function data() {
    return {
      appHeight: 0,
      // 表格数据
      tableData: [],
      // 打开分组弹框
      isGrouping: false,
      // 分组列表
      selGroup: [],
      currentPage4: 2,
      // eventData
      eventData: '',
      // 800毫秒过后开始出现 loading
      hm: 800,
      select_event: {},
      // 选择的活动和场次id集
      eventList: [],
      // 项目活动列表
      printUp: {
        show: false,
        dataList: [],
        selectIds: [],
        id: 0,
        limit: 10,
        page: 1,
        count: 0,
        loading: false
      },
      list: {},
      showHint: false,
      rand_print_template_id: 0
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles'])), {}, {
    tableHeight: function tableHeight() {
      // 90
      return this.appHeight - 95;
    }
  }),
  mounted: function mounted() {
    var _this = this;

    this.appHeight = this.$refs.app.offsetHeight;

    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
    };

    Api_index.projectLists().then(function (res) {
      var _res$data;

      _this.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  // 初始化
  created: function created() {
    var _this2 = this;

    //  项目配置
    adminParameter().then(function (res) {
      if (res.status === 200) {
        var _res$data2 = res.data,
            event = _res$data2.event,
            list = _res$data2.list;
        _this2.eventData = event;
        _this2.list = list;
      }
    });
  },
  methods: {
    show: function show() {
      this.showHint = true;
    },
    close: function close() {
      this.showHint = false;
    },
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      this.select_event = {
        event_id: e.event.id
      };

      if (this.roles.includes('eventrand/index') || this.roles.includes('admin')) {
        this.getTableList();
      }
    },
    // 批量设置摇号规则
    handleEditRule: function handleEditRule() {
      this.close();
      this.$router.push({
        name: 'BatchRule'
      });
    },
    getText: function getText(row) {
      if (row.log_rand_cound > 0 && row.user_count !== row.log_rand_cound) {
        return '继续摇号';
      } else if (row.log_rand_cound > 0 && row.user_count === row.log_rand_cound) {
        return '摇号结束';
      } else {
        return '开始摇号';
      }
    },
    // 跳转开始摇号
    handleStartLottery: debounce(function (row) {
      var _this3 = this;

      localStorage.setItem(parseInt(new Date().getTime() / 86400000).toString(), "".concat(row.id, "-").concat(row.event_type));

      if (row.user_count === 0) {
        this.$message({
          type: 'warning',
          message: "\u5BA2\u6237\u603B\u6570\u4E3A\uFF1A".concat(row.user_count, "\uFF0C\u65E0\u6CD5\u6447\u53F7")
        });
        return;
      }

      var isOk = true,
          isStart = true;

      for (var i = 0; i < this.eventList.length; i++) {
        var item = this.eventList[i]; // 活动结束

        if (item.type === 1 && item.end_time * 1000 <= new Date().getTime()) {
          isOk = false;
          break;
        } // 当前活动未开始
        else if (this.select_event.event_id === item.id && item.start_time * 1000 > new Date().getTime()) {
          isStart = false;
          break;
        }
      } // 开始摇号不判断活动时间是否开始 结束
      // if (!isOk) {
      // 	this.$message({
      // 		type: 'warning',
      // 		message: '活动已结束',
      // 	});
      // 	return;
      // }
      //
      // // 自主模式并且当前活动未开始
      // if (this.list.buy_type === 1 && !isStart) {
      // 	this.$message({
      // 		type: 'warning',
      // 		message: '活动未开始',
      // 	});
      // 	return;
      // }
      // 开启上连 并且未开始时提示


      if (this.list.is_rand_baas && row.is_start === 1) {
        this.$confirm('本次摇号是否采用区块链技术？', '提示', {
          confirmButtonText: '区块链摇号',
          cancelButtonText: '普通摇号',
          type: 'warning'
        }).then(function () {
          _this3.submitBaas({
            id: row.id,
            is_baas: 1
          }, row);
        }).catch(function () {
          _this3.submitBaas({
            id: row.id,
            is_baas: 0
          }, row);
        });
      } else {
        this.submitLottery(row);
      }
    }, 200, true),
    submitLottery: function submitLottery(row) {
      var _this4 = this;

      // 倒计时 800 毫秒 出现 loading
      this.time = setInterval(function () {
        _this4.hm = _this4.hm - 10;

        if (_this4.hm === 0) {
          _this4.loading = _this4.$loading({
            lock: true,
            text: '摇号准备中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          clearInterval(_this4.time);
        }
      }, 10);
      getStartYao({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          var is_preinstall = res.data.data.is_preinstall; // 有预设失败的

          if (is_preinstall) {
            _this4.$alert('摇号人数异常无法继续摇号，请修复异常。', '提示', {
              confirmButtonText: '好的',
              callback: function callback(action) {}
            }); //  是否超过 800毫秒， 没超过 直接终止定时器


            if (_this4.hm === 0) {
              _this4.loading.close();
            } else {
              clearInterval(_this4.time);
            }

            _this4.hm = 800;
            return;
          } //  是否超过 800毫秒， 没超过 直接终止定时器


          if (_this4.hm === 0) {
            _this4.loading.close();
          } else {
            clearInterval(_this4.time);
          } // 获取最新数据


          eventRandList(_this4.select_event).then(function (res) {
            if (res.status === 200) {
              var list = res.data.list;
              _this4.tableData = list || [];

              if (!_this4.tableData.length) {
                _this4.$message({
                  type: 'error',
                  message: '数据错误！'
                });

                return;
              }

              for (var i = 0; i < _this4.tableData.length; i++) {
                // 前往判断
                if (_this4.tableData[i].id === row.id) {
                  _this4.getStartLottery(_this4.tableData[i]);

                  break;
                }
              }
            }
          });
          _this4.hm = 800;
        }
      }).catch(function () {
        //  是否超过 800毫秒， 没超过 直接终止定时器
        if (_this4.hm === 0) {
          _this4.loading.close();
        } else {
          clearInterval(_this4.time);
        }

        _this4.hm = 800;
      });
    },
    submitBaas: function submitBaas(params, row) {
      var _this5 = this;

      isBaas(params).then(function () {
        _this5.submitLottery(row);
      });
    },
    // 开始摇号  // 跳转摇号页面
    getStartLottery: function getStartLottery(row) {
      localStorage.setItem(parseInt(new Date().getTime() / 86400000).toString(), "".concat(row.id, "-").concat(row.event_type)); // 继续摇号

      if (row.log_rand_cound > 0 && row.user_count !== row.log_rand_cound) {
        // 跳转页面参数
        var data = {
          id: row.id,
          group_id: row.group_id,
          round: 1
        }; // 随机摇号

        if (row.type === 1) {
          // 分组摇号
          if (row.type_group === 1) {
            if (row.group_id === '' || row.group_id == null) {
              this.$message({
                type: 'error',
                message: '所有摇号分组已结束'
              });
              return;
            }

            var _this$$router$resolve = this.$router.resolve({
              name: 'StartLottery',
              query: data
            }),
                href = _this$$router$resolve.href;

            window.open(href, '_blank'); // 不分组摇号
          } else {
            var _this$$router$resolve2 = this.$router.resolve({
              name: 'StartLotteryNo',
              query: data
            }),
                _href = _this$$router$resolve2.href;

            window.open(_href, '_blank');
          } //	按序排号

        } else {
          // 按序排号分组
          if (row.type_group === 1) {
            var _this$$router$resolve3 = this.$router.resolve({
              name: 'StartRowNumber',
              query: data
            }),
                _href2 = _this$$router$resolve3.href;

            window.open(_href2, '_blank');
          } else {
            var _this$$router$resolve4 = this.$router.resolve({
              name: 'StartLotteryOrder',
              query: data
            }),
                _href3 = _this$$router$resolve4.href;

            window.open(_href3, '_blank');
          }
        } //	摇号结束

      } else if (row.log_rand_cound > 0 && row.user_count === row.log_rand_cound) {// // 跳转页面参数
        // const data = {
        //   id: row.id,
        //   group_id: row.max_group_id,
        //   round: row.log_group_number
        // }
        // // 随机摇号
        // if (row.type === 1) {
        //   // 分组摇号
        //   if (row.type_group === 1) {
        //     if (row.group_id === '' || row.group_id == null) {
        //       this.$message({
        //         type: 'error',
        //         message: '所有摇号分组已结束'
        //       })
        //       return
        //     }
        //     const { href } = this.$router.resolve({
        //       name: 'StartLotteryHistory',
        //       query: data
        //     })
        //     window.open(href, '_blank')
        //     // 不分组摇号
        //   } else {
        //     const { href } = this.$router.resolve({
        //       name: 'StartLotteryNoHistory',
        //       query: data
        //     })
        //     window.open(href, '_blank')
        //   }
        //   // 顺序摇号
        // } else {
        //   const { href } = this.$router.resolve({
        //     name: 'StartLotteryOrderHistory',
        //     query: data
        //   })
        //   window.open(href, '_blank')
        // }
      } else {
        // 开始摇号
        var _this$$router$resolve5 = this.$router.resolve({
          name: 'Lottery',
          query: {
            id: row.id
          }
        }),
            _href4 = _this$$router$resolve5.href;

        window.open(_href4, '_blank');
      }
    },
    // 设置显示版式
    handleSet: function handleSet() {
      // this.$router.push({ name: 'ShowMould' })
      var path = this.$router.resolve({
        path: '/EditFormat',
        query: {
          id: this.select_event.event_id,
          type: 'round'
        }
      });
      var win = window.open("".concat(path.href), '_blank');
    },
    // 公示画面版式
    handlePublicitySet: function handlePublicitySet() {
      var path = this.$router.resolve({
        path: '/publicitySet',
        query: {
          id: this.select_event.event_id,
          type: 'round'
        }
      });
      var win = window.open("".concat(path.href), '_blank');
    },
    // 查看 摇号记录
    handleRecord: function handleRecord(id) {
      this.$router.push({
        name: 'LotteryRecord',
        query: {
          id: id
        }
      });
    },
    // 点击打印按钮
    handlePrintUp: function handlePrintUp(id) {
      // 存在id，显示弹框
      if (id) {
        this.printUp = {
          show: false,
          dataList: [],
          selectIds: [],
          id: id,
          limit: 10,
          page: 1,
          count: 0
        };
        this.doGetPrintRoundList();
      } // 不存在，跳转打印页面
      else {
        if (!this.printUp.selectIds.length) {
          this.$message({
            type: 'error',
            message: '请先选择轮次！'
          });
          return;
        }

        var path = this.$router.resolve({
          path: '/printUp',
          query: {
            template_id: this.rand_print_template_id,
            ids: this.printUp.selectIds.join(','),
            rand_id: this.printUp.id
          }
        });
        window.open("".concat(path.href), '_blank');
        this.printUp = {
          show: false,
          dataList: [],
          selectIds: [],
          id: 0,
          limit: 10,
          page: 1,
          count: 0
        };
      }
    },
    // 修改 每页条数
    handlePrintUpSize: function handlePrintUpSize(val) {
      this.printUp.limit = val;
      this.doGetPrintRoundList();
    },
    // 翻页
    handlePrintUpCurrente: function handlePrintUpCurrente(val) {
      this.printUp.page = val;
      this.doGetPrintRoundList();
    },
    // 获取打印轮次列表
    doGetPrintRoundList: function doGetPrintRoundList() {
      var _this6 = this;

      this.printUp.loading = true;
      PrintRoundList({
        rand_id: this.printUp.id,
        //规则id
        page: this.printUp.page,
        //分页页码，数字类型
        limit: this.printUp.limit
      }).then(function (res) {
        _this6.rand_print_template_id = res.data.word.word_rule.rand_print_template_id; //打印模板id

        _this6.printUp.show = true;
        _this6.printUp.dataList = res.data.list || [];
        _this6.printUp.count = res.data.count;
        _this6.printUp.selectIds = [];
        _this6.printUp.loading = false;
        setTimeout(function () {
          if (_this6.printUp.dataList.length > 0) _this6.toggleSelection([_this6.printUp.dataList[0]]);
        }, 100);
      }).catch(function (res) {
        _this6.printUp.loading = false;
      });
    },
    toggleSelection: function toggleSelection(rows) {
      var _this7 = this;

      if (rows) {
        rows.forEach(function (row) {
          _this7.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 选中表格数据
    handlePrintUpSelection: function handlePrintUpSelection(row) {
      var array = [];
      row.forEach(function (item) {
        array.push(item.id);
      });
      this.printUp.selectIds = array;
    },
    // 改变时间格式
    doGetTime: function doGetTime(time) {
      return utils.formatDate(new Date(time), 'yyyy-MM-dd hh:mm:ss');
    },
    // 结束本组摇号
    handleEnd: function handleEnd(id) {
      var _this8 = this;

      this.$confirm('结束后本组将不能继续摇号！', '提醒', {
        confirmButtonText: '确认结束',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        eventRandClose({
          id: id
        }).then(function (res) {
          if (res.status === 200) {
            _this8.isGrouping = false;

            _this8.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {});
    },
    // 获取 分组列表
    handleGrouping: function handleGrouping(id) {
      var _this9 = this;

      eventRandSelGroup({
        id: id
      }).then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this9.selGroup = list.level;
          _this9.isGrouping = true;
        }
      });
    },
    // 获取button 文字
    getButtonText: function getButtonText(type) {
      var text = null;

      switch (type) {
        case 1:
          text = '正式';
          break;

        case 2:
          text = '公测';
          break;

        case 3:
          text = '内测';
          break;

        case 4:
          text = '拟测';
          break;
      }

      return text;
    },
    // 重置
    handleReset: function handleReset(id) {
      var _this10 = this;

      this.$confirm('重置会清除摇号的记录，但不会改变摇号规则。', '提醒', {
        confirmButtonText: '继续重置',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        eventRandReset({
          id: id
        }).then(function (res) {
          if (res.status === 200) {
            _this10.getTableList();

            _this10.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {});
    },
    // 获取表格数据
    getTableList: function getTableList() {
      var _this11 = this;

      var params = _objectSpread({}, this.select_event);

      eventRandList(params).then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this11.tableData = list;
        }
      });
    },
    // table 操作按钮
    handleEdit: function handleEdit(data) {
      this.$router.push({
        name: 'EditRule',
        query: {
          id: data.id
        }
      });
    },
    // 摇号分屏
    lotterySplitScreen: function lotterySplitScreen(row) {
      var routeParams = {
        id: row.id
      };
      var path = this.$router.resolve({
        path: '/lotterySplitScreen',
        query: routeParams
      });
      var win = window.open("".concat(path.href), '_blank');
    },
    // 录入摇号
    lotteryEntryIndex: function lotteryEntryIndex(row) {
      console.log(row);
      var routeParams = {
        rand_id: row.id,
        rand_count: row.rand_count //每轮个数

      };
      var path = this.$router.resolve({
        path: '/lotteryEntryIndex',
        query: routeParams
      });
      window.open("".concat(path.href), '_blank');
    },
    // 公示大屏
    lotteryPublicity: function lotteryPublicity(row) {
      var routeParams = {
        rand_id: row.id,
        type_group: row.type_group
      };
      var path = this.$router.resolve({
        path: '/lotteryPublicity',
        query: routeParams
      });
      window.open("".concat(path.href), '_blank');
    }
  }
};