var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container" },
    [
      _vm.is_null
        ? _c(
            "div",
            { staticClass: "noData" },
            [
              _c("img", {
                staticClass: "onImage",
                attrs: { src: require("../../../assets/image/icon-qs.png") },
              }),
              _c("div", { staticClass: "onTips" }, [
                _vm._v("未启用签约排号功能~"),
              ]),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { size: "mini" },
                  on: { click: _vm.handlePerfect },
                },
                [_vm._v("去完善")]
              ),
            ],
            1
          )
        : [
            _c("div", { staticClass: "page-header" }, [
              _c(
                "div",
                { staticClass: "page-header-left" },
                [
                  _c("event-select", {
                    attrs: { "event-list": _vm.eventList, is_round: false },
                    on: { select_round: _vm.selectRoundHandle },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "page-header-right" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "rowNumber/setplatetype"],
                          expression: "['admin', 'rowNumber/setplatetype']",
                        },
                      ],
                      staticClass: "greens-btn",
                      attrs: { size: "small" },
                      on: { click: _vm.handleSet },
                    },
                    [_vm._v("设置显示版式")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "block-bottom mT16 tanle" },
              [
                _vm._l(_vm.tableData, function (row, i) {
                  return _c("list-item", {
                    key: i,
                    attrs: { row: row },
                    on: {
                      handleEdit: _vm.handleEdit,
                      handleRecord: _vm.handleRecord,
                      handleReset: _vm.handleReset,
                      handleStartLottery: _vm.handleStart,
                      lotterySplitScreen: _vm.lotterySplitScreen,
                    },
                  })
                }),
                false
                  ? _c(
                      "div",
                      { staticClass: "table-box" },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.tableData,
                              "header-cell-style": { background: "#fafafa" },
                              height: _vm.tableHeight,
                              border: "",
                              "highlight-current-row": "",
                              size: "medium",
                            },
                          },
                          [
                            _c("template", { slot: "empty" }, [
                              _c("span", [_vm._v("暂无数据")]),
                            ]),
                            _c("el-table-column", {
                              attrs: { align: "center", label: "活动" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scoped) {
                                      return [
                                        _c("span", [
                                          scoped.row.event_type == 1
                                            ? _c(
                                                "span",
                                                { staticClass: "buttonStyle" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getButtonText(
                                                        scoped.row.event_type
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "buttonStyle2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getButtonText(
                                                        scoped.row.event_type
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                          _vm._v(
                                            " " +
                                              _vm._s(scoped.row.event_name) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1257861590
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "排号名称",
                                prop: "name",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "轮次人数",
                                prop: "rand_count",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "已认购客户总数",
                                prop: "user_count",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "已排号",
                                prop: "log_rand_cound",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "待排号",
                                prop: "not_rand_count",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: { align: "center", label: "操作" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scoped) {
                                      return [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              content: "重置",
                                              effect: "dark",
                                              placement: "top-end",
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              directives: [
                                                {
                                                  name: "permission",
                                                  rawName: "v-permission",
                                                  value: [
                                                    "admin",
                                                    "rownumber/reset",
                                                  ],
                                                  expression:
                                                    "['admin', 'rownumber/reset']",
                                                },
                                              ],
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "reset",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleReset(
                                                    scoped.row.id
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              content: _vm.getText(scoped.row),
                                              effect: "dark",
                                              placement: "top-end",
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              directives: [
                                                {
                                                  name: "permission",
                                                  rawName: "v-permission",
                                                  value: [
                                                    "admin",
                                                    "eventranduser/index",
                                                  ],
                                                  expression:
                                                    "['admin', 'eventranduser/index']",
                                                },
                                              ],
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "yaohao",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleStart(
                                                    scoped.row.id
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        scoped.row.log_rand_cound <= 0
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  content: "编辑规则",
                                                  effect: "dark",
                                                  placement: "top-end",
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value: [
                                                        "admin",
                                                        "rownumber/setrule",
                                                      ],
                                                      expression:
                                                        "['admin', 'rownumber/setrule']",
                                                    },
                                                  ],
                                                  attrs: {
                                                    "class-name": "greens",
                                                    "icon-class": "edit",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEdit(
                                                        scoped.row.id
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        scoped.row.log_rand_cound > 0
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  content: "排号记录",
                                                  effect: "dark",
                                                  placement: "top-end",
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value: [
                                                        "admin",
                                                        "rownumber/randlist",
                                                      ],
                                                      expression:
                                                        "['admin', 'rownumber/randlist']",
                                                    },
                                                  ],
                                                  attrs: {
                                                    "class-name": "greens",
                                                    "icon-class": "lottery",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleRecord(
                                                        scoped.row.id
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                833299847
                              ),
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }